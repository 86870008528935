import { useMemo, useState } from "react";
import {
  FormDatePickerRange,
  FormDatePicker,
  FormSelectField,
  FormTextareaField,
  FormTextField,
  FormSimpleSelectField,
  FormNumericField,
  FormCheckboxField,
  FormRadioGroup,
  FormLabel,
} from "../../../components/form";
import { minScreenLength } from "../../../style/theme";
import { useIsMobile } from "@adv-libs/utils";
import types from "./FieldTypes";
import { DocumentsFieldItem } from "../../../api/documentsAPI";
import API from "../../../api/API";
import { useCallback, useEffect } from "react";
import { useCompanyContext } from "../../../features/Companies/CompanyContext";
import { assureArray } from "@adv-libs/utils";
import { contextType } from "../../../api/documentsAPI";

const dynamicAttr = [
  "visibleWhen",
  "requiredWhen",
  "disabledWhen",
  "valueWhen",
];

export interface RequestFormFieldProps {
  field: DocumentsFieldItem;
  data: any;
  evalExpr?: (
    field: string,
    attribute: string,
    context: { [key: string]: any }
  ) => any;
  context?: contextType;
  changeFieldValue?: (field: string, value: any) => void;
  changeFieldAttributes?: (
    field: string,
    attribute: string,
    value: any
  ) => void;
  saveHiddenValue?: (field: string, value: any) => void;
}

const DocumentFormField: React.FC<RequestFormFieldProps> = ({
  field,
  data,
  evalExpr,
  context,
  changeFieldValue,
  changeFieldAttributes,
  saveHiddenValue,
}) => {
  const isMobile = useIsMobile(minScreenLength);
  const companyContext = useCompanyContext();
  const { visible, type, getItems, ...restProps } = field;
  const [isVisible, setIsVisible] = useState(visible);
  const [propsField, setPropsField] = useState(restProps);
  const [isSp, setIsSp] = useState(false);

  const fetchList = useCallback(
    async (procedureName, query, size) => {
      return await API.documents.getList({
        clientDbId: companyContext.company.clientDbId,
        procedureName: procedureName,
        size: size,
        filterXML: query,
      });
    },
    [companyContext.company.clientDbId]
  );

  useEffect(() => {
    if (type === types.SELECT || type === types.RADIO) {
      const key = type === types.SELECT ? "getItems" : "items";
      const newProps = {};

      if (type === types.SELECT) {
        newProps["filter"] = "fuzzy";
        newProps["paginated"] = false;
      }

      if (getItems.search(/context:/i) > -1) {
        const dataList = getItems
          .split(/context:/i)[1]
          .split(/app\.data\./i)[1];

        const items = data[dataList];

        if (items && items.list) newProps[key] = assureArray(items.list);
      } else if (getItems.search(/sp:/i) > -1) {
        const sp = getItems.split(/sp:/i)[1];
        if (sp) {
          setIsSp(true);

          newProps[key] = ({ query, size }) => fetchList(sp, query, size);

          newProps["filter"] = "server";
          newProps["paginated"] = { size: 20 };
        }
      }

      setPropsField((prev) => ({ ...prev, ...newProps }));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    dynamicAttr.forEach((atr) => {
      const result = evalExpr(field.name, atr, context);
      const attribute = atr.split("When")[0];
      if (typeof result !== "undefined") {
        if (atr === "visibleWhen") {
          if (result !== isVisible) {
            setIsVisible(result);
            changeFieldAttributes(field.name, "visible", result);

            if (result) {
              const lastValue = context.field.find(
                (item) => item.name === field.name
              );
              if (lastValue && lastValue.value)
                changeFieldValue(field.name, lastValue.value);
            }
          }
        } else if (atr === "valueWhen") {
          if (type === types.HIDDEN) {
            saveHiddenValue(field.name, result);
          }

          changeFieldValue(field.name, result);
        } else {
          const prevAttr = propsField[attribute];
          if (result !== prevAttr) {
            setPropsField((prev) => ({ ...prev, [attribute]: result }));
            changeFieldAttributes(field.name, attribute, result);
          }
        }
      }
    });
  }, [context]); // eslint-disable-line

  const chooseField = useMemo(() => {
    switch (type.toLowerCase()) {
      case types.SELECT:
        if (isMobile && !isSp) {
          return <FormSimpleSelectField isClearable {...propsField} />;
        } else return <FormSelectField isClearable {...propsField} />;

      case types.NUMBER:
        return <FormNumericField isClearable {...propsField} />;

      case types.TEXT:
        return <FormTextField {...propsField} type="text" />;

      case types.TEXTAREA:
        return <FormTextareaField {...propsField} type="text" />;

      case types.DATE:
        return <FormDatePicker isClearable {...propsField} />;

      case types.DATERANGE:
        return <FormDatePickerRange isClearable {...propsField} />;

      case types.LABEL:
        return <FormLabel {...propsField} />;

      case types.CHECKBOX:
        return <FormCheckboxField {...propsField} />;

      case types.RADIO:
        return <FormRadioGroup {...propsField} />;

      default:
        return null;
    }
  }, [isSp, isMobile, propsField, type]);

  return <>{isVisible ? chooseField : null}</>;
};

export default DocumentFormField;
