import React from "react";
import styled from "styled-components";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const Container: React.FC<ContainerProps> = (props) => {
  return <ContainerStyled {...props}></ContainerStyled>;
};

export const ContainerStyled = styled.div`
  max-width: ${({ theme }) => theme.containerMaxWidth}px;
  margin: 0 auto;
  z-index: 3;
  padding: 0 16px;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    padding: 0;
  }
`;

export default Container;
