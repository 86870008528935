import React from "react";
import styled from "styled-components";

export interface CardHeaderProps {}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return <CardHeaderStyled>{props.children}</CardHeaderStyled>;
};

const CardHeaderStyled = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 16px;
`;

export default CardHeader;
