import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvTextarea,
  AdvTextareaProps,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormTextareaFieldProps
  extends Omit<AdvTextareaProps, "value" | "onCommit"> {
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  type?: "text" | "password";
  size?: any;
  defaultValue?: string;
}

const FormTextareaField = (props: FormTextareaFieldProps) => {
  const { name, type, size, ...controlProps } = props;

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  const formIsSubmitting = useFormIsSubmitting();

  return (
    <AdvFormField
      fieldName={name}
      control={AdvTextarea}
      controlProps={controlProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
      defaultValue={props.defaultValue}
    ></AdvFormField>
  );
};

export default React.memo(FormTextareaField);
