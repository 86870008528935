import { setI18Next } from "@adv-libs/l10n";
import { AdvDatePicker } from "@adv-libs/r365-ui";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import * as ReactI18Next from "react-i18next";
import { initReactI18next } from "react-i18next";
import { addBuildQuery } from "../utils/getBuild";

// Add translations to adv-libs
setI18Next(ReactI18Next);

// Add new detection function for first lng - always LT
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: "myLngDetector",
  lookup(options) {
    return "lt";
  },
});

i18n
  .use(languageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ["localStorage", "myLngDetector", "navigator"],
    },
    nsSeparator: false,
    keySeparator: false,
    load: "currentOnly",
    fallbackLng: "en",
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    cleanCode: true,
    ns: ["translation", "adv-libs"],
    backend: {
      loadPath: decodeURI(addBuildQuery("/l10n/{{ns}}/{{lng}}.json")),
    },
  });

AdvDatePicker.setLocaleCallback(() => {
  return i18n.language;
});
