import { getHistory } from "./history";

const hasPreviousLocation = () => {
  const hasPrevious = !!getHistory()?.location?.state?.idx;
  if (hasPrevious) {
    return true;
  } else {
    return false;
  }
};

export default hasPreviousLocation;
