import React, { useCallback, useState, useMemo } from "react";
import useI18n from "../../../hooks/useI18n";
import styled from "styled-components";
import API from "../../../api/API";
import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import InfiniteCardWithSearch from "../../../components/InfiniteCard/InfiniteCardWithSearch";
import { DocumentsGetListAPIResponse } from "../../../api/documentsAPI";
import NoDataCard from "../../../components/NoDataCard";
import TextField from "../../../components/TextField";
import { useDebounced, escapeXML } from "@adv-libs/utils";

interface DocumentMenuProps {
  data: { [key: string]: any };
  onClose: (resolveValue: boolean | undefined) => {};
  onClick: (id: string) => {};
  companyId: any;
}

const DocumentMenu: React.FC<DocumentMenuProps> = (props) => {
  const { t } = useI18n();
  const [filter, setFilter] = useState<string>("");
  const debouncedFiler = useDebounced(filter, 500);

  const handleSearchFilterChange = useCallback(
    (search) => {
      setFilter(search);
    },
    [setFilter]
  );

  const handleClick = useCallback(
    (id: string) => {
      props.onClick(id);
      props.onClose(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onClick, props.onClose]
  );

  const searchInput = useMemo(() => {
    return (
      <SearchStyled>
        <TextField
          type="text"
          value={filter}
          startIcon={"search-finder"}
          onCommit={handleSearchFilterChange}
          label={t("Search")}
        />
      </SearchStyled>
    );
  }, [handleSearchFilterChange, filter, t]);

  const request = useCallback(
    async (page: number) => {
      const filterXML =
        "<filters><search><r><k>" +
        escapeXML(debouncedFiler) +
        "</k></r></search></filters>";

      return await API.documents.getFormsList({
        clientDbId: props.companyId,
        filterXML: filterXML,
        page: page,
        size: 5,
      });
    },
    [props.companyId, debouncedFiler]
  );

  return (
    <DocumentsMenuStyled>
      <Documents>
        <InfiniteCardWithSearch
          showMore
          id="documents-form-selector"
          header={<MenuHeader>{t("Select document form")}</MenuHeader>}
          request={request}
          searchInput={searchInput}
          threshold={5}
          noBorders
        >
          {(items: DocumentsGetListAPIResponse) => {
            if (!items.length) {
              return <NoDataCard>{t("No documents forms")}</NoDataCard>;
            }
            return (
              <DocumentContainerStyled>
                <div className={clsx("box", "r365-close-dropdown")}>
                  {items.map((item, key) => {
                    return (
                      <div key={key} className="button-container ">
                        <MenuButton onClick={() => handleClick(item.id)}>
                          {item.name}
                        </MenuButton>
                        <Icon icon={"caret-up"} className="caret-right" />
                      </div>
                    );
                  })}
                </div>
              </DocumentContainerStyled>
            );
          }}
        </InfiniteCardWithSearch>
        <DocumentsActionsContainer>
          <CancelButton onClick={() => props.onClose(false)}>
            {t("Cancel")}
          </CancelButton>
        </DocumentsActionsContainer>
      </Documents>
    </DocumentsMenuStyled>
  );
};

const SearchStyled = styled.div`
  > div {
    margin-bottom: 0;
  }

  .r365-control {
    border: 1px solid #e6e6e6;

    .r365-control__end-icon--clickable {
      background-color: #fff;
    }
  }
`;

const MenuHeader = styled.span`
  font-size: 16px;
`;

const DocumentContainerStyled = styled.div`
    width: 100%;
  
    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      
      .button-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e4e4e4;
        cursor: pointer;
  
        &:hover {
          background-color: ${({ theme }) => theme.buttonHoverColor};
        }
      }
  
      .r365-icon {
          font-size: 10px;
          padding-right: 8px;
        }
    
      .r365-icon.caret-right {
      svg {
          transform: rotate(90deg);
      }
  
    }
  `;

const CancelButton = styled.div`
  text-align: center;
  padding: 20px 16px;
  cursor: pointer;
`;

const MenuButton = styled.div`
  text-align: left;
  width: 100%;
  padding: 20px 16px;
  cursor: pointer;
`;

const DocumentsMenuStyled = styled.div`
  box-shadow: 0 0 30px 0px rgba(109, 109, 109, 0.3);
  border-radius: 6px;
  width: 100%;
  background: white;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    width: 400px;
  }
`;

const Documents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;

  .h2 {
    border-bottom: 1px solid #e4e4e4;
    padding: 8px 8px 16px 8px;
    font-size: 16px;
  }
`;

const DocumentsActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #e4e4e4;

  .r365-button {
    & > div {
      width: 100%;
      .r365-button__content {
        width: 100%;
      }
    }
  }
`;

export default DocumentMenu;
