import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import spacetime from "spacetime";

const getCalendarRange = (year: number, month: number, timeline: boolean) => {
  const firstMonthDay = startOfMonth(new Date(year, month));
  const prevMonth = spacetime(firstMonthDay)
    .subtract(1, "month")
    .toNativeDate();
  const nextMonth = spacetime(firstMonthDay).add(1, "month").toNativeDate();

  const rangeStart = timeline
    ? startOfMonth(prevMonth)
    : startOfWeek(startOfMonth(prevMonth), {
      weekStartsOn: 1, // Monday
    });

  const rangeEnd = timeline
    ? endOfMonth(nextMonth)
    : endOfWeek(endOfMonth(nextMonth), {
      weekStartsOn: 1, // Monday
    });

  return {
    rangeStart,
    rangeEnd,
    nextMonth,
    prevMonth,
    firstMonthDay,
  };
};

export default getCalendarRange;
