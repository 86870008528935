import { assureArray } from "@adv-libs/utils";
import i18n from "i18next";
import { DataViewSchemaType } from "../components/DataView/types";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import {
  ApproveHistoryAPIPayload,
  ApproveHistoryAPIResponse,
  ApproveHistoryAPIResponseOriginal,
} from "./types";
import createAPIHandler from "./utils/createAPIHandler";

export type TasksListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  active?: boolean;
  filterXML?: string;
};

export type TasksListAPIResponseItem = {
  id: any;
  item1: string;
  item2: string;
  item3: string;
  status: string;
};

export type TasksListAPIResponse = TasksListAPIResponseItem[];

export type TasksListAPIResponseItemOriginal = {
  Id: string;
  apr1: string;
  apr2: string;
  apr3: string;
  busena: string;
  busena_pav: string;
  kodas_be: string;
  kodas_op: string;
  kodas_rs: string;
  modul: string;
  rus_date: string;
};

export type TasksGetAPIPayload = {
  id: any;
  clientDbId: string;
};

export type TasksGetAPIResponse = {
  id: any;
  title: string;
  buttons: string;
  comment: boolean;
  busenaComment: string;
  status: string;
  add_data: string;
  kor_data: string;
  schema: DataViewSchemaType;
  showHistory: boolean;
  confirmButtonTitle: string | null;
};

export type TaskApproveAPIPayload = {
  action: "P" | "A";
  comment?: string;
  clientDbId: string;
  id: any;
};

export type TaskFiltersAPIPayload = {
  clientDbId: string;
  user: string;
  filterXML: string;
};

export type TaskFiltersAPIResponseItemOriginal = {
  k: string;
  p: string;
  t: string;
  f: string;
};

export type TaskFiltersAPIResponseOriginal = {
  filters: {
    apr1: {
      r: TaskFiltersAPIResponseItemOriginal;
    };
    apr2: {
      r: TaskFiltersAPIResponseItemOriginal;
    };
    metai: {
      r: TaskFiltersAPIResponseItemOriginal;
    };
    modul: {
      r: TaskFiltersAPIResponseItemOriginal;
    };
  };
};

export type TaskFiltersAPIResponseItem = {
  id: string;
  name: string;
};

export type TaskFiltersAPIResponse = {
  apr1: TaskFiltersAPIResponseItem[];
  apr2: TaskFiltersAPIResponseItem[];
  metai: TaskFiltersAPIResponseItem[];
  modul: TaskFiltersAPIResponseItem[];
};

const TasksAPIRequest = () => {
  return {
    get: createAPIHandler(
      async (payload: TasksGetAPIPayload): Promise<TasksGetAPIResponse> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_BUS_DETAILS,
          clientDbId: payload.clientDbId,
          data: {
            Id: payload.id,
            lang: i18n.language,
          },
        });

        const buttons = response.data.row?.rez?.data?.row?.buttons;
        const status = response.data.row?.rez?.data?.row?.busena;
        const data = response.data.row?.rez?.data?.row;
        const schema = assureArray(response.data.row?.rez?.data?.schema);

        const config = assureArray(response.data.row?.rez?.data?.config);

        const showHistoryConfig = config.find(
          (item) => item.key === "SHOW_HISTORY"
        );

        const confirmButtonConfig = config.find(
          (item) => item.key === "BUTTON_OK_TITLE"
        );

        const showHistory = showHistoryConfig?.value || false;
        const confirmButtonTitle = confirmButtonConfig?.value || null;

        const result = {
          ...data,
          status,
          buttons,
          schema,
          showHistory,
          confirmButtonTitle,
        };

        return result;
      }
    ),
    list: createAPIHandler(
      async (payload: TasksListAPIPayload): Promise<TasksListAPIResponse> => {
        const response = await Client.post<{
          app:
            | TasksListAPIResponseItemOriginal
            | TasksListAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_BUS_LIST,
          clientDbId: payload.clientDbId,
          data: {
            response_type: 1,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
            aktyvus: payload.active ? 1 : 0,
            filter_xml: payload.filterXML,
            rus_int: 1,
          },
        });
        const dataArray = assureArray(response.data.app);
        return dataArray.map((i) => {
          return {
            id: i.Id,
            item1: i.apr1,
            item2: i.apr2,
            item3: i.apr3,
            status: i.busena,
          };
        });
      }
    ),
    approve: createAPIHandler(async (payload: TaskApproveAPIPayload) => {
      await Client.post("/client/application", {
        procedureName: APIProcedures.RGI_MGAMA_BUS_ACTION,
        clientDbId: payload.clientDbId,
        data: {
          id: payload.id,
          veiksmas: payload.action,
          pastabos: payload.comment,
        },
      });
    }),
    getApproveHistory: createAPIHandler(
      async (
        payload: ApproveHistoryAPIPayload
      ): Promise<ApproveHistoryAPIResponse> => {
        const response = await Client.post<ApproveHistoryAPIResponseOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_BUS_DETAILS,
            clientDbId: payload.clientDbId,
            data: {
              id: payload.id,
              busHistory: 1,
              lang: i18n.language,
            },
          }
        );

        const data = assureArray(response.data?.row?.rez?.busenos);

        return data.map((item) => {
          return {
            comment: item.komentaras,
            date: item.korData,
            result: item.busena,
            statusColor: item.statusasSpalva,
            statusId: item.statusasId,
            statusName: item.statusasPavadinimas,
            user: item.vartotojas,
          };
        });
      }
    ),
    getTasksFilters: createAPIHandler(
      async (
        payload: TaskFiltersAPIPayload
      ): Promise<TaskFiltersAPIResponse> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_BUS_LIST,
          clientDbId: payload.clientDbId,
          data: {
            user: payload.user,
            response_type: 5,
            aktyvus: 0,
            lang: i18n.language,
            filter_xml: payload.filterXML,
          },
        });

        const apr1Result = assureArray(response.data?.filters?.apr1?.r);
        const apr2Result = assureArray(response.data?.filters?.apr2?.r);
        const metaiResult = assureArray(response.data?.filters?.metai?.r);
        const modulResult = assureArray(response.data?.filters?.modul?.r);

        return {
          apr1: apr1Result.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          apr2: apr2Result.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          metai: metaiResult.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          modul: modulResult.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
        };
      }
    ),
  };
};

export default TasksAPIRequest;
