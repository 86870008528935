import { Button } from "@adv-libs/r365-ui";
import React, { useCallback } from "react";
import styled from "styled-components";
import AdvSelect2WithBack from "../../../components/AdvSelect2WithBack/AdvSelect2WithBack";
import DropdownWithBack from "../../../components/DropdownWithBack/DropdownWithBack";
import { goBackPromise } from "../../../features/PopState/useResetHistory";
import { getHistory } from "../../../features/Router/history";
import useI18n from "../../../hooks/useI18n";

export interface CalendarFiltersProps {
  onApplyFilter: () => any;
  filterValue: any;
  filtersData: any;
  filtersIsLoading: boolean;
  confirmFilter: () => any;
  cancelFilter: () => any;
  onFilterChange: (propertyName: string, filterValue: any) => any;
  onClearFilter: () => any;
  onFiltersOpened: () => any;
  onFiltersClosed: () => any;
  filtersIsOpened: boolean;
}

const CalendarFilters: React.FC<CalendarFiltersProps> = (props) => {
  const { pt, t } = useI18n();

  const handleKodas4AFilterChange = useCallback(
    (kodas_4a) => {
      props.onFilterChange("kodas_4a", kodas_4a);
    },
    [props]
  );

  const handleKodasIsFilterChange = useCallback(
    (kodas_is) => {
      props.onFilterChange("kodas_is", kodas_is);
    },
    [props]
  );

  const handleKodasK12FilterChange = useCallback(
    (kodas_k12) => {
      props.onFilterChange("kodas_k12", kodas_k12);
    },
    [props]
  );

  const handleTipasFilterChange = useCallback(
    (tipas) => {
      props.onFilterChange("tipas", tipas);
    },
    [props]
  );

  const handleOpened = useCallback(() => {
    props.onFiltersOpened();
  }, [props]);

  const handleClosed = useCallback(() => {
    props.onFiltersClosed();
    props.cancelFilter();
  }, [props]);

  const handleCancelFilter = useCallback(async () => {
    await goBackPromise(getHistory());
  }, []);

  const handleClearFilter = useCallback(() => {
    props.onClearFilter();
  }, [props]);

  const handleApplyFilter = useCallback(() => {
    props.onApplyFilter();
  }, [props]);

  return (
    <CalendarFiltersStyled>
      <DropdownWithBack
        offsetY={4}
        isOpen={props.filtersIsOpened}
        onOpen={handleOpened}
        onBackClose={handleClosed}
      >
        <Button icon="filter">{t("Filter")}</Button>
        <FiltersMenu>
          <Filters>
            <AdvSelect2WithBack
              value={props.filterValue.kodas_4a}
              onCommit={handleKodas4AFilterChange}
              getItems={props.filtersData.kodas_4a}
              paginated={false}
              isClearable
              label={pt("filter", "Person")}
              multiple
              multipleCheckboxes
              showMenuConfirm
              detectPrimitive
              onValueClear={props.confirmFilter}
              onMenuClosed={props.confirmFilter}
              filter="fuzzy"
              readOnly={props.filtersIsLoading}
            />
            <AdvSelect2WithBack
              value={props.filterValue.kodas_k12}
              onCommit={handleKodasK12FilterChange}
              getItems={props.filtersData.kodas_k12}
              paginated={false}
              isClearable
              label={pt("filter", "Job position")}
              multiple
              multipleCheckboxes
              showMenuConfirm
              detectPrimitive
              onValueClear={props.confirmFilter}
              onMenuClosed={props.confirmFilter}
              filter="fuzzy"
              readOnly={props.filtersIsLoading}
            />
            <AdvSelect2WithBack
              value={props.filterValue.kodas_is}
              onCommit={handleKodasIsFilterChange}
              getItems={props.filtersData.kodas_is}
              paginated={false}
              isClearable
              label={pt("filter", "Department")}
              multiple
              multipleCheckboxes
              showMenuConfirm
              detectPrimitive
              onValueClear={props.confirmFilter}
              onMenuClosed={props.confirmFilter}
              filter="fuzzy"
              readOnly={props.filtersIsLoading}
            />
            <AdvSelect2WithBack
              value={props.filterValue.tipas}
              onCommit={handleTipasFilterChange}
              getItems={props.filtersData.tipas}
              paginated={false}
              isClearable
              label={pt("filter", "Type")}
              multiple
              multipleCheckboxes
              showMenuConfirm
              detectPrimitive
              onValueClear={props.confirmFilter}
              onMenuClosed={props.confirmFilter}
              filter="fuzzy"
              readOnly={props.filtersIsLoading}
            />
          </Filters>
          <ClearFilterContainer>
            <ClearFilterButton onClick={handleClearFilter}>
              {t("Clear filter")}
            </ClearFilterButton>
          </ClearFilterContainer>
          <FilterActionsContainer>
            <Button primary onClick={handleApplyFilter}>
              {t("Apply filter")}
            </Button>
            <CancelButton onClick={handleCancelFilter}>
              {t("Cancel")}
            </CancelButton>
          </FilterActionsContainer>
        </FiltersMenu>
      </DropdownWithBack>
    </CalendarFiltersStyled>
  );
};

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CancelButton = styled.div`
  text-align: center;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
`;

const ClearFilterButton = styled.div`
  text-align: left;
  padding: 16px 16px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
`;

const FilterActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .r365-button {
    & > div {
      width: 100%;
      .r365-button__content {
        width: 100%;
      }
    }
  }
`;

const ClearFilterContainer = styled.div``;

const FiltersMenu = styled.div`
  box-shadow: 0 0 30px 0px rgba(109, 109, 109, 0.3);
  border-radius: 6px;
  width: 400px;
  padding: 8px 8px;
  background: white;
`;

const CalendarFiltersStyled = styled.div``;

export default CalendarFilters;
