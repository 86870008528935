import { assureArray } from "@adv-libs/utils";
import { DataViewSchemaType } from "../components/DataView/types";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import {
  ApproveHistoryAPIPayload,
  ApproveHistoryAPIResponse,
  ApproveHistoryAPIResponseOriginal,
} from "./types";
import createAPIHandler from "./utils/createAPIHandler";

export type VacationsGetAPIResponse = {
  id: any;
};

export type VacationsListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  sort?: string;
  filter?: string;
  jobPosition?: any;
};

export type VacationsListAPIResponse = VacationsListAPIResponseItem[];

export type VacationsListAPIResponseItem = {
  id: any;
  title: string;
  dateFrom: string;
  dateTo: string;
  usedDays: string;
  statusId: any;
  statusName: string;
  statusColor: string;
};

export type VacationsListAPIResponseItemOriginal =
  JobPositionListAPIResponseItemVacationOriginal;

export type JobPositionListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  sort?: string;
  filter?: string;
  upcoming?: boolean;
};

export type JobPositionListAPIResponse = JobPositionListAPIResponseItem[];

export type JobPositionListAPIResponseItem = {
  id: any;
  name: string;
  startedFrom: string;
  accrued: string;
  accruedToYearEnd: string;
  accruedYearEnd: string;
  nextVacationOn?: string;
  upcoming?: VacationsListAPIResponseItem[];
};

export type JobPositionListAPIResponseItemOriginal = {
  Id: string;
  atost_likutis: string;
  atost_likutis_pb: string;
  metu_pb: string;
  name: string;
  priemimoData: string;
  artimiausiosAtos?: string;
  list?: {
    atostogos?:
      | JobPositionListAPIResponseItemVacationOriginal
      | JobPositionListAPIResponseItemVacationOriginal[];
  };
};

export type JobPositionListAPIResponseItemVacationOriginal = {
  AtostPavadinimas: string;
  AtostTipas: string;
  Id: string;
  PanaudotaDienu: string;
  ikiData: string;
  nuoData: string;
  statusasId: string;
  statusasPavadinimas: string;
  statusasSpalva: string;
};

export type VacationsTypeListAPIPayload = {
  clientDbId: string;
};

export type VacationsTypeListAPIResponse = VacationsTypeListAPIResponseItem[];

export type VacationsTypeListAPIResponseItem = {
  id: string;
  name: string;
  pastaba: string;
};

export type VacationsTypeListAPIResponseItemOriginal = {
  Id: string;
  name: string;
  pastaba: string;
};

export type VacationGetAPIPayload = {
  id: any;
  clientDbId: string;
};

export type VacationGetAPIResponse = {
  id: any;
  title: string;
  buttons: string;
  comment: boolean;
  statusColor: string;
  statusPav: string;
  statusId: string;
  add_data: string;
  kor_data: string;
  schema: DataViewSchemaType;
};

export type VacationRequestAPIPayload = {
  clientDbId: any;
  jobPosition: string;
  startDate: string;
  endDate: string;
  vacationType: string;
  substitutePerson?: string;
  comment?: string;
};

export type VacationCancelRequestAPIPayload = {
  clientDbId: any;
  id: any;
};

const VacationsAPIRequest = () => {
  return {
    listJobPositions: createAPIHandler(
      async (
        payload: JobPositionListAPIPayload
      ): Promise<JobPositionListAPIResponse> => {
        const response = await Client.post<{
          row: {
            rez: {
              list:
                | JobPositionListAPIResponseItemOriginal
                | JobPositionListAPIResponseItemOriginal[];
            };
          };
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_USER_ETAT_LIST,
          clientDbId: payload.clientDbId,
          data: {
            busAtost: payload.upcoming ? 1 : undefined,
          },
        });

        if (!response.data.row?.rez?.list) {
          return [];
        }

        const list = assureArray(response.data.row?.rez?.list);

        return list.map((d) => {
          const upcomingOriginal = d.list?.atostogos ? d.list.atostogos : null;
          let upcoming: VacationsListAPIResponseItem[];
          if (upcomingOriginal) {
            const upcomingOriginalArray = assureArray(upcomingOriginal);
            upcoming = upcomingOriginalArray.map((d) => {
              return {
                id: d.Id,
                title: d.AtostPavadinimas,
                dateFrom: d.nuoData,
                dateTo: d.ikiData,
                usedDays: d.PanaudotaDienu,
                statusColor: d.statusasSpalva,
                statusName: d.statusasPavadinimas,
                statusId: d.statusasId,
              };
            });
          }

          const result: JobPositionListAPIResponseItem = {
            id: d.Id,
            name: d.name,
            accrued: d.atost_likutis,
            accruedToYearEnd: d.atost_likutis_pb,
            accruedYearEnd: d.metu_pb,
            startedFrom: d.priemimoData,
            nextVacationOn: d.artimiausiosAtos,
            upcoming: upcoming,
          };

          return result;
        });
      }
    ),
    list: createAPIHandler(
      async (
        payload: VacationsListAPIPayload
      ): Promise<VacationsListAPIResponse> => {
        const response = await Client.post<{
          atostogos:
            | VacationsListAPIResponseItemOriginal
            | VacationsListAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_USER_ATOST_ISTORIJA,
          clientDbId: payload.clientDbId,
          data: {
            kodas_6a_f: payload.jobPosition,
            response_type: 1,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
          },
        });

        if (!response.data.atostogos) {
          return [];
        }

        const list = assureArray(response.data.atostogos);

        return list.map((d) => {
          return {
            id: d.Id,
            title: d.AtostPavadinimas,
            dateFrom: d.nuoData,
            dateTo: d.ikiData,
            usedDays: d.PanaudotaDienu,
            statusColor: d.statusasSpalva,
            statusName: d.statusasPavadinimas,
            statusId: d.statusasId,
          };
        });
      }
    ),
    get: createAPIHandler(
      async (
        payload: VacationGetAPIPayload
      ): Promise<VacationGetAPIResponse> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_ATOSTOG_DETAIL,
          clientDbId: payload.clientDbId,
          data: {
            Id: payload.id,
          },
        });

        const schema = assureArray(response.data.data.schema);

        return {
          ...response?.data.data,
          schema,
        };
      }
    ),
    typeList: createAPIHandler(
      async (
        payload: VacationsTypeListAPIPayload
      ): Promise<VacationsTypeListAPIResponse> => {
        const response = await Client.post<{
          list: VacationsTypeListAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_ATOST_TYPE_LIST,
          clientDbId: payload.clientDbId,
          data: {},
        });

        if (!response.data.list) {
          return [];
        }

        const list = assureArray(response.data.list);

        return list.map((item) => {
          return {
            id: item.Id,
            name: item.name,
            pastaba: item.pastaba,
          };
        });
      }
    ),
    vacationRequest: createAPIHandler(
      async (payload: VacationRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_CREATE_ATOSTOG_PRASYMAS,
          clientDbId: payload.clientDbId,
          data: {
            kodas_6a_f: payload.jobPosition,
            begDate: payload.startDate,
            endDate: payload.endDate,
            atostTipas: payload.vacationType,
            pavaduojantis: payload.substitutePerson,
            pastabos: payload.comment,
          },
        });
      }
    ),
    vacationCancelRequest: createAPIHandler(
      async (payload: VacationCancelRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_CANCEL_VACATION,
          clientDbId: payload.clientDbId,
          data: {
            Id: payload.id,
          },
        });
      }
    ),
    getApproveHistory: createAPIHandler(
      async (
        payload: ApproveHistoryAPIPayload
      ): Promise<ApproveHistoryAPIResponse> => {
        const response = await Client.post<ApproveHistoryAPIResponseOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_ATOSTOG_DETAIL,
            clientDbId: payload.clientDbId,
            data: {
              id: payload.id,
              busHistory: 1,
            },
          }
        );

        const data = assureArray(response.data?.row?.rez?.busenos);

        return data.map((item) => {
          return {
            comment: item.komentaras,
            date: item.korData,
            result: item.busena,
            statusColor: item.statusasSpalva,
            statusId: item.statusasId,
            statusName: item.statusasPavadinimas,
            user: item.vartotojas,
          };
        });
      }
    ),
  };
};

export default VacationsAPIRequest;
