import modalRegister from "./modalsRegister";

const createModal = (
  id: string,
  children:
    | React.FunctionComponentElement<any>
    | React.FunctionComponentElement<any>[]
) => {
  modalRegister.addModal({ id, children });

  return () => {
    modalRegister.removeModal(id);
  };
};

export default createModal;
