const fileIconsMap = {
  default: "/icons/icon-attach.svg",
  bmp: "/icons/icon-bmp.svg",
  doc: "/icons/icon-doc.svg",
  docx: "/icons/icon-docx.svg",
  jpg: "/icons/icon-jpg.svg",
  jpeg: "/icons/icon-jpg.svg",
  pdf: "/icons/icon-pdf.svg",
  png: "/icons/icon-png.svg",
  xls: "/icons/icon-xls.svg",
  xlsx: "/icons/icon-xlsx.svg",
  xml: "/icons/icon-xml.svg",
  svg: "/icons/icon-svg.svg",
  csv: "/icons/icon-csv.svg",
};

export default fileIconsMap;
