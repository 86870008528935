import useI18n from "../hooks/useI18n";

interface IErrorFallback {
  error?: Error | null;
  errorInfo?: { componentStack: string } | null;
}

const ErrorFallback = (props: IErrorFallback) => {
  const { error, errorInfo } = props;
  const { t } = useI18n();
  if (process.env.NODE_ENV === "development") {
    console.error(error); // eslint-disable-line no-console

    return (
      <div>
        <h1>{t("Something went wrong...")}</h1>
        <pre>{errorInfo ? errorInfo.componentStack : ""}</pre>
      </div>
    );
  }

  return (
    <div>
      <h1>{t("Something went wrong...")}</h1>
    </div>
  );
};

export default ErrorFallback;
