import { wait } from "@adv-libs/utils";
import { History, Location } from "history";
import { useEffect, useRef } from "react";
import { getHistory } from "../Router/history";

export const goBackPromise = (history: History) => {
  return new Promise<Location<any>>((resolve) => {
    const unlisten = history.listen((state) => {
      unlisten();
      resolve(state);
    });
    history.goBack();
  });
};

export const isCreatedUrl = () => {
  const history = getHistory();
  return !!history?.location?.state?.createdUrl;
};

export const waitForModalClose = async () => {
  let timeout = 0;
  while (isCreatedUrl() && timeout < 100) {
    await wait(1);
    timeout += 1;
  }
};

const checkIfBack = async (history: History<any>, fromPopState?: string) => {
  if (
    fromPopState &&
    typeof history.location.state?.popState === "string" &&
    history?.location.state?.createdUrl &&
    history.location.state?.popState === fromPopState
  ) {
    await goBackPromise(history);
    return true;
  } else if (
    !fromPopState &&
    typeof history.location.state?.popState === "string" &&
    history?.location.state?.createdUrl
  ) {
    await goBackPromise(history);
    return true;
  } else {
    return false;
  }
  // const result = await goBackPromise(history);
  // if (typeof result.state?.popState === "string") return true;
  // else return false;
};

export const resetHistory = async (
  history: History<any>,
  fromPopState?: string
) => {
  if (
    typeof history?.location.state?.popState === "string" &&
    history?.location.state?.createdUrl
  ) {
    // Go back until the popState exists
    while (await checkIfBack(history, fromPopState)) {}
  }
};

const useResetHistory = (router) => {
  const resetInvoked = useRef(false);

  useEffect(() => {
    const history = router?.history;
    if (history && resetInvoked.current === false) {
      resetInvoked.current = true;
      resetHistory(router?.history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.history]);
};

export default useResetHistory;
