import "@adv-libs/calendar/dist/style.css";
import "@adv-libs/icons/dist/style.css";
import "@adv-libs/r365-ui/dist/style.css";
import "normalize.css/normalize.css";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import "react-tooltip/dist/react-tooltip.css";
import { ThemeProvider } from "styled-components";
import App from "./app/App";
import "./app/i18n";
import "./bootstrap";
import "./index.css";
import LayoutLoader from "./layouts/LayoutLoader";
import GlobalStyle from "./style/GlobalStyle";
import Theme from "./style/theme";

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <Suspense fallback={<LayoutLoader />}>
      <App />
      <GlobalStyle />
    </Suspense>
  </ThemeProvider>,
  document.getElementById("root")
);
