import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvDatePickerRange,
  AdvDatePickerRangeProps,
  AdvFormFieldWrapper,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormDatePickerRangeProps
  extends Omit<AdvDatePickerRangeProps, "onCommit" | "value"> {
  name: string;
  size?: any;
}

const FormDatePickerRange: React.FC<FormDatePickerRangeProps> = (props) => {
  const { name, size, ...restProps } = props;

  const formIsSubmitting = useFormIsSubmitting();

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  return (
    <AdvFormField
      fieldName={name}
      control={AdvDatePickerRange}
      controlProps={restProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
    />
  );
};

export default React.memo(FormDatePickerRange);
