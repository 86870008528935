import React from "react";
import styled from "styled-components";
import CalendarFilters from "./CalendarFilters";
import CalendarMonthSelector from "./CalendarMonthSelector";

export interface CalendarToolbarProps {
  currentYear: number;
  currentMonth: number;
  onMonthChange: (year: number, month: number) => any;
  onApplyFilter: () => any;
  filterValue: any;
  filtersData: any;
  filtersIsLoading: boolean;
  confirmFilter: () => any;
  cancelFilter: () => any;
  onFilterChange: (propertyName: string, filterValue: any) => any;
  onClearFilter: () => any;
  onFiltersOpened: () => any;
  onFiltersClosed: () => any;
  filtersIsOpened: boolean;
}

const CalendarToolbar: React.FC<CalendarToolbarProps> = (props) => {
  return (
    <CalendarToolbarStyled>
      <CalendarMonthSelector
        currentYear={props.currentYear}
        currentMonth={props.currentMonth}
        onMonthChange={props.onMonthChange}
      />
      <CalendarFilters
        onApplyFilter={props.onApplyFilter}
        filterValue={props.filterValue}
        filtersData={props.filtersData}
        filtersIsLoading={props.filtersIsLoading}
        confirmFilter={props.confirmFilter}
        cancelFilter={props.cancelFilter}
        onFilterChange={props.onFilterChange}
        onClearFilter={props.onClearFilter}
        onFiltersOpened={props.onFiltersOpened}
        onFiltersClosed={props.onFiltersClosed}
        filtersIsOpened={props.filtersIsOpened}
      />
    </CalendarToolbarStyled>
  );
};

const CalendarToolbarStyled = styled.div`
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
`;

export default CalendarToolbar;
