import { GetState, SetState } from "zustand";
import { RootState } from "./useStore";

interface DatabaseType {
  clientDbId: string;
  clientDbName: string;
  clientName: string;
}

export interface UserType {
  userId: string;
  fullName: string;
  email: string;
  role: string;
}

export interface UserSlice {
  user: UserType;
  uiLanguage: string;
  userDb?: DatabaseType;
  setUser: (newUser: UserType) => void;
  clearUser: () => void;
  setUiLanguage: (lang: string) => void;
  setDb: (db: DatabaseType) => void;
}

const initialState = {
  uiLanguage: "lt",
  user: {
    userId: null,
    fullName: null,
    email: null,
    role: null,
  },
};

let createUserSlice = (set: SetState<RootState>, get: GetState<RootState>) => ({
  ...initialState,
  setUser: (newUser: UserType) => set((state) => ({ user: newUser })),
  clearUser: () =>
    set((state) => ({ user: initialState.user, userDb: undefined })),
  setUiLanguage: (lang: string) => set((state) => ({ uiLanguage: lang })),
  setDb: (dbInfo: DatabaseType) => set((state) => ({ userDb: dbInfo })),
});

export default createUserSlice;
