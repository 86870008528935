import { toast, ToastOptions } from "@adv-libs/r365-ui";
import serializeError from "../Error/serializeError";
import events from "../Events/events";

const toastError = (err: any | string, options: ToastOptions = {}) => {
  if (typeof err === "string") {
    toast.error(err, options);
    events.emit("toast-error", err);
  } else {
    const serializedError = serializeError(err);
    toast.error(serializedError, options);
    events.emit("toast-error", serializedError);
  }
};

export default toastError;
