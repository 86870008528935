import { Button } from "@adv-libs/r365-ui";
import { useIsMobile } from "@adv-libs/utils";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import { TasksListAPIResponse } from "../../api/tasksAPI";
import { ROUTE_APPROVE_TASK, ROUTE_TASK, ROUTE_TASKS } from "../../app/routes";
import AdvSelect2WithBack from "../../components/AdvSelect2WithBack/AdvSelect2WithBack";
import InfiniteCard from "../../components/InfiniteCard/InfiniteCard";
import NoDataCard from "../../components/NoDataCard";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import useRouterXMLFilter from "../../features/XMLFilter/RouterXMLFilter";
import useI18n from "../../hooks/useI18n";
import TaskCard from "./components/TaskCard";

export interface MyTasksHistoryProps {}

const XML_FILTER_PROPERTIES = ["apr1", "apr2", "metai", "modul"];

const MyTasksHistory: React.FC<MyTasksHistoryProps> = (props) => {
  const companyContext = useCompanyContext();
  const { t, pt } = useI18n();

  const fetchFilters = useCallback(
    async (filterXML) => {
      return await API.tasks.getTasksFilters({
        clientDbId: companyContext.company.clientDbId,
        user: companyContext.user.kodas_rs,
        filterXML: filterXML,
      });
    },
    [companyContext.company.clientDbId, companyContext.user.kodas_rs]
  );

  const {
    filterValue,
    setFilter,
    filterXML,
    hasFilter,
    filtersData,
    confirmFilter,
    clearFilter,
    showFilters,
    setShowFilters,
    isLoading,
  } = useRouterXMLFilter({
    filterProperties: XML_FILTER_PROPERTIES,
    fetchFilters: fetchFilters,
  });

  const handleApr1FilterChange = useCallback(
    (apr1) => {
      setFilter("apr1", apr1);
    },
    [setFilter]
  );

  const handleApr2FilterChange = useCallback(
    (apr2) => {
      setFilter("apr2", apr2);
    },
    [setFilter]
  );

  const handleMetaiFilterChange = useCallback(
    (metai) => {
      setFilter("metai", metai);
    },
    [setFilter]
  );

  const handleModulFilterChange = useCallback(
    (modul) => {
      setFilter("modul", modul);
    },
    [setFilter]
  );

  const request = useCallback(
    async (page: number) => {
      const result = await API.tasks.list({
        clientDbId: companyContext.company.clientDbId,
        filterXML: filterXML,
        page: page,
        size: 10,
        active: false,
      });

      return result;
    },
    [companyContext.company.clientDbId, filterXML]
  );

  const keepCache = useMemo(() => {
    return [ROUTE_TASKS.path, ROUTE_TASK.path, ROUTE_APPROVE_TASK.path];
  }, []);

  const handleFiltersToggle = useCallback(() => {
    setShowFilters((showFilters) => !showFilters);
  }, [setShowFilters]);

  const isMobile = useIsMobile();

  const filterItemDisabled = useCallback((item) => {
    return item.disabled;
  }, []);

  const filtersContainer = useMemo(() => {
    if (!showFilters) return null;
    return (
      <FiltersStyled>
        <Filters>
          <AdvSelect2WithBack
            value={filterValue.apr1}
            onCommit={handleApr1FilterChange}
            getItems={filtersData.apr1}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Title 1")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.apr2}
            onCommit={handleApr2FilterChange}
            getItems={filtersData.apr2}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Title 2")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.modul}
            onCommit={handleModulFilterChange}
            getItems={filtersData.modul}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Type")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.metai}
            onCommit={handleMetaiFilterChange}
            getItems={filtersData.metai}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Year Of Approval")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
        </Filters>
        <ClearFilterButton>
          <Button
            icon={"times-circle-regular"}
            minimal
            disabled={!hasFilter}
            onClick={clearFilter}
          >
            {t("Clear filters")}
          </Button>
        </ClearFilterButton>
      </FiltersStyled>
    );
  }, [
    showFilters,
    filterValue.apr1,
    filterValue.apr2,
    filterValue.modul,
    filterValue.metai,
    handleApr1FilterChange,
    filtersData.apr1,
    filtersData.apr2,
    filtersData.modul,
    filtersData.metai,
    filterItemDisabled,
    confirmFilter,
    isLoading,
    handleApr2FilterChange,
    handleModulFilterChange,
    handleMetaiFilterChange,
    t,
    pt,
    hasFilter,
    clearFilter,
  ]);

  const filtersIcon = useMemo(() => {
    return (
      <FilterIcon>
        <Button
          onClick={handleFiltersToggle}
          icon={"filter-xmark"}
          iconSize={16}
        >
          {!isMobile ? t("Filter") : null}
        </Button>
      </FilterIcon>
    );
  }, [handleFiltersToggle, isMobile, t]);

  return (
    <MyTasksHistoryStyled>
      <InfiniteCard
        showMore
        id="tasks-history"
        keepOn={keepCache}
        header={pt("approvals", "Approval history")}
        request={request}
        filtersContainer={filtersContainer}
        rightIcon={filtersIcon}
        threshold={9}
      >
        {(items: TasksListAPIResponse) => {
          if (!items.length) {
            return <NoDataCard>{t("No approvals")}</NoDataCard>;
          }
          return (
            <MyTasksItems>
              {items.map((item, key) => {
                return <TaskCard key={key} item={item} />;
              })}
            </MyTasksItems>
          );
        }}
      </InfiniteCard>
    </MyTasksHistoryStyled>
  );
};

const MyTasksItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Filters = styled.div`
  display: grid;
  gap: 8px;

  grid-template-columns: repeat(1, 1fr);

  .r365-control-select2 {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FilterIcon = styled.div``;

const ClearFilterButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FiltersStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const MyTasksHistoryStyled = styled.div``;

export default MyTasksHistory;
