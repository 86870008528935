import clsx from "clsx";
import { useCallback, useLayoutEffect } from "react";
import styled from "styled-components";
import { CompaniesAPIResponseItem } from "../../../api/companiesAPI";
import { ROUTE_LOGIN } from "../../../app/routes";
import persistentCompanyId from "../../../features/Companies/persistentCompanyId";
import { getHistory } from "../../../features/Router/history";

export interface CompaniesBoxesProps {
  data: CompaniesAPIResponseItem[];
  autoSelect?: boolean;
  onChange: (dbId: string | number) => void;
}

function CompaniesBoxes(props: CompaniesBoxesProps) {
  const { data } = props;

  useLayoutEffect(() => {
    const latestCompanyId = persistentCompanyId.getValue();

    if (!data) return;
    if (!props.autoSelect) return;

    const from = getHistory().location.state?.from;

    if (from && from.startsWith(ROUTE_LOGIN.path)) {
      if (data.length === 1) {
        const clientDbId = data[0].clientDbId;
        props.onChange(clientDbId);
      } else if (data.length > 1 && latestCompanyId) {
        const exist = data.find(
          (company) => company.clientDbId === "" + latestCompanyId
        );
        if (exist) {
          props.onChange(latestCompanyId);
        }
      }
    }
  }, [data, props]);

  return (
    <CompanyContainerStyled>
      {data.map((item, key) => {
        return <CompanyItem key={key} item={item} onChange={props.onChange} />;
      })}
    </CompanyContainerStyled>
  );
}

interface CompanyItemProps {
  item: CompaniesAPIResponseItem;
  onChange: (clientDbId: string) => any;
}

const CompanyItem: React.FC<CompanyItemProps> = (props) => {
  const handleClick = useCallback(() => {
    props.onChange(props.item.clientDbId);
  }, [props]);

  let databaseName = props.item.companyName?.trim?.();
  if (!databaseName) {
    databaseName = props.item.clientDbName;
  }

  return (
    <div className={clsx("box", "r365-close-dropdown")} onClick={handleClick}>
      <div className="button-container ">
        <div className="logo-name-container">
          <div className="name-container">
            <div className="name_database">{databaseName}</div>

            <div className="name_company">{props.item.clientName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesBoxes;

const CompanyContainerStyled = styled.div`
  width: 100%;

  .box {
    border-radius: ${({ theme }) => theme.radius};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;
    gap: 10px;
    padding: 0px 10px;
    // min-width: 234px;

    &:hover {
      background-color: ${({ theme }) => theme.buttonHoverColor};
    }

    // &.active {
    //   background-color: ${({ theme }) => theme.buttonHoverColor};
    // }

    .button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .main-btn-link {
      color: inherit;
      width: 100%;
    }

    .logo-name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .icon-down {
      font-size: 0.5rem;
      margin-bottom: 0px;
      width: 5%;

      .r365-icon {
        font-size: 0.5rem;
      }
    }

    .name-container {
      padding: 10px 0px;

      .name_database {
        mark {
          border-radius: 6px;
          background-color: #6aac6a64;
        }
      }

      .name_company,
      .name_status {
        color: ${({ theme }) => theme.fontColorFaded};
        font-size: 0.625rem; // 10px
      }
    }
  }
`;
