import React, { useCallback, useState } from "react";
import { __addModalSetter } from "./modalsRegister";

export interface ModalContainerProps {}

const ModalContainer: React.FC<ModalContainerProps> = (props) => {
  const [modals, setModals] = useState([]);

  const addModal = useCallback((modal: { id; children }) => {
    setModals((modals) => {
      return [...modals, modal];
    });
  }, []);

  const removeModal = useCallback((modalId) => {
    setModals((modals) => {
      return modals.filter(({ id }) => id !== modalId);
    });
  }, []);

  __addModalSetter({ addModal, removeModal });

  return (
    <>
      {modals.map((modal) => {
        return <React.Fragment key={modal.id}>{modal.children}</React.Fragment>;
        // return (
        //   <React.Fragment key={modal.id}>
        //     {ReactDOM.createPortal(modal.children, document.body)}
        //   </React.Fragment>
        // );
      })}
    </>
  );
};

export default ModalContainer;
