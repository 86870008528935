import React from "react";
import ErrorMessage from "./ErrorMessage";
import Spinner from "./Spinner/Spinner";

export interface StateLoaderProps {
  error?: any;
  isLoading?: boolean;
  children?: () => React.ReactNode;
}

const StateLoader: React.FC<StateLoaderProps> = (props) => {
  if (props.error) {
    return <ErrorMessage error={props.error} noMargin />;
  }

  if (props.isLoading) {
    return <Spinner />;
  }
  return <>{typeof props.children === "function" ? props.children() : null}</>;
};

export default StateLoader;
