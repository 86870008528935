import { useMemo } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { BaseLayout } from "../layouts";

interface IPrivateRouteProps {
  component: any;
  layout?: React.ComponentType;
  isAllowed: boolean;
  redirectTo: string;
  path: string;
  exact?: boolean;
}

function PrivateRoute(props: IPrivateRouteProps) {
  const {
    component: Component,
    isAllowed,
    redirectTo,
    path,
    exact,
    ...componentProps
  } = props;

  const location = useLocation();

  const Layout = useMemo(() => {
    return props.layout === null ? null : props.layout ?? BaseLayout;
  }, [props.layout]);

  return (
    <div>
      {isAllowed ? (
        <Route key="routeview" path={path}>
          {Layout ? (
            <Layout>
              <Component {...componentProps} />
            </Layout>
          ) : (
            <Component {...componentProps} />
          )}
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: {
              from: location.pathname + location.search,
              redirect: true,
            },
          }}
        />
      )}
    </div>
  );
}

export default PrivateRoute;
