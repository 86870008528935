import { Button, Callout } from "@adv-libs/r365-ui";
import { KeyCode, keyEvents } from "@adv-libs/utils";
import React, { useCallback, useEffect } from "react";
import useI18n from "../../hooks/useI18n";
import { openDynamicModal } from "./openDynamicModal";
import { AlertButtonsGroup, AlertMessage } from "./style";

export interface OpenConfirmOptions {
  message: React.ReactNode;
  intent?: string;
  confirm?: string;
  cancel?: string;
}

const openConfirm = (options: OpenConfirmOptions) => {
  return new Promise(async (resolve) => {
    await openDynamicModal({
      maxWidth: 400,
      props: {
        message: options.message,
        intent: options.intent,
        confirm: options.confirm,
        cancel: options.cancel,
      },
      onClose: (resolveValue: boolean | undefined) => resolve(!!resolveValue),
      Component: ConfirmDialog,
    });
  });
};

interface ConfirmDialogProps {
  message: React.ReactNode;
  confirm?: string;
  cancel?: string;
  intent?: string;
  onClose?: (resolveValue: boolean) => any;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { t } = useI18n();

  let alertMessageProps: Record<string, any> = { children: props.message };
  if (typeof props.message === "string") {
    alertMessageProps = {
      dangerouslySetInnerHTML: { __html: props.message },
    };
  }

  const handleConfirm = useCallback(() => {
    props.onClose(true);
  }, [props]);

  const handleCancel = useCallback(() => {
    props.onClose(false);
  }, [props]);

  const handleEnterConfirm = useCallback(() => {
    handleConfirm();
  }, [handleConfirm]);

  useEffect(() => {
    keyEvents.addEventListener(KeyCode.KEY_RETURN, handleEnterConfirm);

    return () => {
      keyEvents.removeEventListener(KeyCode.KEY_RETURN, handleEnterConfirm);
    };
  }, [handleEnterConfirm]);

  return (
    <Callout intent={props.intent} noMargin light transparent={false}>
      <AlertMessage {...alertMessageProps} />
      <AlertButtonsGroup>
        <Button minimal onClick={handleCancel}>
          {props.cancel || t("No")}
        </Button>
        <Button
          onClick={handleConfirm}
          primary
          newStyle
          danger={props.intent === "danger"}
          dark={props.intent === "danger"}
        >
          {props.confirm || t("Yes")}
        </Button>
      </AlertButtonsGroup>
    </Callout>
  );
};

export default openConfirm;
