const convertToString = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    // if it's not an object (including arrays) or it's null
    return String(obj); // Convert it to a string and return
  }

  if (Array.isArray(obj)) {
    // If it's an array
    return obj.map((item) => convertToString(item)); // Recurse for each item in the array
  }

  // If it's an object
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = convertToString(obj[key]); // Recurse for each property value
    }
  }
  return newObj;
};

export default convertToString;
