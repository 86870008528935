enum APIProcedures {
  RGI_MGAMA_GET_IM_INF = "RGI_MGAMA_GET_IM_INF",
  RGI_MGAMA_GET_ASM_INF = "RGI_MGAMA_GET_ASM_INF",
  RGI_MGAMA_GET_BUS_LIST = "RGI_MGAMA_GET_BUS_LIST",
  RGI_MGAMA_GET_BUS_DETAILS = "RGI_MGAMA_GET_BUS_DETAILS",
  RGI_MGAMA_ATOST_ISMOK_TYPE_LIST = "RGI_MGAMA_ATOST_ISMOK_TYPE_LIST",
  RGI_MGAMA_ATOST_TYPE_LIST = "RGI_MGAMA_ATOST_TYPE_LIST",
  RGI_MGAMA_GET_USER_ETAT_LIST = "RGI_MGAMA_GET_USER_ETAT_LIST",
  RGI_MGAMA_GET_USER_ATOST_ISTORIJA = "RGI_MGAMA_GET_USER_ATOST_ISTORIJA",
  RGI_MGAMA_GET_ATOSTOG_DETAIL = "RGI_MGAMA_GET_ATOSTOG_DETAIL",
  RGI_MGAMA_CREATE_ATOSTOG_PRASYMAS = "RGI_MGAMA_CREATE_ATOSTOG_PRASYMAS",
  RGI_MGAMA_CANCEL_VACATION = "RGI_MGAMA_CANCEL_VACATION",
  RGI_MGAMA_BUS_ACTION = "RGI_MGAMA_BUS_ACTION",
  RGI_MGAMA_GET_DOC = "RGI_MGAMA_GET_DOC",
  RGI_MGAMA_GET_BUS_HISTORY = "RGI_MGAMA_GET_BUS_HISTORY",
  RGI_MGAMA_GET_PERS_KAL = "RGI_MGAMA_GET_PERS_KAL",
  RGI_MGAMA_GET_PERS_KAL_INFO = "RGI_MGAMA_GET_PERS_KAL_INFO",
  RGI_MGAMA_GET_USER_KOMAND_ISTORIJA = "RGI_MGAMA_GET_USER_KOMAND_ISTORIJA",
  RGI_MGAMA_GET_KOMAND_DETAIL = "RGI_MGAMA_GET_KOMAND_DETAIL",
  RGI_MGAMA_CREATE_KOMAND_PRASYMAS = "RGI_MGAMA_CREATE_KOMAND_PRASYMAS",
  RGI_MGAMA_KOMAND_PRASYMAS_INIT = "RGI_MGAMA_KOMAND_PRASYMAS_INIT",
  RGI_MGAMA_GET_USER_MODULES = "RGI_MGAMA_GET_USER_MODULES",
  RGI_MGAMA_FORM_SP_SELECT = "RGI_MGAMA_FORM_SP_SELECT",
  RGI_MGAMA_FORM_INIT = "RGI_MGAMA_FORM_INIT",
  RGI_MGAMA_FORM_EDIT = "RGI_MGAMA_FORM_EDIT",
  RGI_MGAMA_DVS_OP_LIST = "RGI_MGAMA_DVS_OP_LIST",
  RGI_MGAMA_DVS_FORM_TYPE_LIST = "RGI_MGAMA_DVS_FORM_TYPE_LIST",
  RGI_MGAMA_FORM_CANCEL = "RGI_MGAMA_FORM_CANCEL",
  RGI_MGAMA_FORM_LIST = "RGI_MGAMA_FORM_LIST",
  RGI_MGAMA_FORM_MG02_GET = "RGI_MGAMA_FORM_MG02_GET",
  RGI_MGAMA_FORM_MG02_EDIT = "RGI_MGAMA_FORM_MG02_EDIT",
  RGI_MGAMA_FORM_GET_SS_DVS_RUSYS = "RGI_MGAMA_FORM_GET_SS_DVS_RUSYS",
}

export default APIProcedures;
