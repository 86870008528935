import { assureArray } from "@adv-libs/utils";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import createAPIHandler from "./utils/createAPIHandler";
import i18n from "i18next";

export type FormsGetListAPIResponse = FormsGetListAPIResponseItem[];

export type FormsGetListAPIResponseItem = {
  apr1: string;
  apr2: string;
  apr3: string;
  eil_nr: any;
  id: any;
  aktyvi: any;
};

export type FormsListAPIResponse = FormsListAPIResponseItem[];

export type FormsListAPIResponseItem = {
  title: string;
  id: any;
  isActive: boolean;
};

export type FormGetAPIPayload = {
  formId: any;
  clientDbId: string;
};

export type FormGetAPIResponseOriginal = {
  add_date: string;
  aktyvi: string;
  forma: string;
  id: any;
  kodas_op: string;
  pav: string;
  r_date: string;
  ss: any;
  modul: string;
  sql_init?: string;
  sql_save?: string;
};

export type FormGetAPIResponse = {
  addDate?: string;
  active: boolean;
  formXML: string;
  id: any;
  dvsType: string;
  title: string;
  updateDate?: string;
  isSystemic: any;
  sqlInit?: string;
  sqlSave?: string;
};

export type EditFormAPIPayload = {
  active: boolean;
  formXML: string;
  id: any;
  dvsType: string;
  title: string;
  sqlInit: string;
  sqlSave: string;
  clientDbId: string;
};

export type GetFormListAPIPayload = {
  clientDbId: string;
  filterXML?: string;
  page?: number;
  size?: number;
};

export type GetDvsTypeListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  filter?: string;
};

export type GetDvsTypeListAPIResponse = GetDvsTypeListAPIResponseItem[];

export type GetDvsTypeListAPIResponseItem = {
  id: string;
  name: string;
};

const DocumentsAPIRequest = () => {
  return {
    getFormsList: createAPIHandler(
      async (payload: GetFormListAPIPayload): Promise<FormsListAPIResponse> => {
        const response = await Client.post<{
          app: FormsGetListAPIResponse;
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_LIST,
          clientDbId: payload.clientDbId,
          data: {
            filter_xml: payload.filterXML,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
          },
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((item) => {
          return {
            title: item.apr1,
            id: item.id,
            isActive: item.aktyvi && item.aktyvi === "0" ? false : true,
          };
        });
      }
    ),
    get: createAPIHandler(
      async (payload: FormGetAPIPayload): Promise<FormGetAPIResponse> => {
        const response = await Client.post<{ app: FormGetAPIResponseOriginal }>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_FORM_MG02_GET,
            clientDbId: payload.clientDbId,
            data: {
              id: payload.formId,
              lang: i18n.language,
            },
          }
        );

        const item = response.data.app;

        return {
          active: item.aktyvi && item.aktyvi === "0" ? false : true,
          formXML: item.forma,
          id: item.id,
          dvsType: item.kodas_op,
          title: item.pav,
          isSystemic: item.ss && item.ss === "0" ? false : true,
          sqlInit: item.sql_init,
          sqlSave: item.sql_save,
        };
      }
    ),
    editForm: createAPIHandler(
      async (payload: EditFormAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_MG02_EDIT,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.id,
            aktyvi: payload.active ? "1" : "0",
            pav: payload.title,
            modul: "OD",
            kodas_op: payload.dvsType,
            forma: payload.formXML,
            sql_init: payload.sqlInit,
            sql_save: payload.sqlSave,
          },
        });
      }
    ),
    getDvsTypeList: createAPIHandler(
      async (
        payload: GetDvsTypeListAPIPayload
      ): Promise<GetDvsTypeListAPIResponse> => {
        const response = await Client.post<{
          app: GetDvsTypeListAPIResponse;
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_SP_SELECT,
          clientDbId: payload.clientDbId,
          data: {
            proc: APIProcedures.RGI_MGAMA_FORM_GET_SS_DVS_RUSYS,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
            filter: payload.filter,
          },
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      }
    ),
    deleteForm: createAPIHandler(
      async (payload: FormGetAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_MG02_EDIT,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.formId,
            delete: 1,
          },
        });
      }
    ),
  };
};

export default DocumentsAPIRequest;
