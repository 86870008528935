import { Icon } from "@adv-libs/icons";
import { AdvMonthPicker } from "@adv-libs/r365-ui";
import { format, parse, startOfMonth } from "date-fns";
import React, { useCallback, useMemo } from "react";
import spacetime from "spacetime";
import styled from "styled-components";

export interface CalendarMonthSelectorProps {
  currentYear: number;
  currentMonth: number;
  onMonthChange: (year: number, month: number) => any;
}

const CalendarMonthSelector: React.FC<CalendarMonthSelectorProps> = (props) => {
  const { currentYear, currentMonth, onMonthChange } = props;

  const value = useMemo(() => {
    return format(
      startOfMonth(new Date(currentYear, currentMonth)),
      "yyyy-MM-dd"
    );
  }, [currentYear, currentMonth]);

  const handleDateChange = useCallback(
    (value: string) => {
      const date = spacetime(parse(value, "yyyy-MM-dd", new Date()));
      const year = date.year();
      const month = date.month();
      onMonthChange(year, month);
    },
    [onMonthChange]
  );

  const handleRightClick = useCallback(() => {
    currentMonth === 11
      ? onMonthChange(currentYear + 1, 0)
      : onMonthChange(currentYear, currentMonth + 1);
  }, [currentMonth, currentYear, onMonthChange]);

  const handleLeftClick = useCallback(() => {
    currentMonth === 0
      ? onMonthChange(currentYear - 1, 11)
      : onMonthChange(currentYear, currentMonth - 1);
  }, [currentMonth, currentYear, onMonthChange]);

  return (
    <CalendarMonthSelectorStyled>
      <Picker>
        <Icon
          icon="caret-down"
          className="caret-left"
          onClick={handleLeftClick}
        />
        <Icon
          icon="caret-up"
          className="caret-right"
          onClick={handleRightClick}
        />
        <AdvMonthPicker
          value={value}
          onCommit={handleDateChange}
          showIcon={false}
          minimal
        />
      </Picker>
    </CalendarMonthSelectorStyled>
  );
};

const Picker = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding-left: 4px;

  .r365-icon {
    font-size: 12px;
    width: 24px;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  .r365-icon.caret-left {
    svg {
      transform: rotate(90deg);
    }
  }

  .r365-icon.caret-right {
    svg {
      transform: rotate(90deg);
    }
  }
`;

const CalendarMonthSelectorStyled = styled.div`
  padding: 8px 0;

  .r365-control-month {
    background-color: transparent !important;
    padding-left: 0;

    input {
      font-weight: 600;
      font-size: 18px;
      padding-left: 4px;
      width: 156px;
    }
  }
`;

export default CalendarMonthSelector;
