import { AdvFormAPI } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvSelect2,
  AdvSimpleSelect,
  Button,
} from "@adv-libs/r365-ui";
import { useIsMobile } from "@adv-libs/utils";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import API from "../../api/API";
import { ROUTE_MAIN } from "../../app/routes";
import CopyField from "../../components/CopyField";
import { FormTextField } from "../../components/form";
import Form from "../../components/form/Form";
import { MainFormErrorStyled } from "../../components/form/MainFormError";
import SubmitButton from "../../components/form/SubmitButton";
import PageTitle from "../../components/PageTitle";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { createGoSafeBack } from "../../features/Router/goSafeBack";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import toastSuccess from "../../features/ToastMessage/toastSuccess";
import useI18n from "../../hooks/useI18n";
import Container from "../../layouts/Container";
import FullWidthContainer from "../../layouts/FullWidthContainer";
import { minScreenLength } from "../../style/theme";
import { setUrlWithCompanyId } from "../../utils/path";

export interface UserProps {}

const languages = [
  { id: "en", name: "English" },
  { id: "lt", name: "Lietuvių" },
];

const UserSettings: React.FC<UserProps> = (props) => {
  const { t, i18n } = useI18n();

  const companyContext = useCompanyContext();

  const initialLanguage = useMemo(() => {
    const language = languages.find((item) => item.id === i18n.language);
    return language;
  }, [i18n.language]);

  const [language, setLanguage] = useState(initialLanguage);

  const isMobile = useIsMobile(minScreenLength);

  const handlePasswordSubmit = useCallback(
    async (values, formAPI: AdvFormAPI) => {
      await API.auth.changePassword(values);
      formAPI.setValuesObject({}, true, false);
      toastSuccess(t("Password changed!"));
    },
    [t]
  );

  const handleLanguageChange = useCallback((language, options) => {
    if (options?.silent) {
      setLanguage(language);
    } else {
      window.localStorage.setItem("i18nextLng", language.id);
      window.location.reload();
    }
  }, []);

  const validation = useMemo(() => {
    return yup.object({
      oldPassword: yup.string().required(t("Field is required")),
      password: yup.string().required(t("Field is required")),
      repeatPassword: yup
        .string()
        .required(t("Field is required"))
        .oneOf([yup.ref("password"), null], t("Passwords must match")),
    });
  }, [t]);

  const fullName = [companyContext.user.vardas, companyContext.user.pavarde]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      <PageTitle>{t("Profile settings")}</PageTitle>
      <ScrollRestoration id="user-settings" restoreTop />
      <FullWidthContainer>
        <Container>
          <UserSettingsContainer>
            <UserInfoForm>
              <div className="h2">{t("User information")}</div>
              {fullName ? (
                <>
                  <div className="subheading">{t("Fullname")}</div>
                  <CopyField>{fullName}</CopyField>
                </>
              ) : null}
              {companyContext.user.tel ? (
                <>
                  <div className="subheading">{t("Phone number")}</div>
                  <CopyField>{companyContext.user.tel}</CopyField>
                </>
              ) : null}
              {companyContext.user.email ? (
                <>
                  <div className="subheading">{t("Email")}</div>
                  <CopyField>{companyContext.user.email}</CopyField>
                </>
              ) : null}
            </UserInfoForm>
            <UserPasswordForm>
              <div className="h2">{t("Password")}</div>
              <Form
                onSubmit={handlePasswordSubmit}
                validation={validation}
                showGenericError={true}
                toastifyError={false}
              >
                <FormTextField
                  type="password"
                  name="oldPassword"
                  label={t("Current password")}
                />
                <FormTextField
                  type="password"
                  name="password"
                  label={t("New password")}
                />
                <FormTextField
                  type="password"
                  name="repeatPassword"
                  label={t("Repeat password")}
                />
                <FormActions>
                  <SubmitButton>{t("Save")}</SubmitButton>
                </FormActions>
              </Form>
            </UserPasswordForm>
            <LanguageSelectForm>
              <div className="h2">{t("Select language")}</div>
              <AdvFormFieldWrapper>
                {isMobile ? (
                  <AdvSimpleSelect
                    fieldName="language"
                    getItems={languages}
                    value={language}
                    onCommit={handleLanguageChange}
                  />
                ) : (
                  <AdvSelect2
                    fieldName="language"
                    detectPrimitive
                    paginated={false}
                    filter="fuzzy"
                    getItems={languages}
                    value={language}
                    onCommit={handleLanguageChange}
                  />
                )}
              </AdvFormFieldWrapper>
              <FormActions>
                <Button
                  fill
                  onClick={createGoSafeBack(
                    setUrlWithCompanyId(ROUTE_MAIN.path)
                  )}
                >
                  {t("Close")}
                </Button>
              </FormActions>
            </LanguageSelectForm>
          </UserSettingsContainer>
        </Container>
      </FullWidthContainer>
    </>
  );
};

const FormActions = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 14px;
  margin-right: 0;
  margin-left: auto;

  .r365-button {
    width: 100%;
  }
`;

const UserSettingsContainer = styled.div`
  background: white;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 30px;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    max-width: 600px;
    margin-top: 10vh;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }

  form {
    .r365-form-field-wrapper--padding {
      padding-left: 0;
      padding-right: 0;
    }

    ${MainFormErrorStyled} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const UserInfoForm = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 25px 30px 25px 20px;

  .h2 {
    margin-bottom: 14px;
  }

  .subheading {
    margin-bottom: 2px;
  }
`;

const UserPasswordForm = styled.div`
  padding: 25px 30px 25px 20px;

  .h2 {
    margin-bottom: 14px;
  }
`;

const LanguageSelectForm = styled.div`
  padding: 0px 30px 25px 20px;

  .h2 {
    margin-bottom: 14px;
  }
`;

export default UserSettings;
