import { Icon } from "@adv-libs/icons";
import { assureArray } from "@adv-libs/utils";
import clsx from "clsx";
import download from "downloadjs";
import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import API from "../../api/API";
import toastError from "../../features/ToastMessage/toastError";
import breakpoints from "../../style/breakpoints";
import fileIconsMap from "./iconsMap";
import {
  DataViewBlockType,
  DataViewInlineType,
  DataViewItemType,
  DataViewLinkType,
  DataViewListItemFileType,
  DataViewListType,
  DataViewSchemaType,
} from "./types";

export interface DataViewProps {
  schema: DataViewSchemaType;
  clientDbId: string;
}

const DataView: React.FC<DataViewProps> = (props) => {
  if (!Array.isArray(props.schema)) return null;

  return (
    <DataViewStyled>
      <table>
        {props.schema.map((item, key) => {
          return (
            <DataViewItem key={key} item={item} clientDbId={props.clientDbId} />
          );
        })}
      </table>
    </DataViewStyled>
  );
};

interface DataViewItemProps {
  item: DataViewItemType;
  clientDbId: string;
}

const DataViewItem: React.FC<DataViewItemProps> = (props) => {
  if (props.item.type === "inline") {
    return <DataViewInline item={props.item} />;
  } else if (props.item.type === "block") {
    return <DataViewBlock item={props.item} />;
  } else if (props.item.type === "list") {
    return <DataViewList item={props.item} clientDbId={props.clientDbId} />;
  } else if (props.item.type === "link") {
    return <DataViewLink item={props.item} />;
  } else if (props.item.type === "space") {
    return <DataViewSpace />;
  } else {
    return null;
  }
};

interface DataViewBlockProps {
  item: DataViewBlockType;
}

const DataViewBlock: React.FC<DataViewBlockProps> = (props) => {
  return (
    <tbody>
      <tr className="data-view-block-top">
        <td className={clsx("data-view-key")} colSpan={2}>
          {props.item.key}
        </td>
      </tr>
      <tr className="data-view-block-bottom">
        <td colSpan={2} className="data-view-value">
          {props.item.value &&
            props.item.value.split("\n").map((line, index) => {
              return <p key={index}>{line}</p>;
            })}
        </td>
      </tr>
    </tbody>
  );
};

interface DataViewInlineProps {
  item: DataViewInlineType;
}

const DataViewInline: React.FC<DataViewInlineProps> = (props) => {
  const valueStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (props.item.color) {
      style["color"] = props.item.color;
    }
    return style;
  }, [props.item.color]);

  return (
    <tbody>
      <tr className="data-view-inline">
        <td
          className={clsx("data-view-key", {
            "data-view-key--empty": !props.item.key,
          })}
        >
          {props.item.key}
        </td>
        <td
          className={clsx("data-view-value", {
            "data-view-value--empty": !props.item.value,
          })}
          style={valueStyle}
        >
          {props.item.value}
        </td>
      </tr>
    </tbody>
  );
};

interface DataViewLinkProps {
  item: DataViewLinkType;
}

const DataViewLink: React.FC<DataViewLinkProps> = (props) => {
  return (
    <tbody>
      <tr className="data-view-link">
        <td
          className={clsx("data-view-key", {
            "data-view-key--empty": !props.item.key,
          })}
        >
          {props.item.key}
        </td>
        <td
          className={clsx("data-view-value", {
            "data-view-value--empty": !props.item.value,
            "data-view-value--only-link": !props.item.title,
          })}
        >
          <a href={props.item.value} target="__blank">
            {props.item.title ?? props.item.value}
          </a>
        </td>
      </tr>
    </tbody>
  );
};

interface DataViewSpaceProps {}

const DataViewSpace: React.FC<DataViewSpaceProps> = (props) => {
  return (
    <tbody>
      <tr className="data-view-space" style={{ height: 10 }}></tr>
    </tbody>
  );
};

interface DataViewListItemSpaceProps {}

const DataViewListItemSpace: React.FC<DataViewListItemSpaceProps> = (props) => {
  return <tr className="data-view-list-space" style={{ height: 10 }}></tr>;
};

interface DataViewListItemInlineProps {
  item: DataViewInlineType;
}

const DataViewListItemInline: React.FC<DataViewListItemInlineProps> = (
  props
) => {
  const valueStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (props.item.color) {
      style["color"] = props.item.color;
    }
    return style;
  }, [props.item.color]);

  return (
    <tr className="data-view-list-inline">
      <td
        className={clsx("data-view-key", {
          "data-view-key--empty": !props.item.key,
        })}
      >
        {props.item.key}
      </td>
      <td
        className={clsx("data-view-value", {
          "data-view-value--empty": !props.item.value,
        })}
        style={valueStyle}
      >
        {props.item.value}
      </td>
    </tr>
  );
};

interface DataViewListItemFileProps {
  item: DataViewListItemFileType;
  clientDbId: string;
}

const DataViewListItemFile: React.FC<DataViewListItemFileProps> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPDFViewing, setPDFIsViewing] = useState(false);

  let ext = null;
  if (props.item.value.includes(".")) {
    const splitted = props.item.value.toLowerCase().split(".");
    ext = splitted[splitted.length - 1];
  }

  let iconUrl = fileIconsMap["default"];
  if (ext && fileIconsMap[ext]) {
    iconUrl = fileIconsMap[ext];
  }

  const getFileData = useCallback(async () => {
    const data = await API.files.getFile({
      id: props.item.id,
      clientDbId: props.clientDbId,
    });

    return data;
  }, [props.clientDbId, props.item.id]);

  const handleFileDownload = useCallback(async () => {
    if (isDownloading) return;
    try {
      setIsDownloading(true);
      const data = await getFileData();

      if (data.link) {
        window.open(data.link, "_blank");
      } else {
        const buffer = Buffer.from(data.content, "base64");

        const blob = new Blob([buffer], {});
        download(blob, props.item.value);
      }
    } catch (err) {
      toastError(err);
    }
    setIsDownloading(false);
  }, [isDownloading, getFileData, props.item.value]);

  const handlePDFView = useCallback(async () => {
    if (isPDFViewing) return;
    try {
      setPDFIsViewing(true);
      const data = await getFileData();
      const buffer = Buffer.from(data.content, "base64");

      const blob = new Blob([buffer], { type: "application/pdf" });
      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL, "__blank");
    } catch (err) {
      toastError(err);
    }
    setPDFIsViewing(false);
  }, [getFileData, isPDFViewing]);

  const isPDF = /\.pdf$/i.test(props.item.value);

  return (
    <tr
      className={clsx("data-view-list-file", isDownloading ? "loading" : null)}
    >
      <td colSpan={2} className={clsx("data-view-key")}>
        <div className="file-container">
          <div className="file">
            <div className="file-ext-icon">
              <img src={iconUrl} alt="" />
            </div>
            <div className="file-name">{props.item.value}</div>
          </div>
          <div className="file-actions">
            {isPDF ? (
              <div className="file-view-icon" onClick={handlePDFView}>
                {isPDFViewing ? (
                  <Icon icon={["spin", 2200]} spin />
                ) : (
                  <Icon icon="eye-solid" />
                )}
              </div>
            ) : null}
            <div className="file-download-icon" onClick={handleFileDownload}>
              {isDownloading ? (
                <Icon icon={["spin", 2200]} spin />
              ) : (
                <Icon icon="import" />
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

interface DataViewListProps {
  item: DataViewListType;
  clientDbId: string;
}

const DataViewList: React.FC<DataViewListProps> = (props) => {
  const schema = assureArray(props.item.data?.schema ?? []);

  return (
    <>
      <tbody>
        <tr className="data-view-list-top">
          <td className="data-view-key" colSpan={2}>
            {props.item.key}
          </td>
        </tr>
      </tbody>
      <tbody className="data-view-list">
        {schema.map((item, index) => {
          if (item.type === "inline") {
            return <DataViewListItemInline key={index} item={item} />;
          } else if (item.type === "file") {
            return (
              <DataViewListItemFile
                key={index}
                clientDbId={props.clientDbId}
                item={item}
              />
            );
          } else if (item.type === "space") {
            return <DataViewListItemSpace key={index} />;
          } else {
            return null;
          }
        })}
      </tbody>
      <tbody>
        <DataViewListItemSpace />
      </tbody>
    </>
  );
};

export const DataViewStyled = styled.div`
  margin: 0 auto;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    tr {
      td {
        vertical-align: middle;

        padding: 8px 10px;
        padding-left: 16px;

        &.data-view-key {
          font-weight: 600;
          color: #000000;
          overflow-wrap: break-word;
        }

        &.data-view-value {
          font-weight: 400;
          color: rgb(47, 47, 47);
          overflow-wrap: break-word;
        }
      }
    }

    tbody:nth-child(odd) {
      tr.data-view-inline,
      tr.data-view-link {
        .data-view-key {
          background: #f5f5f8;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        .data-view-value {
          background: #f5f5f8;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }

    tr.data-view-link {
      .data-view-value--only-link {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }

      a {
        color: rgb(13, 128, 95);
        text-decoration: underline;
      }
    }

    tr.data-view-list-inline {
      td.data-view-key {
        font-weight: 600;
        color: rgb(47, 47, 47);
        padding-left: 15px;
        overflow-wrap: break-word;
      }
    }

    tr.data-view-list-file {
      td.data-view-key {
        font-weight: 400;
        padding-left: 15px;

        .file-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #f5f5f8;
          padding: 5px;
          padding-left: 18px;
          padding-right: 10px;
          border-radius: 6px;

          .file {
            display: flex;
            align-items: center;

            .file-ext-icon {
              margin-right: 6px;
              padding-top: 2px;

              img {
                width: 30px;
                height: auto;
              }
            }
          }

          .file-actions {
            display: flex;
            gap: 8px;

            .file-view-icon {
              color: #474747;
              cursor: pointer;
              background: #e2e2e2;
              width: 36px;
              height: 36px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;

              transition: background 0.15s linear;

              &:hover {
                background: #d2d2d2;
              }

              .r365-icon svg[style] {
                font-size: 14px !important;
              }
            }

            .file-download-icon {
              color: #474747;
              cursor: pointer;
              padding: 12px;
              width: 36px;
              height: 36px;
              background: #e2e2e2;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;

              transition: background 0.15s linear;

              &:hover {
                background: #d2d2d2;
              }

              .r365-icon svg[style] {
                font-size: 14px !important;
              }
            }
          }
        }
      }

      &.loading {
        pointer-events: none;
      }
    }

    tr.data-view-list-top {
      .data-view-key {
        padding-top: 15px;
      }
    }

    tr.data-view-block-top {
      .data-view-key {
        padding-top: 15px;
        padding-bottom: 0px;
      }
    }

    tr.data-view-block-bottom {
      td.data-view-value {
        line-height: 1.4;
        border-radius: 6px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: 400;
        color: rgb(47, 47, 47);

        p {
          margin: 2px 0;
        }
      }
    }

    tbody.data-view-list {
      box-shadow: 0 0 0 1px #e6e6e6;
      border-radius: 6px;

      tr:first-child td {
        padding-top: 15px;
      }

      tr:last-child td {
        padding-bottom: 15px;
      }
    }

    @media screen and (max-width: ${breakpoints.xs}px) {
      table-layout: initial;

      tr.data-view-inline,
      tr.data-view-link {
        td.data-view-key,
        td.data-view-value {
          display: block;

          &--empty {
            display: none;
          }
        }

        td.data-view-value {
          padding-top: 0;
        }
      }

      tr.data-view-list-top {
        td.data-view-key {
          display: block;
        }
      }

      tr.data-view-list-inline {
        td.data-view-key,
        td.data-view-value {
          display: block;

          &--empty {
            display: none;
          }
        }
      }
    }
  }
`;

export default DataView;
