import { Icon } from "@adv-libs/icons";
import { Menu } from "@adv-libs/r365-ui";
import React from "react";
import styled from "styled-components";
import DropdownWithBack from "../../../components/DropdownWithBack/DropdownWithBack";
import { useCompanyContext } from "../CompanyContext";
import BackButton, { BackButtonStyled } from "./BackButton";
import CompaniesSwitch from "./CompaniesSwitch";
import CompanyInformation from "./CompanyInformation";

export interface CompanyHeaderProps {}

const CompanyHeader: React.FC<CompanyHeaderProps> = (props) => {
  const companyContext = useCompanyContext();

  if (!companyContext) {
    return null;
  }

  let databaseName = companyContext.company.companyName?.trim?.();
  if (!databaseName) {
    databaseName = companyContext.company.clientDbName;
  }

  return (
    <CompanyHeaderStyled>
      <BackButton></BackButton>
      <DropdownWithBack align="start" offsetY={1}>
        <Header>
          <CompanyInfo>
            <DatabaseName>{databaseName}</DatabaseName>
            <CompanyName>{companyContext.company.clientName}</CompanyName>
          </CompanyInfo>
          <DropdownIcon>
            <Icon icon={"caret-down"} />
          </DropdownIcon>
        </Header>
        <Menu>
          <CompanyInformation clientDbId={companyContext.company.clientDbId} />
          <CompaniesSwitch />
        </Menu>
      </DropdownWithBack>
    </CompanyHeaderStyled>
  );
};

const Header = styled.div`
  position: relative;
  height: 64px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    height: 50px;
  }
`;

const DatabaseName = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const CompanyName = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const CompanyHeaderStyled = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.headerHeight}px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: white;
  z-index: 100;
  border-bottom: 1px solid #e4e4e4;

  ${BackButtonStyled} {
    margin-right: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .r365-dropdown {
    position: relative;
    display: block;
    width: 100%;

    .r365-menu {
      position: relative;
      padding: 0;
      max-height: initial;
    }
  }

  /* Mobile */
  @media screen and (max-width: ${(props) =>
      props.theme.minScreenLength - 1}px) {
    .r365-popover[style] {
      left: 0 !important;
      right: 0 !important;
    }

    .r365-dropdown {
      .r365-popover {
        height: 100%;
        pointer-events: none;
        padding-bottom: calc(${({ theme }) => theme.headerHeight}px + 64px);

        .r365-menu {
          pointer-events: all;
          border-radius: 0;
          max-height: 100%;
        }

        .r365-menu:not(.r365-menu--flat) {
          box-shadow: none;
          border-bottom: 1px solid #e4e4e4;
        }
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    top: 5px;
    left: 90px;
    right: auto;
    z-index: 200;
    border-bottom: 0;

    ${BackButtonStyled} {
      display: none;
    }

    .r365-dropdown {
      .r365-popover {
        height: 100%;
        pointer-events: none;
        padding-bottom: calc(5px + 50px + 10px);

        .r365-menu {
          top: 10px;
          max-height: 100%;
          pointer-events: all;
        }
      }
    }
  }
`;

const CompanyInfo = styled.div`
  margin-right: 50px;
`;

const DropdownIcon = styled.div`
  .r365-icon {
    font-size: 10px;
  }
`;

export default CompanyHeader;
