import { Icon } from "@adv-libs/icons";
import { copyToClipboard } from "@adv-libs/utils";
import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import toastInfo from "../features/ToastMessage/toastInfo";
import useI18n from "../hooks/useI18n";

export interface CopyFieldProps {}

const CopyField: React.FC<CopyFieldProps> = (props) => {
  const contentRef = useRef<HTMLSpanElement>();
  const { t } = useI18n();

  const handleCopy = useCallback(() => {
    const content = contentRef.current.textContent;
    copyToClipboard(content);
    toastInfo(t("Copied"), {
      autoClose: 1000,
    });
  }, [t]);

  return (
    <CopyFieldStyled onClick={handleCopy}>
      <CopyButton>
        <Icon icon="copy"></Icon>
      </CopyButton>
      <span ref={contentRef}>{props.children}</span>
    </CopyFieldStyled>
  );
};

export const CopyFieldStyled = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const CopyButton = styled.div`
  padding: 10px 10px 10px 0;
  cursor: pointer;
`;

export default CopyField;
