import { Icon } from "@adv-libs/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useCacheContext } from "../../features/Cache/CacheContext";
import breakpoints from "../../style/breakpoints";
import BackButtonDesktop from "../BackButtonDesktop";
import AccordionBody from "./AccordionBody";

export interface AccordionProps {
  title: string;
  icon?: any;
  iconSrc?: any;
  expandedByDefault?: boolean;
  minHeight?: number;
  showBackButton?: boolean;
  disabled?: boolean;
  cache?: {
    id: string;
    keepOn: string[];
  };
}

const CACHE_SCOPE = "accordion";

const Accordion: React.FC<AccordionProps> = (props) => {
  const cacheContext = useCacheContext();

  const defaultExpandedFromCache = props.cache
    ? cacheContext.getCache(CACHE_SCOPE, props.cache?.id) ??
      props.expandedByDefault
    : props.expandedByDefault;

  const [isOpen, setIsOpen] = useState(defaultExpandedFromCache);

  const handleHeaderClick = useCallback(() => {
    if (!props.disabled) {
      setIsOpen((state) => !state);
    }
  }, [props.disabled]);

  const iconProps = useMemo(() => {
    if (props.icon) {
      return {
        icon: props.icon,
      };
    }

    if (props.iconSrc) {
      return {
        src: props.iconSrc,
      };
    }

    return {};
  }, [props.icon, props.iconSrc]);

  useEffect(() => {
    if (props.cache) {
      cacheContext.setCache(
        CACHE_SCOPE,
        props.cache.id,
        props.cache.keepOn,
        isOpen
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <AccordionStyled>
      {props.showBackButton ? <BackButtonDesktop /> : null}

      <AccordionHeader
        disabled={props.disabled}
        showBackButton={props.showBackButton}
        onClick={handleHeaderClick}
        isOpen={isOpen}
      >
        <span className="accordion-title">
          {props.icon || props.iconSrc ? (
            <span className="accordion-title-icon">
              <Icon {...iconProps} />
            </span>
          ) : null}
          {props.title}
        </span>
        <span className="accordion-icon">
          {!props.disabled ? <Icon icon={"caret-down"} /> : null}
        </span>
      </AccordionHeader>
      {isOpen ? (
        <AccordionBody minHeight={props.minHeight}>
          {props.children}
        </AccordionBody>
      ) : null}
    </AccordionStyled>
  );
};

const AccordionHeader = styled.div<{
  showBackButton?: boolean;
  disabled?: boolean;
  isOpen: boolean;
}>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 8px;

  .accordion-title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .accordion-title-icon {
      font-size: 22px;
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }

  .accordion-icon {
    font-size: 10px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform 0.2s ease-in-out;

    ${({ isOpen }) => (isOpen ? "transform: rotate(180deg);" : "")}
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const AccordionStyled = styled.div`
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: white;
  overflow: hidden;
`;

export default Accordion;
