import { assureArray } from "@adv-libs/utils";
import { DataViewSchemaType } from "../components/DataView/types";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import {
  ApproveHistoryAPIPayload,
  ApproveHistoryAPIResponse,
  ApproveHistoryAPIResponseOriginal,
} from "./types";
import createAPIHandler from "./utils/createAPIHandler";

export type BusinessTripsGetAPIResponse = {
  id: any;
};

export type BusinessTripsListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  sort?: string;
  filter?: string;
  jobPosition?: any;
};

export type BusinessTripsListAPIResponse = BusinessTripsListAPIResponseItem[];

export type BusinessTripsListAPIResponseItem = {
  id: any;
  title: string;
  description: string;
  dateFrom: string;
  dateTo: string;
  location: string;
  statusId: any;
  statusName: string;
  statusColor: string;
};

export type BusinessTripsListAPIResponseItemOriginal =
  JobPositionListAPIResponseItemBusinessTripOriginal;

export type JobPositionListAPIPayload = {
  clientDbId: string;
  size?: number;
  page?: number;
  sort?: string;
  filter?: string;
  upcoming?: boolean;
};

export type JobPositionListAPIResponse = JobPositionListAPIResponseItem[];

export type JobPositionListAPIResponseItem = {
  id: any;
  name: string;
  startedFrom: string;
  accrued: string;
  accruedToYearEnd: string;
  accruedYearEnd: string;
  upcoming?: BusinessTripsListAPIResponseItem[];
  nextBusinessTripOn?: string;
};

export type JobPositionListAPIResponseItemOriginal = {
  Id: string;
  atost_likutis: string;
  atost_likutis_pb: string;
  metu_pb: string;
  name: string;
  priemimoData: string;
  artimiausiaKomand?: string;
  list_komand?: {
    komandiruotes?:
      | JobPositionListAPIResponseItemBusinessTripOriginal
      | JobPositionListAPIResponseItemBusinessTripOriginal[];
  };
};

export type JobPositionListAPIResponseItemBusinessTripOriginal = {
  KomandTikslas: string;
  KomandTipas: string;
  KomandPavadinimas: string;
  VietosPavadinimas: string;
  Id: string;
  ikiData: string;
  nuoData: string;
  statusasId: string;
  statusasPavadinimas: string;
  statusasSpalva: string;
};

export type BusinessTripsTypeListAPIPayload = {
  clientDbId: string;
};

export type BusinessTripsTypeListAPIResponse =
  BusinessTripsTypeListAPIResponseItem[];

export type BusinessTripsTypeListAPIResponseItem = {
  id: string;
  name: string;
  pastaba: string;
};

export type BusinessTripsTypeListAPIResponseItemOriginal = {
  Id: string;
  name: string;
  pastaba: string;
};

export type BusinessTripInitAPIResponseItem = {
  pareigos_list?: {
    pareigos?:
      | JobPositionListAPIResponseItemOriginal
      | JobPositionListAPIResponseItemOriginal[];
  };
  vietoves_list?: {
    vietoves?:
      | BusinessTripsTypeListAPIResponseItemOriginal
      | BusinessTripsTypeListAPIResponseItemOriginal[];
  };
  tipai_list?: {
    tipai?:
      | BusinessTripsTypeListAPIResponseItemOriginal
      | BusinessTripsTypeListAPIResponseItemOriginal[];
  };
};

export type BusinessTripsInitAPIResponse = {
  destinationsList: BusinessTripsTypeListAPIResponseItem[];
  businessTypesList: BusinessTripsTypeListAPIResponseItem[];
  jobsList: JobPositionListAPIResponseItem[];
};

export type BusinessTripGetAPIPayload = {
  id: any;
  clientDbId: string;
};

export type BusinessTripGetAPIResponse = {
  id: any;
  title: string;
  buttons: string;
  comment: boolean;
  statusColor: string;
  statusPav: string;
  statusId: string;
  add_data: string;
  kor_data: string;
  schema: DataViewSchemaType;
};

export type BusinessTripRequestAPIPayload = {
  clientDbId: any;
  jobPosition: string;
  startDate: string;
  endDate: string;
  businessTripType: string;
  destinationCode?: string;
  destinationName: string;
  purpose: string;
  comment?: string;
};

export type BusinessTripCancelRequestAPIPayload = {
  clientDbId: any;
  id: any;
};

const BusinessTripsAPIRequest = () => {
  return {
    listJobPositions: createAPIHandler(
      async (
        payload: JobPositionListAPIPayload
      ): Promise<JobPositionListAPIResponse> => {
        const response = await Client.post<{
          row: {
            rez: {
              list:
                | JobPositionListAPIResponseItemOriginal
                | JobPositionListAPIResponseItemOriginal[];
            };
          };
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_USER_ETAT_LIST,
          clientDbId: payload.clientDbId,
          data: {
            busKomand: payload.upcoming ? 1 : undefined,
          },
        });

        if (!response.data.row?.rez?.list) {
          return [];
        }

        const list = assureArray(response.data.row?.rez?.list);

        return list.map((d) => {
          const upcomingOriginal = d.list_komand?.komandiruotes
            ? d.list_komand.komandiruotes
            : null;
          let upcoming: BusinessTripsListAPIResponseItem[];
          if (upcomingOriginal) {
            const upcomingOriginalArray = assureArray(upcomingOriginal);
            upcoming = upcomingOriginalArray.map((d) => {
              return {
                id: d.Id,
                title: d.KomandPavadinimas,
                description: d.KomandTikslas,
                dateFrom: d.nuoData,
                dateTo: d.ikiData,
                location: d.VietosPavadinimas,
                statusColor: d.statusasSpalva,
                statusName: d.statusasPavadinimas,
                statusId: d.statusasId,
              };
            });
          }

          const result: JobPositionListAPIResponseItem = {
            id: d.Id,
            name: d.name,
            accrued: d.atost_likutis,
            accruedToYearEnd: d.atost_likutis_pb,
            accruedYearEnd: d.metu_pb,
            startedFrom: d.priemimoData,
            nextBusinessTripOn: d.artimiausiaKomand,
            upcoming: upcoming,
          };

          return result;
        });
      }
    ),
    list: createAPIHandler(
      async (
        payload: BusinessTripsListAPIPayload
      ): Promise<BusinessTripsListAPIResponse> => {
        const response = await Client.post<{
          komandiruotes:
            | BusinessTripsListAPIResponseItemOriginal
            | BusinessTripsListAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_USER_KOMAND_ISTORIJA,
          clientDbId: payload.clientDbId,
          data: {
            kodas_6a_f: payload.jobPosition,
            response_type: 1,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
          },
        });

        if (!response.data.komandiruotes) {
          return [];
        }

        const list = assureArray(response.data.komandiruotes);

        return list.map((d) => {
          return {
            id: d.Id,
            title: d.KomandPavadinimas,
            description: d.KomandTikslas,
            dateFrom: d.nuoData,
            dateTo: d.ikiData,
            location: d.VietosPavadinimas,
            statusColor: d.statusasSpalva,
            statusName: d.statusasPavadinimas,
            statusId: d.statusasId,
          };
        });
      }
    ),
    get: createAPIHandler(
      async (
        payload: BusinessTripGetAPIPayload
      ): Promise<BusinessTripGetAPIResponse> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_KOMAND_DETAIL,
          clientDbId: payload.clientDbId,
          data: {
            Id: payload.id,
          },
        });

        const schema = assureArray(response.data.data.schema);

        return {
          ...response?.data.data,
          schema,
        };
      }
    ),
    getBusinessRequestInit: createAPIHandler(
      async (
        payload: BusinessTripsTypeListAPIPayload
      ): Promise<BusinessTripsInitAPIResponse> => {
        const response = await Client.post<{
          rez: BusinessTripInitAPIResponseItem;
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_KOMAND_PRASYMAS_INIT,
          clientDbId: payload.clientDbId,
          data: {},
        });

        const vietovesList = response.data.rez.vietoves_list?.vietoves
          ? assureArray(response.data.rez.vietoves_list.vietoves)
          : [];
        const tipaiList = response.data.rez.tipai_list?.tipai
          ? assureArray(response.data.rez.tipai_list.tipai)
          : [];
        const pareigosList = response.data.rez.pareigos_list?.pareigos
          ? assureArray(response.data.rez.pareigos_list.pareigos)
          : [];

        const destinationsList = vietovesList.map((item) => ({
          id: item.Id,
          name: item.Id + " - " + item.name,
          pastaba: item.pastaba,
        }));

        const businessTypesList = tipaiList.map((item) => ({
          id: item.Id,
          name: item.name,
          pastaba: item.pastaba,
        }));

        const jobsList = pareigosList.map((item) => ({
          id: item.Id,
          name: item.name,
          accrued: item.atost_likutis,
          accruedToYearEnd: item.atost_likutis_pb,
          accruedYearEnd: item.metu_pb,
          startedFrom: item.priemimoData,
        }));

        return { destinationsList, businessTypesList, jobsList };
      }
    ),
    businessTripRequest: createAPIHandler(
      async (payload: BusinessTripRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_CREATE_KOMAND_PRASYMAS,
          clientDbId: payload.clientDbId,
          data: {
            kodas_6a_f: payload.jobPosition,
            begDate: payload.startDate,
            endDate: payload.endDate,
            komandTipas: payload.businessTripType,
            komandTikslas: payload.purpose,
            vietosKodas: payload.destinationCode,
            vietosPavadinimas: payload.destinationName,
            pastabos: payload.comment,
          },
        });
      }
    ),
    businessTripCancelRequest: createAPIHandler(
      async (payload: BusinessTripCancelRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_CANCEL_VACATION,
          clientDbId: payload.clientDbId,
          data: {
            Id: payload.id,
          },
        });
      }
    ),
    getApproveHistory: createAPIHandler(
      async (
        payload: ApproveHistoryAPIPayload
      ): Promise<ApproveHistoryAPIResponse> => {
        const response = await Client.post<ApproveHistoryAPIResponseOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_KOMAND_DETAIL,
            clientDbId: payload.clientDbId,
            data: {
              id: payload.id,
              busHistory: 1,
            },
          }
        );

        const data = assureArray(response.data?.row?.rez?.busenos);

        return data.map((item) => {
          return {
            comment: item.komentaras,
            date: item.korData,
            result: item.busena,
            statusColor: item.statusasSpalva,
            statusId: item.statusasId,
            statusName: item.statusasPavadinimas,
            user: item.vartotojas,
          };
        });
      }
    ),
  };
};

export default BusinessTripsAPIRequest;
