import { Dialog } from "@adv-libs/r365-ui";
import { useAutoNameFallback } from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/redux/reduxStore";
import PopStateItem from "../PopState/PopStateItem";
import { goBackPromise } from "../PopState/useResetHistory";
import { getHistory } from "../Router/history";
import useCloseModal from "./hooks/useCloseModal";
import useOpenModal from "./hooks/useOpenModal";
import ModalContext from "./ModalContex";
import {
  createModalIsOpenSelector,
  createModalIsRegisteredSelector,
  registerModal,
  removeModal,
} from "./reducer";

export interface ModalProps {
  modalName?: string;
  defaultIsOpen?: boolean;
  title?: string;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  isCloseButtonShown?: boolean;
  onOpened?: () => any;
  onClosed?: () => any;
  onPopState?: (state) => any;
  maxWidth?: any;
  maxHeight?: any;
  height?: any;
  closeIcon?: any;
  closeIconSize?: any;
}

export interface ModalHandle {
  modalName: string;
  open: (options?: { onClose?: (...args: any) => any; context?: any }) => any;
  close: (...args: any) => any;
}

const Modal = React.forwardRef<
  ModalHandle,
  React.PropsWithChildren<ModalProps>
>(({ children, ...props }, ref) => {
  const onCloseCallbackRef = useRef<any>();
  const [modalContext, setModalContext] = useState([]);

  const dispatch = useAppDispatch();

  const modalName = useAutoNameFallback(props.modalName, "modal");

  const open = useOpenModal(modalName);
  // const close = useCloseModal(modalName);

  const providerValue = useMemo(() => {
    return { context: modalContext, modalName };
  }, [modalContext, modalName]);

  const modalIsRegisteredSelector = useMemo(() => {
    return createModalIsRegisteredSelector(modalName);
  }, [modalName]);

  const modalIsRegistered = useSelector(modalIsRegisteredSelector);

  const handleOpened = useCallback(() => {
    if (typeof props.onOpened === "function") {
      props.onOpened();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.onOpened]);

  const handleClosed = useCallback(() => {
    if (typeof onCloseCallbackRef.current === "function") {
      onCloseCallbackRef.current();
    }
    if (typeof props.onClosed === "function") {
      props.onClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.onClosed]);

  const handleOpen = useCallback(
    (options) => {
      onCloseCallbackRef.current = options?.onClose;
      setModalContext(options?.context);
      open();
    },
    [open]
  );

  const handleClose = useCallback(
    async (...args) => {
      if (typeof onCloseCallbackRef.current === "function") {
        onCloseCallbackRef.current(...args);
      }
      // close();
      await goBackPromise(getHistory());
      setModalContext([]);
      handleClosed();
      // resetHistory(getHistory(), "pop-state-" + modalName);
    },
    [handleClosed]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        modalName: modalName,
        open: handleOpen,
        close: handleClose,
      };
    },
    [handleClose, handleOpen, modalName]
  );

  useLayoutEffect(() => {
    dispatch(registerModal({ modalName, defaulIsOpen: props.defaultIsOpen }));

    return () => {
      dispatch(removeModal({ modalName }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContext.Provider value={providerValue}>
      {modalIsRegistered ? (
        <ModalDialog
          onOpened={handleOpened}
          onClosed={handleClosed}
          modalName={modalName}
          title={props.title}
          maxWidth={props.maxWidth}
          maxHeight={props.maxHeight}
          height={props.height}
          onPopState={props.onPopState}
          closeIcon={props.closeIcon}
          closeIconSize={props.closeIconSize}
        >
          {children}
        </ModalDialog>
      ) : null}
    </ModalContext.Provider>
  );
});

interface ModalDialogProps {
  modalName: string;
  title?: string;
  maxWidth?: any;
  maxHeight?: any;
  height?: any;
  closeIcon?: any;
  closeIconSize?: any;
  onOpened: () => any;
  onClosed: () => any;
  onPopState?: (state) => any;
}

const ModalDialog: React.FC<ModalDialogProps> = ({ children, ...props }) => {
  const modalIsOpenSelector = useMemo(() => {
    return createModalIsOpenSelector(props.modalName);
  }, [props.modalName]);

  const closedWithBackRef = useRef(false);

  const modalIsOpen = useSelector(modalIsOpenSelector);
  const close = useCloseModal(props.modalName);

  const handleBackClose = useCallback(() => {
    closedWithBackRef.current = true;
    close();
    props.onClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, props?.onClosed]);

  const handleManualClose = useCallback(() => {
    closedWithBackRef.current = false;
    getHistory().goBack();
    // setTimeout(() => {
    //   props.onClosed();
    // }, 100);
    // close();
  }, []);

  useEffect(() => {
    if (modalIsOpen) {
      props.onOpened();
      closedWithBackRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  // useSecondEffect(() => {
  //   console.log("modal", props.modalName, modalIsOpen, closedWithBackRef.current);
  //   if (!modalIsOpen) {
  //     if (!closedWithBackRef.current && props.onPopState) {
  //       getHistory().goBack();
  //     }
  //     props.onClosed();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalIsOpen, props.onPopState]);

  return modalIsOpen
    ? ReactDOM.createPortal(
        <>
          <div
            data-testid="modal"
            data-name={props.modalName}
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 999,
              background: "rgba(0, 0, 0, 0.32)",
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
            }}
          >
            <PopStateItem
              name={props.modalName}
              onPopState={props.onPopState}
              onBack={handleBackClose}
              createUrl
            >
              <Dialog
                maxWidth={props.maxWidth}
                maxHeight={props.maxHeight}
                height={props.height}
                onClose={handleManualClose}
              >
                {children}
              </Dialog>
            </PopStateItem>
          </div>
        </>,
        document.body
      )
    : null;
};

export default Modal;
