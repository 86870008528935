import i18n from "../../utils/i18n";

const createAPIHandler = <T, R>(handler: (payload?: T) => Promise<R>) => {
  return async (payload?: T) => {
    try {
      return await handler(payload);
    } catch (err) {
      if (err.isAxiosError && err.response && err.response.status === 401) {
        if (err.request?.responseURL?.endsWith?.("/auth/login")) {
          throw new Error(i18n.t("Invalid username or password"));
        }
      }

      throw err;
    }
  };
};

export default createAPIHandler;
