import React, { useMemo } from "react";
import { ROUTE_APPROVE_TASK, ROUTE_TASK, ROUTE_TASKS } from "../../app/routes";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import MyTasksHistory from "./MyTasksHistory";
import TasksDashboardWidget from "./TasksDashboardWidget";

export interface MyTasksProps {}

const MyTasks: React.FC<MyTasksProps> = (props) => {
  const { pt } = useI18n();

  const keepScroll = useMemo(() => {
    return [ROUTE_TASKS.path, ROUTE_TASK.path, ROUTE_APPROVE_TASK.path];
  }, []);

  const cache = useMemo(() => {
    return {
      id: "my-tasks-widget",
      keepOn: [ROUTE_TASKS.path, ROUTE_TASK.path],
    };
  }, []);

  return (
    <>
      <PageTitle>{pt("approvals", "Approvals")}</PageTitle>
      <ScrollRestoration id="tasks" keepOn={keepScroll} />
      <Grid>
        <GridCol col-s="2 / -2" col-l="3 / -3" col-xxl="4 / -4">
          <PageContent>
            <TasksDashboardWidget showBackButton cache={cache} />
            <MyTasksHistory />
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

export default MyTasks;
