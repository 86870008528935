import React, { useCallback, useEffect } from "react";
import { getHistory } from "../Router/history";
import getPopStateName from "./getPopStateName";
import popStateBuffer from "./popStateBuffer";

export interface PopStateItemProps {
  name: string;
  onPopState: (state) => any;
  onBack?: () => any;
  createUrl?: boolean;
}

const PopStateItem: React.FC<PopStateItemProps> = (props) => {
  const popStateName = getPopStateName(props.name);

  useEffect(() => {
    if (props.onPopState && props.createUrl) {
      const history = getHistory();
      history.push({
        pathname: window.location.pathname,
        search: window.location.search,
        state: {
          ...history.location.state,
          popState: popStateName,
          createdUrl: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.onPopState) {
      popStateBuffer.add(popStateName, props.onPopState);
    }

    return () => {
      if (props.onPopState) {
        popStateBuffer.remove(popStateName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPopState]);

  const onBack = props.onBack;

  const handlePop = useCallback(
    (state, action) => {
      if (action === "POP") {
        const currentPopState = state?.state?.popState;
        const lastPopStateName = popStateBuffer.getLastName();
        if (
          lastPopStateName === popStateName &&
          lastPopStateName !== currentPopState
        ) {
          if (typeof onBack === "function") {
            onBack();
          }
        }
      }
    },
    [popStateName, onBack]
  );

  useEffect(() => {
    const history = getHistory();
    if (history) {
      return history.listen(handlePop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePop]);

  return <>{props.children}</>;
};

export default PopStateItem;
