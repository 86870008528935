import { useEffect } from "react";
import modHistory from "./modHistory";

const useModHistory = (router) => {
  useEffect(() => {
    modHistory(router);
  }, [router]);
};

export default useModHistory;
