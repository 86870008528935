import React from "react";
import styled from "styled-components";
import breakpoints from "../../style/breakpoints";
import { SpinnerStyled } from "../Spinner/Spinner";

export interface AccordionBodyProps {
  minHeight?: number;
}

const AccordionBody: React.FC<AccordionBodyProps> = (props) => {
  return (
    <AccordionBodyStyled minHeight={props.minHeight}>
      {props.children}
    </AccordionBodyStyled>
  );
};

const AccordionBodyStyled = styled.div<{ minHeight?: number }>`
  border-top: 1px solid #e6e6e6;
  padding: 16px 8px;

  min-height: ${(props) =>
    typeof props.minHeight === "number" ? props.minHeight + "px" : "initial"};

  ${SpinnerStyled} {
    margin: 0;
    min-height: ${(props) =>
      typeof props.minHeight === "number" ? props.minHeight + "px" : "initial"};
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default AccordionBody;
