import { Icon } from "@adv-libs/icons";
import React from "react";
import styled from "styled-components";
import { ROUTE_MAIN } from "../app/routes";
import { createGoSafeBack } from "../features/Router/goSafeBack";
import useI18n from "../hooks/useI18n";
import breakpoints from "../style/breakpoints";
import { setUrlWithCompanyId } from "../utils/path";

export interface BackButtonDesktopProps {}

const BackButtonDesktop: React.FC<BackButtonDesktopProps> = (props) => {
  const { t } = useI18n();

  return (
    <BackButtonDesktopStyled>
      <span onClick={createGoSafeBack(setUrlWithCompanyId(ROUTE_MAIN.path))}>
        <Icon icon={["arrow-left", 2174]}></Icon>
        {t("Back")}
      </span>
    </BackButtonDesktopStyled>
  );
};

const BackButtonDesktopStyled = styled.div`
  color: #292e3a;
  font-weight: 600;
  border-bottom: 1px solid #e6e6e6;
  display: none;

  padding: 16px 8px;

  & > span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    .r365-icon {
      transition: transform 0.2s ease-in-out;
      margin-right: 8px;
    }

    &:hover {
      .r365-icon {
        transform: translateX(-4px);
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default BackButtonDesktop;
