import { AdvFormAPI } from "@adv-libs/adv-form";
import events from "../../features/Events/events";
import toastError from "../../features/ToastMessage/toastError";

const setErrorToForm = (
  formAPI: AdvFormAPI,
  error,
  toastifyError: boolean = true
) => {
  if (error.__isError || error.__isJSONError) {
    if (!formAPI.isDisposed()) {
      formAPI.setMultipleFieldMessages(error.fields);

      if (error.description || error.title) {
        formAPI.setFormMessages([
          { description: error.description || error.title },
        ]);
      }
    }
  } else {
  }

  if (toastifyError) {
    toastError(error);
  }

  // Wait for re-render
  setTimeout(() => {
    events.emit("error");
  }, 0);
};

export default setErrorToForm;
