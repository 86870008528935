import { useDebouncedCallback } from "@adv-libs/utils";
import React, { useImperativeHandle, useLayoutEffect, useRef } from "react";
import { matchPath } from "react-router-dom";
import createPersistentStorage from "../../utils/createPersistentStorage";
import { useCacheContext } from "../Cache/CacheContext";

export interface ScrollRestorationProps {
  restoreTop?: boolean;
  keepOn?: string[];
  id: string;
}

export interface ScrollRestorationInstance {
  restore: () => any;
}

const CACHE_SCOPE = "scroll";

const ScrollRestoration = React.forwardRef<
  ScrollRestorationInstance,
  ScrollRestorationProps
>((props, ref) => {
  const lastScrollRef = useRef<number>(0);

  const cacheContext = useCacheContext();

  useImperativeHandle(
    ref,
    () => {
      return {
        restore: () => {
          const $scrollContainer = document.getElementById("scroll-container");
          if ($scrollContainer) {
            $scrollContainer.scrollTo(0, lastScrollRef.current);
          }
        },
      };
    },
    []
  );

  const handleScroll = useDebouncedCallback(
    () => {
      const $scrollContainer = document.getElementById("scroll-container");
      if (!props.restoreTop && $scrollContainer) {
        cacheContext.setCache(
          CACHE_SCOPE,
          props.id,
          props.keepOn,
          $scrollContainer.scrollTop
        );
      }
    },
    100,
    [props.id, props.keepOn]
  );

  useLayoutEffect(() => {
    const $scrollContainer = document.getElementById("scroll-container");
    if (props.restoreTop && $scrollContainer) {
      $scrollContainer.scrollTo(0, 0);
    }
  }, [props.restoreTop]);

  useLayoutEffect(() => {
    const $scrollContainer = document.getElementById("scroll-container");

    const savedScroll = cacheContext.getCache(CACHE_SCOPE, props.id);
    if ($scrollContainer) {
      $scrollContainer.scrollTo(0, savedScroll);
    }

    if ($scrollContainer) {
      $scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if ($scrollContainer) {
        $scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  return null;
});

const persistentScrollStorage = createPersistentStorage("scroll", "object");

class PersistentScrollPosition {
  public saveScroll(path: string, keep: string[], scroll: number) {
    let data = persistentScrollStorage.getValue();
    if (!data) data = {};
    data[path] = { v: scroll, k: keep };
    persistentScrollStorage.setValue(data);
  }

  public getScroll(path: string) {
    let data = persistentScrollStorage.getValue();
    return data?.[path]?.v ?? 0;
  }

  public clear(path: string) {
    const data = persistentScrollStorage.getValue();
    for (const id in data) {
      const k = data[id].k;
      const keep = !!k.find((item) => {
        const result = matchPath(path, {
          path: item,
        });
        return result?.isExact;
      });
      if (!keep) {
        delete data[id];
      }
    }
    persistentScrollStorage.setValue(data);
  }
}

const persistentScrollPosition = new PersistentScrollPosition();

export { persistentScrollPosition };

export default ScrollRestoration;
