import React from "react";
import styled from "styled-components";
import breakpoints from "../../style/breakpoints";

export interface CardBodyProps {}

const CardBody: React.FC<CardBodyProps> = (props) => {
  return <CardBodyStyled>{props.children}</CardBodyStyled>;
};

const CardBodyStyled = styled.div`
  padding: 16px 8px;

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default CardBody;
