import React, { ImgHTMLAttributes } from "react";

export interface LogoProps extends ImgHTMLAttributes<any> {
  black?: boolean;
  small?: boolean;
}

const logoUrls = {
  black: "/logo-black.svg",
  white: "/logo-white.svg",
  blackSmall: "/logo-black-small.svg",
  whiteSmall: "/logo-white-small.svg",
};

const Logo: React.FC<LogoProps> = (props) => {
  const { black, small, ...restProps } = props;

  return black ? (
    <img
      className="logo"
      src={small ? logoUrls.blackSmall : logoUrls.black}
      alt="Rivile MGAMA"
      {...restProps}
    />
  ) : (
    <img
      className="logo"
      src={small ? logoUrls.whiteSmall : logoUrls.white}
      alt="Rivile MGAMA"
      {...restProps}
    />
  );
};

export default Logo;
