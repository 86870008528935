import React, { useLayoutEffect, useMemo, useState } from "react";
import { JobPositionListAPIResponseItem } from "../../api/businessTripAPI";
import { ROUTE_BUSINESS_TRIPS, ROUTE_BUSINESS_TRIP } from "../../app/routes";
import Accordion from "../../components/Accordion/Accordion";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import { useCacheContext } from "../../features/Cache/CacheContext";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import MyBusinessTripsHistory from "./MyBusinessTripsHistory";
import MyBusinessTripsSelector from "./MyBusinessTripsSelector";

export interface MyBusinessTripsProps {}

const CACHE_SCOPE = "businessTrips";

const MyBusinessTrips: React.FC<MyBusinessTripsProps> = (props) => {
  const cacheContext = useCacheContext();
  const { t } = useI18n();

  const [jobPosition, setJobPosition] =
    useState<JobPositionListAPIResponseItem>(
      cacheContext.getCache(CACHE_SCOPE, "job-position")
    );

  const keepOn = useMemo(() => {
    return [ROUTE_BUSINESS_TRIPS.path, ROUTE_BUSINESS_TRIP.path];
  }, []);

  useLayoutEffect(() => {
    cacheContext.setCache(CACHE_SCOPE, "job-position", keepOn, jobPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPosition]);

  return (
    <>
      <PageTitle>{t("Business trips")}</PageTitle>
      <ScrollRestoration id="businessTrips" keepOn={keepOn} />
      <Grid>
        <GridCol col-s="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
          <PageContent>
            <Accordion
              title={t("Business Trips")}
              iconSrc="/icons/icon-nav-business-trip.svg"
              showBackButton
              expandedByDefault
              disabled
              minHeight={200}
            >
              <MyBusinessTripsSelector
                jobPosition={jobPosition}
                onJobPositionChanged={setJobPosition}
              />
            </Accordion>
            <MyBusinessTripsHistory jobPosition={jobPosition} />
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

export default MyBusinessTrips;
