import { toast } from "@adv-libs/r365-ui";
import { useCallback, useEffect } from "react";

let lastState = null;

const useCloseToastOnRouteChange = (router) => {
  const handleHistoryChange = useCallback((state, action) => {
    if (lastState && action === "POP") {
      if (lastState.state?.createdUrl) {
        lastState = state;
        return;
      }
    }

    if (state?.state?.redirect) {
      lastState = state;
      return;
    }

    toast.dismiss();
    lastState = state;
  }, []);

  useEffect(() => {
    const history = router?.history;
    if (history) {
      history.listen(handleHistoryChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.history, handleHistoryChange]);
};

export default useCloseToastOnRouteChange;
