import React from "react";
import styled from "styled-components";
import { CalendarGroupDetailedType } from "./types";

export interface CalendarGroupViewProps {
  group: CalendarGroupDetailedType;
}

const CalendarGroupView: React.FC<
  React.PropsWithChildren<CalendarGroupViewProps>
> = (props) => {
  return (
    <CalendarGroupViewStyled>
      <Title>{props.group.title}</Title>
      {props.group.apr1 ? <Apr1>{props.group.apr1}</Apr1> : null}
      {props.group.apr2 ? <Apr2>{props.group.apr2}</Apr2> : null}
      {props.group.apr3 ? <Apr3>{props.group.apr3}</Apr3> : null}
    </CalendarGroupViewStyled>
  );
};

const Title = styled.div`
  font-weight: 600;
`;

const Apr1 = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const Apr2 = styled.div`
  font-size: 12px;
  color: #737373;
`;

const Apr3 = styled.div`
  font-size: 12px;
  color: #737373;
`;

const CalendarGroupViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 200px;
  padding: 16px;
`;

export default CalendarGroupView;
