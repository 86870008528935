export const ROUTE_LOGIN = { path: "/login" };
export const ROUTE_LOGOUT = { path: "/logout" };
export const ROUTE_COMPANIES = { path: "/companies" };
export const ROUTE_HISTORY = { path: "/history" };
export const ROUTE_MAIN = {
  path: "/",
};
export const ROUTE_TASKS = {
  path: "/tasks",
};
export const ROUTE_APPROVE_TASK = {
  path: "/tasks/approve/:id",
  createPath: (params: { id: any }) => "/tasks/approve/" + params.id,
};
export const ROUTE_TASK = {
  path: "/tasks/:id",
  createPath: (params: { id: any }) => "/tasks/" + params.id,
};
export const ROUTE_VACATIONS = {
  path: "/vacations",
};
export const ROUTE_VACATION = {
  path: "/vacations/:id",
  createPath: (params: { id: any }) => "/vacations/" + params.id,
};
export const ROUTE_CALENDAR = {
  path: "/calendar",
};
export const ROUTE_VACATION_REQUEST = {
  path: "/vacations/request/new",
};
export const ROUTE_USER_SETTINGS = {
  path: "/settings/user",
};
export const ROUTE_BUSINESS_TRIPS = {
  path: "/businessTrips",
};
export const ROUTE_BUSINESS_TRIP = {
  path: "/businessTrips/:id",
  createPath: (params: { id: any }) => "/businessTrips/" + params.id,
};
export const ROUTE_BUSINESS_TRIP_REQUEST = {
  path: "/businessTrips/request/new",
};

export const ROUTE_DOCUMENTS = {
  path: "/documents",
};

export const ROUTE_DOCUMENT = {
  path: "/documents/:id/:formId",
  createPath: (params: { id: any; formId: any }) =>
    "/documents/" + params.id + "/" + params.formId,
};

export const ROUTE_DOCUMENTS_REQUEST = {
  path: "/documents/request/new/:id",
  createPath: (params: { id: any }) => "/documents/request/new/" + params.id,
};

export const ROUTE_SETTINGS = {
  path: "/settings",
};

export const ROUTE_FORMS = {
  path: "/settings/forms",
};

export const ROUTE_FORM = {
  path: "/settings/forms/editing/:id",
  createPath: (params: { id: any }) => "/settings/forms/editing/" + params.id,
};

export const ROUTE_FORMS_NEW = {
  path: "/settings/forms/new",
};
