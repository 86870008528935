import { useCallback } from "react";
import { proxy, subscribe, useSnapshot } from "valtio";
import { getHistory } from "../Router/history";
import createModal from "./createModal";
import Modal from "./Modal";

export type OpenDynamicModalOptions<P = any> = {
  title?: string;
  onClose?: (resolveValue?: boolean) => any;
  onPopState?: () => any;
  Component: any;
  props?: P;
  maxWidth?: any;
  maxHeight?: any;
  height?: any;
  closeIcon?: any;
  closeIconSize?: any;
  modalProps?: any;
};

let dynamicModalCounter = 0;

export const openDynamicModal = async <P,>(
  options: OpenDynamicModalOptions<P>
) => {
  return new Promise((resolve) => {
    let remove;
    let manuallyClosed = false;
    let manuallyClosedResolveValue = null;
    let lastActiveElement = document.activeElement as HTMLElement;

    const handleClose = (resolveValue?: boolean) => {
      if (remove) remove();
      lastActiveElement?.focus();
      resolve(null);
      if (manuallyClosed) {
        resolveValue = manuallyClosedResolveValue;
      }
      if (options.onClose) {
        options.onClose(resolveValue);
      }
    };

    const handleManualClose = (resolveValue?: boolean) => {
      manuallyClosed = true;
      manuallyClosedResolveValue = resolveValue;
      getHistory().goBack();
    };

    let modalProps = options.modalProps;
    if (modalProps) {
      try {
        subscribe(options.modalProps, () => {});
      } catch (err) {
        modalProps = proxy(options.modalProps);
      }
    }

    remove = createModal(
      `r365-dynamic-modal-${++dynamicModalCounter}`,
      <DynamicModal
        title={options.title}
        onClose={handleClose}
        onManualClose={handleManualClose}
        onPopState={options.onPopState}
        Component={options.Component}
        props={options.props}
        maxWidth={options.maxWidth}
        maxHeight={options.maxHeight}
        height={options.height}
        closeIcon={options.closeIcon}
        closeIconSize={options.closeIconSize}
        modalProps={modalProps}
      ></DynamicModal>
    );
  });
};

interface DynamicModalProps extends OpenDynamicModalOptions<any> {
  onManualClose?: (resolveValue?: boolean) => any;
  modalProps?: Record<any, any>;
  closeIcon?: any;
  closeIconSize?: any;
}

const emptyProxy = proxy({});

const DynamicModal: React.FC<DynamicModalProps> = (props) => {
  const handlePopState = useCallback(() => {
    if (props.onPopState) {
      return props.onPopState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPopState]);

  const Component = props.Component;

  const modalPropsSnapshot = useSnapshot(props.modalProps ?? emptyProxy);

  return (
    <Modal
      title={props.title}
      defaultIsOpen
      onClosed={props.onClose}
      onPopState={handlePopState}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      height={props.height}
      closeIcon={props.closeIcon}
      closeIconSize={props.closeIconSize}
      {...modalPropsSnapshot}
    >
      <Component onClose={props.onManualClose} {...props.props} />
    </Modal>
  );
};
