import ClientError from "../../features/Error/ClientError";
import FormValidationError from "./FormValidationError";

const validateForm = async (values, validationSchema, validationContext?) => {
  try {
    if (validationContext) {
      await validationSchema.validate(values, {
        context: validationContext,
        abortEarly: false,
      });
    } else await validationSchema.validate(values, { abortEarly: false });
  } catch (err) {
    if (err.name === "ValidationError") {
      throw new FormValidationError({ validationError: err });
    } else {
      throw new ClientError({ message: err.toString() });
    }
  }
};

export default validateForm;
