import { AdvFormAPI } from "@adv-libs/adv-form";
import { AdvFormGroup, Button } from "@adv-libs/r365-ui";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import API from "../../../api/API";
import { ROUTE_FORMS } from "../../../app/routes";
import Card, { CardStyled } from "../../../components/Card/Card";
import {
  FormSelectField,
  FormTextareaField,
  FormTextField,
  FormActiveButtonField,
} from "../../../components/form";
import Form from "../../../components/form/Form";
import SubmitButton from "../../../components/form/SubmitButton";
import { useCompanyContext } from "../../../features/Companies/CompanyContext";
import goSafeBack, {
  createGoSafeBack,
} from "../../../features/Router/goSafeBack";
import toastSuccess from "../../../features/ToastMessage/toastSuccess";
import useI18n from "../../../hooks/useI18n";
import { setUrlWithCompanyId } from "../../../utils/path";
import { FormGetAPIResponse } from "../../../api/formsAPI";
import NoDataCard from "../../../components/NoDataCard";
import PageTitle from "../../../components/PageTitle";
import StateLoader from "../../../components/StateLoader";
import PopStateItem from "../../../features/PopState/PopStateItem";
import ScrollRestoration from "../../../features/Router/ScrollRestoration";
import CardBody from "../../../components/Card/CardBody";
import BackButtonDesktop from "../../../components/BackButtonDesktop";
import { Grid, GridCol } from "../../../style/Grid";
import { Icon } from "@adv-libs/icons";
import DropdownWithBack from "../../../components/DropdownWithBack/DropdownWithBack";
import openConfirm from "../../../features/Modal/openConfirm";
import toastError from "../../../features/ToastMessage/toastError";
import { useCacheContext } from "../../../features/Cache/CacheContext";
import { waitForModalClose } from "../../../features/PopState/useResetHistory";

export interface FormEditingProps {
  initialData?: FormGetAPIResponse;
  formId?: string;
  error?: any;
  isLoading?: boolean;
}

const FormEditing: React.FC<FormEditingProps> = (props) => {
  const { formId, initialData, error, isLoading } = props;
  const companyContext = useCompanyContext();
  const { t, pt } = useI18n();
  const [formAPI, setFormAPI] = useState<AdvFormAPI>();
  const isSystemic = initialData ? initialData.isSystemic : false;
  const { clearCache } = useCacheContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleSubmit = useCallback(
    async (values, formAPI: AdvFormAPI) => {
      await API.forms.editForm({
        clientDbId: companyContext.company.clientDbId,
        id: formId,
        ...values,
      });

      formAPI.setTouched(false);
      goSafeBack(ROUTE_FORMS.path);
      setTimeout(() => {
        toastSuccess(
          formId ? t("Document form is updated") : t("Document form is created")
        );
      }, 1);
    },
    [companyContext.company.clientDbId, t, formId]
  );

  const handleDeleteClick = useCallback(async () => {
    if (isSystemic) return;

    setMenuOpen(false);
    await waitForModalClose();
    const confirmed = await openConfirm({
      message: t("Do you really want to delete the form?"),
      intent: "danger",
    });

    if (!confirmed) return;

    try {
      await API.forms.deleteForm({
        clientDbId: companyContext.company.clientDbId,
        formId: formId,
      });

      goSafeBack(ROUTE_FORMS.path);
      clearCache();
      setTimeout(() => {
        toastSuccess(t("Document form is deleted"));
      }, 1);
    } catch (err) {
      toastError(err);
    }
  }, [companyContext.company.clientDbId, formId, clearCache, t, isSystemic]);

  const validation = useMemo(() => {
    return yup.object({
      title: yup.string().required(t("Field is required")),
      formXML: yup.string().required(t("Field is required")),
      dvsType: yup.string().required(t("Field is required")),
    });
  }, [t]);

  const handlePopStateItem = useCallback(() => {
    if (formAPI && formAPI.isTouched()) {
      return t("Are you sure to exit?");
    }
  }, [formAPI, t]);

  const fetchList = useCallback(
    async (query) => {
      return await API.forms.getDvsTypeList({
        clientDbId: companyContext.company.clientDbId,
        size: 10,
        filter: query,
      });
    },
    [companyContext.company.clientDbId]
  );

  return (
    <>
      <PageTitle>
        {formId ? t("Document form") : t("New document form")}
      </PageTitle>
      <ScrollRestoration id="form-editing" restoreTop />
      <PopStateItem name="form-editing" onPopState={handlePopStateItem}>
        <NewFormStyled>
          <Grid>
            <GridCol col-l="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
              <Card>
                <BackButtonDesktop />
                <CardBody>
                  <StateLoader error={error} isLoading={isLoading}>
                    {() => {
                      if (initialData || !formId) {
                        return (
                          <>
                            <TopStyled>
                              <Header>
                                {formId
                                  ? t("Document form")
                                  : t("New document form")}
                              </Header>

                              {formId && (
                                <DropdownWithBack
                                  isOpen={menuOpen}
                                  onClose={() => setMenuOpen(false)}
                                  onOpen={() => setMenuOpen(true)}
                                >
                                  <MoreButton onClick={() => {}}>
                                    <Icon
                                      icon={["vertical-menu-option", 1816]}
                                    />
                                  </MoreButton>
                                  <MoreMenu>
                                    <MenuItem
                                      className="menu-item-red"
                                      onClick={handleDeleteClick}
                                      disabled={isSystemic}
                                    >
                                      {t("Delete")}
                                    </MenuItem>
                                  </MoreMenu>
                                </DropdownWithBack>
                              )}
                            </TopStyled>
                            <Form
                              onSubmit={handleSubmit}
                              onFormReady={setFormAPI}
                              validation={validation}
                              showGenericError
                              toastifyError={false}
                              initialValues={initialData}
                            >
                              <AdvFormGroup noMargin>
                                <FormTextField
                                  label={t("Title")}
                                  type="text"
                                  name="title"
                                  disabled={isSystemic}
                                  size={{
                                    md: 12,
                                  }}
                                />
                              </AdvFormGroup>

                              <AdvFormGroup noMargin>
                                <FormTextField
                                  label={t("Stored procedure name (INIT)")}
                                  type="text"
                                  name="sqlInit"
                                  disabled={isSystemic}
                                  size={{
                                    md: 12,
                                  }}
                                />
                              </AdvFormGroup>

                              <AdvFormGroup noMargin>
                                <FormTextField
                                  label={t("Stored procedure name (SAVE)")}
                                  type="text"
                                  name="sqlSave"
                                  disabled={isSystemic}
                                  size={{
                                    md: 12,
                                  }}
                                />
                              </AdvFormGroup>

                              <AdvFormGroup noMargin>
                                <FormSelectField
                                  label={t("DVS type")}
                                  name="dvsType"
                                  getItems={({ query }) => fetchList(query)}
                                  paginated={{ size: 10 }}
                                  filter="server"
                                  isClearable
                                  disabled={isSystemic}
                                  submitFieldName="dvsType:id"
                                  size={{
                                    md: 12,
                                  }}
                                />
                              </AdvFormGroup>

                              <AdvFormGroup noMargin>
                                <FormTextareaField
                                  name="formXML"
                                  label={t("Form XML")}
                                  rows={10}
                                  disabled={isSystemic}
                                  size={{ md: 12 }}
                                />
                              </AdvFormGroup>

                              <AdvFormGroup>
                                <div className="active-button">
                                  <FormActiveButtonField
                                    name="active"
                                    dateRange={false}
                                    size={{ md: 12 }}
                                  />
                                </div>
                              </AdvFormGroup>

                              <FormActions>
                                <div className="close-button">
                                  <Button
                                    fill
                                    onClick={createGoSafeBack(
                                      setUrlWithCompanyId(ROUTE_FORMS.path)
                                    )}
                                  >
                                    {t("Close")}
                                  </Button>
                                </div>
                                <div className="submit-button">
                                  <SubmitButton>
                                    {formId ? t("Save") : t("Create")}
                                  </SubmitButton>
                                </div>
                              </FormActions>
                            </Form>
                          </>
                        );
                      } else {
                        return (
                          <NoDataCard>
                            {pt("forms", "No information available")}
                          </NoDataCard>
                        );
                      }
                    }}
                  </StateLoader>
                </CardBody>
              </Card>
            </GridCol>
          </Grid>
        </NewFormStyled>
      </PopStateItem>
    </>
  );
};

const MoreMenu = styled.div`
  box-shadow: 0 0 30px 0px rgba(109, 109, 109, 0.3);
  border-radius: 6px;
  width: 130px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuItem = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 16px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: rgba(236, 43, 43, 1);

  ${({ disabled }) =>
    disabled ? "cursor: not-allowed; color: rgba(236, 43, 43, 0.4)" : null};
`;

const TopStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MoreButton = styled.div`
  padding: 10px 10px 10px 0;
  cursor: pointer;
`;

const NewFormStyled = styled.div`
  margin: 0 auto;

  .active-button {
    .r365-control {
      border: none;
      box-shadow: none;
    }
  }

  form {
    ${CardStyled} {
      margin: 6px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 6px;
`;

const FormActions = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  .close-button {
    order: 2;
  }

  .submit-button {
    order: 1;
  }

  .r365-button {
    .r365-button__content {
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;

    .close-button {
      order: 1;
    }

    .submit-button {
      order: 2;
    }

    .r365-button {
      min-width: 200px;
    }
  }
`;

export default FormEditing;
