import { AdvStaticStatus, Button } from "@adv-libs/r365-ui";
import React, { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { ROUTE_TASKS, ROUTE_DOCUMENTS } from "../../app/routes";
import BackButtonDesktop from "../../components/BackButtonDesktop";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import DataView from "../../components/DataView/DataView";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import StateLoader from "../../components/StateLoader";
import { useCacheContext } from "../../features/Cache/CacheContext";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import openConfirm from "../../features/Modal/openConfirm";
import goSafeBack, { createGoSafeBack } from "../../features/Router/goSafeBack";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import toastError from "../../features/ToastMessage/toastError";
import toastSuccess from "../../features/ToastMessage/toastSuccess";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import { setUrlWithCompanyId } from "../../utils/path";
import DataViewPageSharedStyles from "../shared/DataViewPageSharedStyles";
import MyApproveHistory from "../shared/MyApproveHistory/MyApproveHistory";

export interface MyDocumentProps {}

const MyDocument: React.FC<MyDocumentProps> = (props) => {
  const { t } = useI18n();
  const [cancelling, setCancelling] = useState(false);
  const { clearCache } = useCacheContext();

  const {
    params: { id: documentId, formId },
  } = useRouteMatch<any>();

  const companyContext = useCompanyContext();

  const apiPayload = useMemo(() => {
    return {
      operationId: documentId,
      clientDbId: companyContext.company.clientDbId,
      formId: formId,
    };
  }, [companyContext.company.clientDbId, documentId, formId]);

  const [state] = useAPI(
    API.documents.get,
    {
      autoStart: apiPayload,
    },
    [apiPayload]
  );

  const handleCancelClick = useCallback(async () => {
    const confirmed = await openConfirm({
      message: t("Do you really want to cancel this document?"),
      intent: "danger",
    });

    if (!confirmed) return;

    try {
      setCancelling(true);
      await API.documents.documentCancelRequest(apiPayload);

      goSafeBack(ROUTE_DOCUMENTS.path);
      clearCache();
      setTimeout(() => {
        toastSuccess(t("Document canceled"));
      }, 1);
    } catch (err) {
      toastError(err);
    } finally {
      setCancelling(false);
    }
  }, [apiPayload, clearCache, t]);

  return (
    <>
      <ScrollRestoration id="document" restoreTop />
      <Grid>
        <GridCol col-s="2 / -2" col-m="3 / -3" col-xl="4 / -4">
          <PageContent>
            <DataViewPageSharedStyles.ViewStyled>
              <Card>
                <BackButtonDesktop />
                <CardBody>
                  <StateLoader error={state.error} isLoading={state.isLoading}>
                    {() => {
                      return (
                        <>
                          <PageTitle>{`${state.data.title}`}</PageTitle>
                          <DataViewPageSharedStyles.ViewInnerStyled>
                            <div className="top">
                              <div className="left">
                                {state.data.statusPav?.trim?.() ? (
                                  <div className="dynamic-status">
                                    <AdvStaticStatus
                                      name={state.data.statusPav}
                                      background={state.data.statusColor}
                                      color="#313131"
                                      template={false}
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div className="right">
                                {state.data.add_data?.trim?.() ? (
                                  <div className="create-date">
                                    <div className="title">{t("Created")}</div>
                                    <div className="date">
                                      {state.data.add_data}
                                    </div>
                                  </div>
                                ) : null}
                                {state.data.kor_data?.trim?.() ? (
                                  <div className="update-date">
                                    <div className="title">{t("Updated")}</div>
                                    <div className="date">
                                      {state.data.kor_data}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <DataViewPageSharedStyles.Header>
                              {state.data.title}
                            </DataViewPageSharedStyles.Header>
                            <DataView
                              schema={state.data.schema}
                              clientDbId={companyContext.company.clientDbId}
                            />
                            <DataViewPageSharedStyles.FormActions>
                              <Grid>
                                <GridCol
                                  col-s="2 / -2"
                                  col-m="3 / -3"
                                  col-xl="4 / -4"
                                >
                                  {state.data.buttons === "2" ? (
                                    <div className="buttons-container">
                                      <Button
                                        danger
                                        dark
                                        fill
                                        disabled={cancelling}
                                        icon={
                                          cancelling
                                            ? ["spin", 2200]
                                            : undefined
                                        }
                                        spin={cancelling}
                                        onClick={handleCancelClick}
                                      >
                                        {t("Cancel document")}
                                      </Button>
                                    </div>
                                  ) : null}

                                  <div className="back-button">
                                    <Button
                                      fill
                                      onClick={createGoSafeBack(
                                        setUrlWithCompanyId(ROUTE_TASKS.path)
                                      )}
                                    >
                                      {t("Close")}
                                    </Button>
                                  </div>
                                </GridCol>
                              </Grid>
                            </DataViewPageSharedStyles.FormActions>
                          </DataViewPageSharedStyles.ViewInnerStyled>
                        </>
                      );
                    }}
                  </StateLoader>
                </CardBody>
              </Card>
            </DataViewPageSharedStyles.ViewStyled>
            {state.data ? (
              <MyApproveHistory
                id={documentId}
                clientDbId={companyContext.company.clientDbId}
                user={companyContext.user.kodas_rs}
                formId={formId}
                request={API.documents.getApproveHistory}
              />
            ) : null}
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

export default MyDocument;
