const colorSchema: Record<string, { backgroundColor: string, borderColor: string, color: string }> = {
  0: {
    backgroundColor: "#FFECA9",
    borderColor: "#BC8D33",
    color: "#6D4A15",
  },
  1: {
    backgroundColor: "#CCF0E3",
    borderColor: "#2A916B",
    color: "#154836",
  },
  2: {
    backgroundColor: "#D7DEE4",
    borderColor: "#556A7D",
    color: "#405261",
  },
  3: {
    backgroundColor: "#FFF0F4",
    borderColor: "#FF5381",
    color: "#C80000",
  },
  4: {
    backgroundColor: "#FFE3E3",
    borderColor: "#7D0000",
    color: "#7D0000",
  },
  5: {
    backgroundColor: "#CDDEFD",
    borderColor: "#0846BA",
    color: "#052C74",
  },
  6: {
    backgroundColor: "#FFE9FA",
    borderColor: "#934F83",
    color: "#783D6A",
  },
  7: {
    backgroundColor: "#FFC6F9",
    borderColor: "#8B448C",
    color: "#611B6C",
  },
  8: {
    backgroundColor: "#EFF3D5",
    borderColor: "#ADB95F",
    color: "#3A550E",
  },
  9: {
    backgroundColor: "#DFDAFF",
    borderColor: "#746AAE",
    color: "#363080",
  },
};

export default colorSchema;
