import { invariant } from "@adv-libs/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Modal {
  isOpen: boolean;
}

export interface ModalReducerState {
  modals: Record<string, Modal>;
}

const initialState: ModalReducerState = {
  modals: {},
};

const modalSlice = createSlice({
  name: "r365/modal",
  initialState,
  reducers: {
    registerModal(
      state,
      action: PayloadAction<{ modalName: string; defaulIsOpen?: boolean }>
    ) {
      const { modalName, defaulIsOpen } = action.payload;
      const modal = state.modals[modalName];
      invariant(!modal, `Modal '${modalName}' is already registered`);
      state.modals[modalName] = {
        isOpen: defaulIsOpen ?? false,
      };
    },
    removeModal(state, action: PayloadAction<{ modalName: string }>) {
      const { modalName } = action.payload;
      const modal = state.modals[modalName];
      invariant(modal, `Modal '${modalName}' is not registered`);
      state.modals[modalName] = undefined;
    },
    openModal(state, action: PayloadAction<{ modalName: string }>) {
      const { modalName } = action.payload;
      const modal = state.modals[modalName];
      invariant(modal, `Modal '${modalName}' is not registered`);
      state.modals[modalName].isOpen = true;
    },
    closeModal(state, action: PayloadAction<{ modalName: string }>) {
      const { modalName } = action.payload;
      const modal = state.modals[modalName];
      invariant(modal, `Modal '${modalName}' is not registered`);
      state.modals[modalName].isOpen = false;
    },
  },
});

// Selectors

export const createModalIsOpenSelector =
  (modalName: string) => (state: { modal: ModalReducerState }) => {
    const modal = state.modal.modals[modalName];
    invariant(modal, `Modal '${modalName}' is not registered`);
    return modal.isOpen;
  };

export const createModalIsRegisteredSelector =
  (modalName: string) => (state: { modal: ModalReducerState }) => {
    const modal = state.modal.modals[modalName];
    return !!modal;
  };

const modalReducer = {
  modal: modalSlice.reducer,
};

export { modalReducer };

export const { registerModal, removeModal, openModal, closeModal } =
  modalSlice.actions;
