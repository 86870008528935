import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { brandGreenDark, brandGreenLight } from "../style/theme";

interface ILogoGenerator {
  name: string;
  imgUrl?: string;
  separator?: string;
}

function LogoGenerator(props: ILogoGenerator) {
  const { name, imgUrl, separator = "_" } = props;
  const [acronym, acronymSet] = useState("");

  const generateAcronym = useCallback(() => {
    const ignoreWords = ["UAB", "AB"];
    const acronym = name
      ?.split(separator)
      // filter arrays from ignore words list
      .filter((subobj) => !ignoreWords.includes(subobj))
      // only output first 2 arrays
      .slice(0, 2)
      // output 2 letters from each array word
      .reduce((res, word) => (res += word.slice(0, 1)), "")
      // make all letter capital
      .toLocaleUpperCase();

    acronymSet(acronym);
  }, [name, separator]);

  useEffect(() => {
    generateAcronym();
  }, [generateAcronym]);

  return (
    <LogoGeneratorStyled>
      <div className="logo-container">
        {imgUrl ? (
          <div className="logo-img">
            <img src={imgUrl} alt="" />
          </div>
        ) : (
          <div className="logo-text">{acronym}</div>
        )}
      </div>
    </LogoGeneratorStyled>
  );
}

export default LogoGenerator;

const size = "30px";

export const LogoGeneratorStyled = styled.div`
  border-radius: 50%;
  height: ${size};
  width: ${size};
  user-select: none;

  .logo-container,
  .logo-img,
  img {
    border-radius: inherit;
    height: inherit;
    width: inherit;
  }

  .logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: ${brandGreenLight};

    .logo-img {
      img {
        object-fit: cover;
      }
    }

    .logo-text {
      color: ${brandGreenDark};
      font-size: 14px;
    }
  }
`;
