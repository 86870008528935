let _popStateSkipped = false;

export const skipPopState = () => {
  _popStateSkipped = true;
};

export const restorePopState = () => {
  _popStateSkipped = false;
};

export const popStateIsSkipped = () => {
  return _popStateSkipped;
};
