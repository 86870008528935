import events from "./events";

const initEvents = () => {
  events.on("error", () => {});

  events.on("clear-error", () => {});

  events.on("fieldchanged", () => {});

  events.on("toast-error", (err) => {});
};

export default initEvents;
