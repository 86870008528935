import { Icon } from "@adv-libs/icons";
import React from "react";
import styled from "styled-components";

export interface SpinnerProps {}

const icon: any = ["spin", 2200];

const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <SpinnerStyled>
      <Icon icon={icon} spin />
    </SpinnerStyled>
  );
};

export const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  align-items: center;
  min-height: 100px;
  font-size: 18px;
  color: ${(props) => props.theme.darkGreen};
`;

export default Spinner;
