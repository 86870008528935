/** Fake pt function for translations. DO NOT DELETE */
const pt = (ctx: string, str: string) => str;

const statusMap = {
  1: pt("task", "Pending"),
  2: pt("task", "Approved"),
  3: pt("task", "Declined"),
  4: pt("task", "Done"),
};

export default statusMap;
