import React, { useMemo } from "react";
import { ROUTE_DOCUMENTS } from "../../app/routes";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import MyDocumentsHistory from "./MyDocumentsHistory";
import styled from "styled-components";
import MyDocumentsFormSelector from "./MyDocumentsFormSelector";

export interface MyDocumentsProps {}

const MyDocuments: React.FC<MyDocumentsProps> = (props) => {
  const { t } = useI18n();

  const keepOn = useMemo(() => {
    return [ROUTE_DOCUMENTS.path];
  }, []);

  return (
    <>
      <PageTitle>{t("Documents")}</PageTitle>
      <ScrollRestoration id="documents" keepOn={keepOn} />
      <Grid>
        <GridCol col-s="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
          <PageContent>
            <MyDocumentsStyled>
              <MyDocumentsFormSelector />
              <MyDocumentsHistory />
            </MyDocumentsStyled>
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

const MyDocumentsStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

export default MyDocuments;
