import {
  CalendarEventStyleType,
  CalendarMobile,
  CalendarTimeline,
} from "@adv-libs/calendar";
import { useIsMobile, wait } from "@adv-libs/utils";
import { endOfMonth, startOfMonth } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import spacetime from "spacetime";
import styled from "styled-components";
import API from "../../api/API";
import PageTitle from "../../components/PageTitle";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { goBackPromise } from "../../features/PopState/useResetHistory";
import { getHistory } from "../../features/Router/history";
import {
  getXMLFilterInitialValuesFromUrl,
  putXMLFilterToUrl,
} from "../../features/XMLFilter/utils";
import useXMLFilter from "../../features/XMLFilter/XMLFilter";
import useI18n from "../../hooks/useI18n";
import { minScreenLength } from "../../style/theme";
import { getQuery } from "../../utils/path";
import colorSchema from "./ colorSchema";
import openCalendarDetailView from "./CalendarEventDetailView/openCalendarDetailView";
import CalendarGroupView from "./CalendarGroupView";
import CalendarToolbar from "./CalendarToolbar/CalendarToolbar";
import getCalendarRange from "./getCalendarRange";
import { CalendarEventDetailedType } from "./types";
import useCalendarData from "./useCalendarData";

export interface CalendarPageProps {}

const XML_FILTER_PROPERTIES = ["kodas_4a", "kodas_is", "tipas", "kodas_k12"];

const isEmptyFilter = (filter: string) => {
  return filter === "<filters></filters>";
};

const CalendarPage: React.FC<CalendarPageProps> = (props) => {
  const { t } = useI18n();
  const firstLoadRef = useRef<boolean>(true);

  const companyContext = useCompanyContext();

  const location = useLocation();

  const isMobile = useIsMobile(minScreenLength);

  const isTimeline = !isMobile;

  const initialRange = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    const range = getCalendarRange(year, month, isTimeline);

    return {
      from: range.rangeStart,
      to: range.rangeEnd,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [range, setRange] = useState(initialRange);

  const initialMonth = useMemo(() => {
    const query = getQuery(location.pathname + location.search);
    if (query && query["menuo"]) {
      return {
        currentYear: +query["menuo"].slice(0, 4),
        currentMonth: +query["menuo"].slice(4) - 1,
      };
    }
    return;
  }, [location]);

  const initialXMLFilterValues = useMemo(() => {
    return getXMLFilterInitialValuesFromUrl({
      filterProperties: XML_FILTER_PROPERTIES,
      location: location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showFiltersDialog, setShowFiltersDialog] = useState(false);

  const fetchFilters = useCallback(
    async (filterXML) => {
      const finalFilterXML =
        isEmptyFilter(filterXML) && firstLoadRef.current ? null : filterXML;
      return await API.calendar.getFilters({
        clientDbId: companyContext.company.clientDbId,
        user: companyContext.user.kodas_rs,
        from: range.from ? spacetime(range.from).format("iso-short") : null,
        to: range.to ? spacetime(range.to).format("iso-short") : null,
        filterXML: finalFilterXML,
      });
    },
    [
      companyContext.company.clientDbId,
      companyContext.user.kodas_rs,
      range.from,
      range.to,
    ]
  );

  const rangeStringTrigger =
    spacetime(range.from).format("iso-short") +
    " - " +
    spacetime(range.to).format("iso-short");

  const {
    filterValue,
    setFilter,
    appliedFilterXML,
    filtersData,
    confirmFilter,
    clearFilter,
    applyFilter,
    cancelFilter,
    xmlTrigger,
    // setShowFilters,
    isLoading: filtersIsLoading,
    // initialFilterXML,
  } = useXMLFilter({
    filterProperties: XML_FILTER_PROPERTIES,
    fetchFilters: fetchFilters,
    initialXMLFilterValues: initialXMLFilterValues,
    trigger: rangeStringTrigger,
  });

  const handleApplyFilter = useCallback(async () => {
    applyFilter();
    await wait(0);
    await goBackPromise(getHistory());
    putXMLFilterToUrl({
      filterProperties: XML_FILTER_PROPERTIES,
      filterValue: filterValue,
      location: location,
    });
  }, [applyFilter, filterValue, location]);

  const handleClearFilter = useCallback(async () => {
    clearFilter();
    await wait(0);
    await goBackPromise(getHistory());
    putXMLFilterToUrl({
      filterProperties: XML_FILTER_PROPERTIES,
      filterValue: {},
      location: location,
    });
  }, [clearFilter, location]);

  useEffect(() => {
    if (xmlTrigger > 0) {
      firstLoadRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xmlTrigger]);

  const handleDataLoad = useCallback(
    async (startDate: Date, endDate: Date) => {
      setRange({ from: startDate, to: endDate });

      const finalFilterXML =
        isEmptyFilter(appliedFilterXML) && firstLoadRef.current
          ? null
          : appliedFilterXML;

      const data = await API.calendar.getCalendarData({
        clientDbId: companyContext.company.clientDbId,
        user: companyContext.user.kodas_rs,
        from: spacetime(startDate).format("iso-short"),
        to: spacetime(endDate).format("iso-short"),
        filterXML: finalFilterXML,
      });

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      xmlTrigger,
      companyContext.company.clientDbId,
      companyContext.user.kodas_rs,
    ]
  );

  const {
    onMonthChange,
    currentYear,
    currentMonth,
    isLoading: dataIsLoading,
    data,
  } = useCalendarData({
    onDataLoad: handleDataLoad,
    timeline: isTimeline,
    initialMonth: initialMonth,
  });

  const handleSwipedLeft = useCallback(() => {
    onMonthChange(
      currentMonth === 11 ? currentYear + 1 : currentYear,
      currentMonth === 11 ? 0 : currentMonth + 1
    );
  }, [currentMonth, currentYear, onMonthChange]);

  const handleSwipedRight = useCallback(() => {
    onMonthChange(
      currentMonth === 0 ? currentYear - 1 : currentYear,
      currentMonth === 0 ? 11 : currentMonth - 1
    );
  }, [currentMonth, currentYear, onMonthChange]);

  const handleFiltersOpened = useCallback(() => {
    setShowFiltersDialog(true);
  }, []);

  const handleFiltersClosed = useCallback(() => {
    setShowFiltersDialog(false);
  }, []);

  const handleEventTitle = useCallback((event: CalendarEventDetailedType) => {
    return event.title;
  }, []);

  const handleMobileEventTitle = useCallback(
    (event: CalendarEventDetailedType) => {
      return event.title_m;
    },
    []
  );

  const handleEventClick = useCallback(
    (event: CalendarEventDetailedType) => {
      const eventTitle = handleEventTitle(event);

      openCalendarDetailView({
        event: event,
        title: eventTitle,
        user: companyContext.user.kodas_rs,
        clientDbId: companyContext.company.clientDbId,
      });
    },
    [
      companyContext.company.clientDbId,
      companyContext.user.kodas_rs,
      handleEventTitle,
    ]
  );

  const handleEventStyle = useCallback(
    (event: CalendarEventDetailedType): CalendarEventStyleType => {
      const style = colorSchema[event.color]
        ? colorSchema[event.color]
        : colorSchema[0];

      return {
        eventStyle: {
          border: `1px solid ${style.borderColor}`,
        },
        eventBackgroundStyle: {
          backgroundColor: style.backgroundColor,
        },
        eventContentStyle: {
          color: style.color,
        },
      };
    },
    []
  );

  const timelineStartDate = startOfMonth(new Date(currentYear, currentMonth));
  const timelineEndDate = endOfMonth(new Date(currentYear, currentMonth));

  const calendar = (
    <>
      <CalendarToolbar
        currentYear={currentYear}
        currentMonth={currentMonth}
        onMonthChange={onMonthChange}
        onApplyFilter={handleApplyFilter}
        onClearFilter={handleClearFilter}
        filterValue={filterValue}
        filtersData={filtersData}
        filtersIsLoading={filtersIsLoading}
        confirmFilter={confirmFilter}
        cancelFilter={cancelFilter}
        onFilterChange={setFilter}
        filtersIsOpened={showFiltersDialog}
        onFiltersClosed={handleFiltersClosed}
        onFiltersOpened={handleFiltersOpened}
      />
      {isTimeline ? (
        <CalendarTimeline
          data={data}
          startDate={timelineStartDate}
          endDate={timelineEndDate}
          isLoading={dataIsLoading}
          onEventClick={handleEventClick}
          eventTitle={handleEventTitle}
          eventStyle={handleEventStyle}
          groupView={CalendarGroupView}
        />
      ) : (
        <CalendarMobile
          data={data}
          currentMonth={currentMonth}
          currentYear={currentYear}
          isLoading={dataIsLoading}
          onSwipedLeft={handleSwipedLeft}
          onSwipedRight={handleSwipedRight}
          onEventClick={handleEventClick}
          eventTitle={handleMobileEventTitle}
          eventStyle={handleEventStyle}
        />
      )}
      {/* <Calendar
        data={data}
        currentYear={currentYear}
        currentMonth={currentMonth}
        timeline={isTimeline}
        // timeline={true}
        isLoading={dataIsLoading}
        onMonthChange={onMonthChange}
        // onDayClick={handleDayClick}
        onEventClick={handleEventClick}
        eventTitle={handleEventTitle}
        eventStyle={handleEventStyle}
        groupView={CalendarGroupView}
      /> */}
    </>
  );

  return (
    <>
      <PageTitle>{t("Calendar")}</PageTitle>
      {isMobile ? (
        <MobileCalendarContainer>
          <MinHeightContainer>{calendar}</MinHeightContainer>
        </MobileCalendarContainer>
      ) : (
        <TimelineCalendarContainer>{calendar}</TimelineCalendarContainer>
      )}
    </>
  );
};

const MinHeightContainer = styled.div`
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MobileCalendarContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: calc(60px + 64px);
  bottom: 0;
`;

const TimelineCalendarContainer = styled.div`
  background: white;
  position: fixed;
  left: 250px;
  right: 0;
  top: 60px;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export default CalendarPage;
