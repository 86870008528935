import React, { useMemo } from "react";
import styled from "styled-components";
import PageTitle from "../../components/PageTitle";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import breakpoints from "../../style/breakpoints";
import { Grid, GridCol, GridContainerStyled } from "../../style/Grid";
import TasksDashboardWidget from "../MyTasks/TasksDashboardWidget";
import VacationsDashboardWidget from "../Vacations/VacationsDashboardWidget";
import { useModulesContext } from "../../features/ModulesList/ModulesContext";
import Modules from "../../features/ModulesList/Modules";

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const modulesContext = useModulesContext();
  const vacationModule = useMemo(() => {
    if (modulesContext) {
      return (
        modulesContext.find((item) => item.module === Modules.VACATIONS) || {
          show: false,
        }
      );
    }
    return { show: false };
  }, [modulesContext]);

  const tasksCache = useMemo(() => {
    return {
      id: "dashboard-tasks-widget",
      keepOn: [],
    };
  }, []);

  const vacationsCache = useMemo(() => {
    return {
      id: "dashboard-vacations-widget",
      keepOn: [],
    };
  }, []);

  return (
    <>
      <PageTitle></PageTitle>
      <DashboardStyled>
        <ScrollRestoration id="dashboard" restoreTop />
        <Grid>
          <GridCol
            order={1}
            order-s={2}
            col="span 12"
            col-s="span 6"
            col-l="span 5"
            col-xl="span 6"
          >
            <TasksDashboardWidget footer size={4} cache={tasksCache} />
          </GridCol>
          {vacationModule.show && (
            <GridCol
              order={2}
              order-s={1}
              col="span 12"
              col-s="span 6"
              col-l="span 7"
              col-xl="span 6"
            >
              <VacationsDashboardWidget cache={vacationsCache} />
            </GridCol>
          )}
        </Grid>
      </DashboardStyled>
    </>
  );
};

const DashboardStyled = styled.div`
  @media screen and (max-width: ${breakpoints.s - 1}px) {
    ${GridContainerStyled} {
      grid-gap: 24px;
    }
  }
`;

export default Dashboard;
