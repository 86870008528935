import { ToastContainer } from "@adv-libs/r365-ui";
import * as History from "history";
import { useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { CacheContextProvider } from "../features/Cache/CacheContext";
import ModalContainer from "../features/Modal/ModalContainer";
import PopStateInjection from "../features/PopState/PopStateInjection";
import useModHistory from "../features/PopState/useModHistory";
import useResetHistory from "../features/PopState/useResetHistory";
import { setHistory } from "../features/Router/history";
import useCloseToastOnRouteChange from "../features/Router/useCloseToastOnRouteChange";
import AppRoutes from "./AppRoutes";
import ReduxProvider from "./redux/ReduxProvider";

function App() {
  const [router, setRouter] = useState<
    BrowserRouter & { history: History.History<History.LocationState> }
  >();

  useLayoutEffect(() => {
    setHistory(router?.history);
  });

  useResetHistory(router);
  useModHistory(router);
  useCloseToastOnRouteChange(router);

  return (
    <>
      <ReduxProvider>
        <BrowserRouter ref={setRouter as any}>
          <CacheContextProvider>
            <AppRoutes />
            <ToastContainer position="bottom-right" />
            <ModalContainer />
            <PopStateInjection />
          </CacheContextProvider>
        </BrowserRouter>
      </ReduxProvider>
      <Tooltip id="generic" />
    </>
  );
}

export default App;
