import styled from "styled-components";

export const AlertMessage = styled.p`
  margin: 0;
  margin-bottom: 25px;
`;

export const AlertButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .r365-button {
    margin-left: 10px;
  }
`;
