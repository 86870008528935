import { Icon } from "@adv-libs/icons";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import React, { useImperativeHandle } from "react";
import styled from "styled-components";

nProgress.configure({
  showSpinner: false,
  trickleSpeed: 100,
  parent: "#nprogress-container",
});

export interface SpinnerWithProgressProps {}

export interface SpinnerWithProgressInstance {
  start: () => any;
  done: () => any;
}

const icon: any = ["spin", 2200];

const SpinnerWithProgress = React.forwardRef<
  SpinnerWithProgressInstance,
  React.PropsWithChildren<SpinnerWithProgressProps>
>((props, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        start: () => nProgress.start(),
        done: () => nProgress.done(),
      };
    },
    []
  );

  return (
    <SpinnerStyled>
      <div className="spinner-container">
        <Icon icon={icon} spin />
        <div id="nprogress-container"></div>
      </div>
    </SpinnerStyled>
  );
});

export const SpinnerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  min-height: 100px;
  font-size: 18px;
  color: ${(props) => props.theme.darkGreen};

  .spinner-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #nprogress-container {
    margin-top: 30px;
    height: 3px;
    background: #e6e6e6;
    width: 180px;

    #nprogress .bar {
      background: #0b3f30;

      .peg {
        box-shadow: 0 0 10px #0b3f30, 0 0 5px #0b3f30;
      }
    }
  }
`;

export default SpinnerWithProgress;
