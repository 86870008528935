import { CalendarDataType } from "@adv-libs/calendar";
import { useSecondEffect } from "@adv-libs/utils";
import { useCallback, useEffect, useReducer } from "react";
import {
  calendarStateActions,
  calendarStateReducer,
  initialCalendarState,
} from "./calendarState";
import getCalendarRange from "./getCalendarRange";

export interface UseCalendarDataOptions {
  onDataLoad: (startDate: Date, endDate: Date) => Promise<CalendarDataType>;
  timeline?: boolean;
  initialMonth?: { currentYear: number; currentMonth: number };
}

const useCalendarData = (options: UseCalendarDataOptions) => {
  const { onDataLoad, timeline, initialMonth } = options;

  const [state, dispatch] = useReducer(calendarStateReducer, {
    ...initialCalendarState,
    ...initialMonth,
  });

  const currentYear = state.currentYear;
  const currentMonth = state.currentMonth;

  const getCacheKey = useCallback(
    (year: number, month: number) => {
      if (timeline) {
        return `${year}-${month}-t`;
      } else {
        return `${year}-${month}`;
      }
    },
    [timeline]
  );

  const currentCacheKey = getCacheKey(currentYear, currentMonth);

  const fetchEvents = useCallback(
    async (
      startDate: Date,
      endDate: Date,
      cacheKeys: string[],
      showLoading?: boolean
    ) => {
      dispatch(calendarStateActions.setLoadingMonths(cacheKeys, !!showLoading));
      try {
        const data = await onDataLoad(startDate, endDate);
        dispatch(calendarStateActions.setEvents(cacheKeys, data));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(calendarStateActions.setLoadingMonths(cacheKeys, false));
      }
    },
    [onDataLoad]
  );

  const handleMonthChanged = useCallback(
    async (year: number, month: number, force?: boolean) => {
      const { rangeStart, rangeEnd, nextMonth, prevMonth } = getCalendarRange(
        year,
        month,
        timeline
      );

      const prevMonthCacheKey = getCacheKey(
        prevMonth.getFullYear(),
        prevMonth.getMonth()
      );
      const currentMonthCacheKey = getCacheKey(year, month);
      const nextMonthCacheKey = getCacheKey(
        nextMonth.getFullYear(),
        nextMonth.getMonth()
      );

      const cacheKeys = [
        prevMonthCacheKey,
        currentMonthCacheKey,
        nextMonthCacheKey,
      ];

      if (force) {
        fetchEvents(rangeStart, rangeEnd, cacheKeys, true);
      } else {
        if (!state.cachedEvents[currentMonthCacheKey]) {
          fetchEvents(rangeStart, rangeEnd, cacheKeys, true);
        } else if (
          !state.cachedEvents[nextMonthCacheKey] ||
          !state.cachedEvents[prevMonthCacheKey]
        ) {
          fetchEvents(rangeStart, rangeEnd, cacheKeys, false);
        }
      }

      dispatch(calendarStateActions.setCurrentDate(year, month));
    },
    [fetchEvents, getCacheKey, state.cachedEvents, timeline]
  );

  useEffect(() => {
    handleMonthChanged(state.currentYear, state.currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline, onDataLoad]);

  useSecondEffect(() => {
    handleMonthChanged(state.currentYear, state.currentMonth, true);
  }, [onDataLoad]);

  return {
    onMonthChange: handleMonthChanged,
    currentYear: state.currentYear,
    currentMonth: state.currentMonth,
    isLoading: state.loadingMonths[currentCacheKey],
    data: state.cachedEvents[currentCacheKey] || { events: [], groups: [] },
  };
};

export default useCalendarData;
