import React, { useContext, useLayoutEffect, useMemo, useRef } from "react";
import { matchPath, useLocation } from "react-router-dom";

export type CacheContextType = {
  setCache: (
    scope: string,
    id: string,
    keepOn: string[] | true,
    state: any
  ) => any;
  getCache: (scope: string, id: string) => any;
  clearCache: () => any;
};

const CacheContext = React.createContext<CacheContextType>(null);

export interface CacheContextProviderProps {}

const CacheContextProvider: React.FC<CacheContextProviderProps> = (props) => {
  const cacheRef = useRef<Record<string, { v: any; k: string[] | true }>>({});
  const location = useLocation();

  const value = useMemo(() => {
    return {
      setCache: (scope: string, id: string, keepOn: string[], state: any) => {
        cacheRef.current[scope + "|" + id] = { v: state, k: keepOn };
      },
      getCache: (scope: string, id: string): any => {
        return cacheRef.current[scope + "|" + id]?.v ?? null;
      },
      clearCache: () => {
        cacheRef.current = {};
      },
    };
  }, []);

  useLayoutEffect(() => {
    const path = location?.pathname;
    for (const id in cacheRef.current) {
      const k = cacheRef.current[id]?.k || [];
      if (k === true) continue;
      const keep = !!k.find((item) => {
        const result = matchPath(path, {
          path: item,
        });
        return result?.isExact;
      });
      if (!keep) {
        delete cacheRef.current[id];
      }
    }
  }, [location?.pathname]);

  return (
    <CacheContext.Provider value={value}>
      {props.children}
    </CacheContext.Provider>
  );
};

const useCacheContext = () => {
  return useContext(CacheContext);
};

export { useCacheContext, CacheContextProvider };
