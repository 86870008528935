import {
  AdvSelect2,
  AdvSelect2Instance,
  AdvSelectProps2,
} from "@adv-libs/r365-ui";
import { useIsMobile } from "@adv-libs/utils";
import React, { useCallback, useRef } from "react";
import getPopStateName from "../../features/PopState/getPopStateName";
import PopStateItem from "../../features/PopState/PopStateItem";
import { goBackPromise } from "../../features/PopState/useResetHistory";
import { getHistory } from "../../features/Router/history";

const POP_STATE_NAME = "adv-selec2-popover-back";

export type AdvSelect2WithBackProps = Omit<AdvSelectProps2, "popoverWrapper">;

const AdvSelect2WithBack: React.FC<AdvSelect2WithBackProps> = ({
  onMenuClosed,
  ...props
}) => {
  const selectRef = useRef<AdvSelect2Instance>();

  const isMobile = useIsMobile();

  const handleMobileBack = useCallback(() => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  }, []);

  const handleClosed = useCallback(async () => {
    const history = getHistory();
    const state = history.location.state;
    if (
      state?.createdUrl &&
      state?.popState === getPopStateName(POP_STATE_NAME)
    ) {
      await goBackPromise(getHistory());
    }

    if (typeof onMenuClosed === "function") {
      onMenuClosed();
    }
  }, [onMenuClosed]);

  const handlePopState = useCallback(() => {}, []);

  const popoverWrapper = useCallback(
    ({ children }) => {
      if (isMobile) {
        return (
          <PopStateItem
            name={POP_STATE_NAME}
            onBack={handleMobileBack}
            onPopState={handlePopState}
            createUrl
          >
            {children}
          </PopStateItem>
        );
      } else {
        return <>{children}</>;
      }
    },
    [handleMobileBack, handlePopState, isMobile]
  );

  return (
    <AdvSelect2
      ref={selectRef}
      {...(props as any)}
      onMenuClosed={handleClosed}
      popoverWrapper={popoverWrapper}
    />
  );
};

export default AdvSelect2WithBack;
