import { useSecondEffect } from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useCacheContext } from "../../features/Cache/CacheContext";
import serializeError from "../../features/Error/serializeError";
import useI18n from "../../hooks/useI18n";
import breakpoints from "../../style/breakpoints";
import CardHeader from "../Card/CardHeader";
import ErrorMessage from "../ErrorMessage";
import Spinner, { SpinnerStyled } from "../Spinner/Spinner";
import { InfiniteCardState } from "./types";

export interface InfiniteCardProps {
  showMore?: boolean;
  keepOn?: string[];
  header?: string;
  threshold: number;
  id: string;
  request: (page: number) => Promise<any>;
  getFilterValues?: () => Promise<any[]>;
  children?: (data: any[]) => React.ReactNode;
  rightIcon?: React.ReactNode;
  filtersContainer?: React.ReactNode;
}

const initialState: InfiniteCardState = {
  items: [],
  hasMore: true,
  page: 1,
  error: null,
  isLoading: true,
  iteration: 0,
  loaded: false,
};

const CACHE_SCOPE = "infinite-card";

const InfiniteCard: React.FC<InfiniteCardProps> = (props) => {
  const { t } = useI18n();
  const { request } = props;
  const mountedRef = useRef(true);

  const cacheContext = useCacheContext();

  const [state, setState] = useState<InfiniteCardState>(
    cacheContext.getCache(CACHE_SCOPE, props.id) || initialState
  );

  const fetch = useCallback(async () => {
    try {
      setState((state) => {
        return { ...state, isLoading: true, error: null };
      });
      const data = await request(state.page);
      if (!mountedRef.current) return;
      if (data.length === 0) {
        setState((state) => {
          return {
            ...state,
            hasMore: false,
            isLoading: false,
          };
        });
      } else {
        setState((state) => {
          const items = [...state.items, ...data];
          return {
            ...state,
            hasMore: data.length < props.threshold ? false : state.hasMore,
            items,
            isLoading: false,
            loaded: true,
          };
        });
      }
    } catch (err) {
      console.error(err);
      if (!mountedRef.current) return;
      setState((state) => {
        return { ...state, isLoading: false, error: serializeError(err) };
      });
    }
  }, [props.threshold, request, state.page]);

  const handleLoadMore = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        page: state.page + 1,
      };
    });
  }, []);

  useSecondEffect(() => {
    setState({ ...initialState, iteration: state.iteration + 1 });
  }, [request]);

  useEffect(() => {
    if (!state.loaded) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSecondEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.iteration]);

  useLayoutEffect(() => {
    cacheContext.setCache(CACHE_SCOPE, props.id, props.keepOn || [], state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <InfiniteCardStyled>
      {props.header ? (
        <CardHeader>
          <div>{props.header}</div>
          {props.rightIcon ? <RightIcon>{props.rightIcon}</RightIcon> : null}
        </CardHeader>
      ) : null}
      {props.filtersContainer ? props.filtersContainer : null}
      <InfiniteCardContent state={state}>{props.children}</InfiniteCardContent>
      {props.showMore &&
      state.hasMore &&
      !(state.page === 1 && state.isLoading) ? (
        <InfiniteCardShowMore onClick={handleLoadMore}>
          {state.isLoading && state.page !== 1 ? (
            <Spinner />
          ) : (
            <span className="show-more">{t("Show more")}</span>
          )}
        </InfiniteCardShowMore>
      ) : null}
    </InfiniteCardStyled>
  );
};

interface InfiniteCardContentProps {
  state: InfiniteCardState;
  children: (items: any[]) => React.ReactNode;
}

const InfiniteCardContent: React.FC<InfiniteCardContentProps> = (props) => {
  if (props.state.error) {
    return <ErrorMessage error={props.state.error} />;
  }

  if (props.state.isLoading && props.state.page === 1) {
    return <Spinner />;
  }

  return <>{props.children(props.state.items)}</>;
};

const RightIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InfiniteCardShowMore = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 20px;
  padding-bottom: 0;

  .show-more {
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
    color: black;

    &:hover {
      opacity: 0.8;
    }
  }

  ${SpinnerStyled} {
    min-height: initial !important;
    margin: 0px 0px;
  }
`;

export const InfiniteCardStyled = styled.div`
  padding: 16px 8px;

  margin-bottom: 10px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: white;
  overflow: hidden;

  ${SpinnerStyled} {
    min-height: 40px;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default InfiniteCard;
