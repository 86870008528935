const scrollToFirstError = () => {
  setTimeout(() => {
    const $errors = Array.from(
      document.querySelectorAll<HTMLDivElement>(
        ".r365-form-field-wrapper[data-error=true]"
      )
    );

    const $scrollContainer = document.getElementById("scroll-container");

    if ($errors.length && $scrollContainer) {
      const $firstError = $errors[0];
      const yOffset = -150;
      const y =
        $firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;

      $scrollContainer.scrollTo({ top: y, behavior: "smooth" });
    }
  }, 0);
};

export default scrollToFirstError;
