import create from "zustand";
import createUserSlice, { UserSlice } from "./createUserSlice";
import { devtools, persist } from "zustand/middleware";

export type RootState = UserSlice;

const useStore = create<RootState>(
  devtools(
    persist(
      (set, get) => ({
        ...createUserSlice(set, get),
      }),
      { name: "userSettings" }
    )
  )
);

export default useStore;
