import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import API from "../../api/API";
import { CompaniesAPIResponseItem } from "../../api/companiesAPI";
import { UserGetAPIResponse } from "../../api/userAPI";
import { ROUTE_COMPANIES } from "../../app/routes";
import { useStore, userSelectors } from "../../app/store";
import { SpinnerWithProgressInstance } from "../../components/Spinner/SpinnerWithProgress";
import LayoutError from "../../layouts/LayoutError";
import LayoutLoader from "../../layouts/LayoutLoader";
import { getQuery, setUrlWithCompanyId } from "../../utils/path";
import persistentCompanyId from "./persistentCompanyId";

export type CompanyContextType = {
  company: CompaniesAPIResponseItem;
  user: UserGetAPIResponse["app"];
};

const CompanyContext = React.createContext<CompanyContextType>(null);

export interface CompanyContextProviderProps {}

const CompanyContextProvider: React.FC<CompanyContextProviderProps> = (
  props
) => {
  const [contextValue, setContextValue] = useState<CompanyContextType>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = useStore(userSelectors.userId);
  const spinnerRef = useRef<SpinnerWithProgressInstance>();
  const history = useHistory();

  const location = useLocation();

  const companyId = useMemo(() => {
    const url = location.pathname + location.search;
    const query = getQuery(url);
    return query.o;
  }, [location]);

  const getContextData = useCallback(
    async (clientDbId: string) => {
      try {
        setError(null);
        setLoading(true);
        spinnerRef.current?.start();
        const result = { company: null, user: null };
        const companyData = await API.companies.check({
          clientDbId,
          userId,
        });

        if (companyData.content.length === 0) {
          history.replace(ROUTE_COMPANIES.path);
        } else {
          result.company = companyData.content[0];
        }

        const userData = await API.user.get({
          clientDbId: clientDbId,
        });

        result.user = userData.app;

        setContextValue(result);

        spinnerRef.current?.done();
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  useLayoutEffect(() => {
    if (companyId) {
      persistentCompanyId.setValue(companyId);
    } else {
      history.replace(setUrlWithCompanyId(window.location.pathname));
    }

    getContextData(persistentCompanyId.getValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const value = useMemo(() => {
    return contextValue;
  }, [contextValue]);

  return (
    <CompanyContext.Provider value={value}>
      {error ? (
        <LayoutError error={error} />
      ) : value && !loading ? (
        props.children
      ) : (
        <LayoutLoader ref={spinnerRef} />
      )}
    </CompanyContext.Provider>
  );
};

const useCompanyContext = () => {
  return useContext(CompanyContext);
};

export { CompanyContextProvider, useCompanyContext };
