import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvCheckbox,
  AdvCheckboxProps,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormCheckboxFieldProps
  extends Omit<AdvCheckboxProps, "value" | "onCommit"> {
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  size?: any;
}

const FormCheckboxField: React.FC<FormCheckboxFieldProps> = (props) => {
  const { name, size, ...controlProps } = props;

  const formIsSubmitting = useFormIsSubmitting();

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  return (
    <AdvFormField
      fieldName={name}
      control={AdvCheckbox}
      controlProps={controlProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
    ></AdvFormField>
  );
};

export default FormCheckboxField;
