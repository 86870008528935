import {
  assureArray,
  escapeXML,
  isPrimitive,
  useSecondEffect,
} from "@adv-libs/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toastError from "../ToastMessage/toastError";

export interface XMLFilterOptions {
  filterProperties: string[];
  fetchFilters: (filterXML: string) => Promise<any>;
  initialXMLFilterValues?: Record<any, any>;
  trigger?: any;
}

const useXMLFilter = (options: XMLFilterOptions) => {
  const [xmlTrigger, setXmlTrigger] = useState<number>(0);

  const initialFiltersData = useMemo(() => {
    const result = {};
    for (const propertyName of options.filterProperties) {
      result[propertyName] = [];
    }
    return result;
  }, [options.filterProperties]);

  const [isLoading, setIsLoading] = useState(false);
  const filterValueRef = useRef<Record<string, any[]>>({});
  const [filterValue, setFilterValue] = useState<Record<string, any[]>>(
    options.initialXMLFilterValues ?? {}
  );
  const [confirmedFilter, setConfirmedFilter] = useState<Record<string, any[]>>(
    options.initialXMLFilterValues ?? {}
  );
  const [appliedFilter, setAppliedFilter] = useState<Record<string, any[]>>(
    options.initialXMLFilterValues ?? {}
  );

  const [filtersData, setFiltersData] = useState<any>(initialFiltersData);

  const confirmFilter = useCallback(() => {
    /** Filters create from the reference to avoid the memorization */
    const result = {};

    for (const propertyName of options.filterProperties) {
      const filterValue = filterValueRef.current[propertyName];
      result[propertyName] = filterValue ? assureArray(filterValue) : undefined;
    }

    setConfirmedFilter(result);
  }, [options.filterProperties]);

  const cancelFilter = useCallback(() => {
    setFilterValue(appliedFilter);
    setConfirmedFilter(appliedFilter);
  }, [appliedFilter]);

  const applyFilter = useCallback(() => {
    setAppliedFilter(confirmedFilter);
    setXmlTrigger((prev) => prev + 1);
  }, [confirmedFilter]);

  const setFilter = useCallback((propertyName: string, value: any[]) => {
    setFilterValue((state) => {
      return { ...state, [propertyName]: value };
    });
  }, []);

  const clearFilter = useCallback(() => {
    setFilterValue({});
    setConfirmedFilter({});
    setAppliedFilter({});
    setXmlTrigger((prev) => prev + 1);
  }, []);

  const filterXML = useMemo(() => {
    const xml = ["<filters>"];
    for (const propertyName of options.filterProperties) {
      const filterValue = confirmedFilter[propertyName];
      if (filterValue) {
        xml.push(`<${propertyName}>`);
        assureArray(filterValue).forEach((itemOrId) => {
          xml.push(`<r>`);
          xml.push(`<k>${escapeXML(itemOrId?.id ?? itemOrId)}</k>`);
          xml.push(`</r>`);
        });
        xml.push(`</${propertyName}>`);
      }
    }
    xml.push("</filters>");
    return xml.join("");
  }, [confirmedFilter, options.filterProperties]);

  const appliedFilterXML = useMemo(() => {
    const xml = ["<filters>"];
    for (const propertyName of options.filterProperties) {
      const filterValue = appliedFilter[propertyName];
      if (filterValue) {
        xml.push(`<${propertyName}>`);
        assureArray(filterValue).forEach((itemOrId) => {
          xml.push(`<r>`);
          xml.push(`<k>${escapeXML(itemOrId?.id ?? itemOrId)}</k>`);
          xml.push(`</r>`);
        });
        xml.push(`</${propertyName}>`);
      }
    }
    xml.push("</filters>");
    return xml.join("");
  }, [appliedFilter, options.filterProperties]);

  const repopulateValues = useCallback(
    (filtersData) => {
      const result = {};
      for (const propertyName of options.filterProperties) {
        const propertyFilterValue = filterValue[propertyName];

        if (propertyFilterValue) {
          const itemsById = {};

          filtersData[propertyName].forEach((item) => {
            itemsById[item.id] = item;
          }, {});

          const populated = assureArray(propertyFilterValue).map((itemOrId) => {
            if (isPrimitive(itemOrId)) {
              return itemsById[itemOrId];
            } else {
              return itemOrId;
            }
          });

          result[propertyName] = populated;
        } else {
          const propertyFilterValues = filtersData[propertyName];
          const selectedItems = assureArray(propertyFilterValues).filter(
            (item) => {
              return item.selected === true;
            }
          );
          result[propertyName] = selectedItems;
        }
      }

      setFilterValue(result);
      setConfirmedFilter(result);
      setAppliedFilter(result);
      // setXmlTrigger((prev) => prev + 1);
    },
    [filterValue, options.filterProperties]
  );

  const fetchFilters = useCallback(
    async (repopulate?: boolean) => {
      try {
        setIsLoading(true);
        const filters = await options.fetchFilters(filterXML);
        setFiltersData(filters);
        if (repopulate) {
          repopulateValues(filters);
        }
      } catch (err) {
        toastError(err);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterXML, repopulateValues, options.fetchFilters]
  );

  useEffect(() => {
    /** Update references from the real select component value */
    filterValueRef.current = filterValue;
  }, [filterValue]);

  useSecondEffect(() => {
    fetchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterXML, options.trigger]);

  /** Initially load filters data */
  useEffect(() => {
    fetchFilters(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    confirmFilter,
    clearFilter,
    cancelFilter,
    filterXML,
    appliedFilterXML,
    filterValue,
    setFilter,
    applyFilter,
    isLoading,
    filtersData,
    xmlTrigger,
  };
};

export default useXMLFilter;
