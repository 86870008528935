import React from "react";
import styled from "styled-components";
import API from "../../../api/API";
import useAPI from "../../../api/hooks/useAPI";
import CopyField, { CopyFieldStyled } from "../../../components/CopyField";
import StateLoader from "../../../components/StateLoader";
import useI18n from "../../../hooks/useI18n";

export interface CompanyInformationProps {
  clientDbId: string;
}

const CompanyInformation: React.FC<CompanyInformationProps> = (props) => {
  const { t } = useI18n();

  const [state] = useAPI(
    API.companies.get,
    {
      autoStart: {
        clientDbId: props.clientDbId,
      },
      cache: {
        id: "company-information",
        keepOn: true,
      },
    },
    [props.clientDbId]
  );

  return (
    <CompanyInformationStyled>
      <StateLoader error={state.error} isLoading={state.isLoading}>
        {() => {
          return (
            <>
              <div className="h2">{t("Company information")}</div>
              <div className="subheading">{t("Company name")}</div>
              <CopyField>{state.data.app?.im_pav}</CopyField>
              <div className="subheading">{t("Registration code")}</div>
              <CopyField>{state.data.app?.im_kodas}</CopyField>
              <div className="subheading">{t("VAT")}</div>
              <CopyField>{state.data.app?.im_pvm_kodas}</CopyField>
            </>
          );
        }}
      </StateLoader>
    </CompanyInformationStyled>
  );
};

const CompanyInformationStyled = styled.div`
  position: relative;
  width: 100%;
  padding: 28px 16px 16px 16px;
  border-bottom: 1px solid #e4e4e4;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    width: auto;
    min-width: 420px;
    padding: 28px 20px 10px 20px;
  }

  .h2 {
    margin-bottom: 14px;
  }

  .subheading {
    margin-bottom: 2px;
  }

  ${CopyFieldStyled} {
    margin-bottom: 10px;
  }
`;

export default CompanyInformation;
