import {
  AdvCheckbox,
  AdvFormFieldWrapper,
  AdvInput,
  AdvInputPassword,
} from "@adv-libs/r365-ui";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import API from "../../../api/API";
import { ROUTE_COMPANIES, ROUTE_LOGOUT } from "../../../app/routes";
import useStore from "../../../app/store/useStore";
import Form from "../../../components/form/Form";
import SubmitButton from "../../../components/form/SubmitButton";
import { saveTokenExpirationDate } from "../../../features/AuthToken/AuthToken";
import persistentCompanyId from "../../../features/Companies/persistentCompanyId";
import { getHistory } from "../../../features/Router/history";
import useI18n from "../../../hooks/useI18n";
import { addQuery, getQuery, removeQueryParam } from "../../../utils/path";
import {
  AdvFormField,
  useFormIsSubmitting,
  AdvFormAPI,
} from "@adv-libs/adv-form";

function LoginForm() {
  const [rememberMe, setRememberMe] = useState(false);
  const { t, pt } = useI18n();

  const [formAPI, setFormAPI] = useState<AdvFormAPI>();

  const formIsSubmitting = useFormIsSubmitting(formAPI ? formAPI.formName : "");

  const history = useHistory<any>();

  const onSubmit = useCallback(
    async (values) => {
      const redirectedFrom = getHistory().location.state?.from;

      const tokenExpirationDate = await API.auth.login({
        password: values.passwordMGAMA,
        username: values.usernameMGAMA,
        rememberMe: rememberMe,
      });

      saveTokenExpirationDate(
        new Date(tokenExpirationDate).toISOString(),
        rememberMe
      );

      const profile = await API.auth.profile();

      if (redirectedFrom) {
        const orgId = getQuery(redirectedFrom).o;
        persistentCompanyId.setValue(orgId);
      }

      const setUserInfo = useStore.getState().setUser;
      setUserInfo(profile);
    },
    [rememberMe]
  );

  const validation = useMemo(() => {
    return yup.object({
      usernameMGAMA: yup.string().required(t("Field is required")),
      passwordMGAMA: yup.string().required(t("Field is required")),
    });
  }, [t]);

  const handleCheckbox = useCallback(() => {
    setRememberMe((state) => !state);
  }, []);

  useLayoutEffect(() => {
    const redirectedFrom = history.location.state?.from;

    if (redirectedFrom) {
      if (
        redirectedFrom !== ROUTE_COMPANIES.path &&
        redirectedFrom !== ROUTE_LOGOUT.path
      ) {
        const url = addQuery({
          redirectTo: removeQueryParam(redirectedFrom, "o"),
        });

        history.replace(url, {
          from: redirectedFrom,
          redirect: true,
        });
      }
    }
  }, [history, t]);

  useLayoutEffect(() => {
    const clearUser = useStore.getState().clearUser;
    clearUser();
  }, []);

  return (
    <LoginFormStyled>
      <Title>{pt("title", "Login")}</Title>
      <Description>{t("Welcome back to the Rivile MGAMA!")}</Description>

      <Form
        onFormReady={setFormAPI}
        onSubmit={onSubmit}
        validation={validation}
        toastifyError={false}
      >
        <AdvFormField
          fieldName="usernameMGAMA"
          control={AdvInput}
          controlProps={{
            label: t("Email"),
            type: "text",
            autocomplete: true,
          }}
          wrapper={AdvFormFieldWrapper}
          disabled={formIsSubmitting}
        ></AdvFormField>

        <AdvFormField
          fieldName="passwordMGAMA"
          control={AdvInputPassword}
          controlProps={{
            label: t("Password"),
            type: "email",
            autoComplete: "current-password",
          }}
          wrapper={AdvFormFieldWrapper}
          disabled={formIsSubmitting}
          className="password"
        ></AdvFormField>

        <AdvFormFieldWrapper>
          <Actions>
            <AdvCheckbox
              value={rememberMe}
              onCommit={handleCheckbox}
              label={t("Remember me")}
              height={24}
            />

            <a
              href={window.__ENV.REACT_APP_LINK_TO_CHNG_PASSWORD}
              className="forgot-password"
            >
              {t("Forgot password?")}
            </a>
          </Actions>
        </AdvFormFieldWrapper>

        <AdvFormFieldWrapper padding={false}>
          <SubmitButton>{t("Login")}</SubmitButton>
        </AdvFormFieldWrapper>
      </Form>
    </LoginFormStyled>
  );
}

export default LoginForm;

const LoginFormStyled = styled.div`
  .password {
    & ::-ms-reveal {
      display: none;
    }
  }
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 8px;
`;

const Description = styled.h3`
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 7%;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  .forgot-password {
    text-decoration: none;
    color: inherit;
  }
`;
