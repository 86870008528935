import clsx from "clsx";
import i18next from "i18next";
import React, { useCallback } from "react";
import styled from "styled-components";
import useI18n from "../../../hooks/useI18n";
import { GB } from "./LanguageFlags/GB";
import { LT } from "./LanguageFlags/LT";

type LanguageItemType = {
  id: string;
  name: string;
  icon: React.ReactNode;
};

const initLanguages: LanguageItemType[] = [
  { id: "lt", name: "LT", icon: <LT /> },
  { id: "en", name: "EN", icon: <GB /> },
];

export interface LanguagesProps {}

const Languages: React.FC<LanguagesProps> = (props) => {
  const { t, i18n } = useI18n();

  const handleChangeLanguage = useCallback((lng: string) => {
    i18next.changeLanguage(lng);
  }, []);

  return (
    <LanguagesMenu>
      <div>
        <h4>{t("Choose language")}</h4>
      </div>

      <div>
        <LanguagesOptions>
          {initLanguages.map((item) => {
            return (
              <LanguageItem
                key={item.id}
                item={item}
                active={i18n.language}
                onSelect={handleChangeLanguage}
              />
            );
          })}
        </LanguagesOptions>
      </div>
    </LanguagesMenu>
  );
};

interface LanguageItemProps {
  item: LanguageItemType;
  active: string;
  onSelect: (languageId: string) => any;
}

const LanguageItem: React.FC<LanguageItemProps> = (props) => {
  const handleLanguageSelect = useCallback(() => {
    props.onSelect(props.item.id);
  }, [props]);

  return (
    <div
      onClick={handleLanguageSelect}
      className={clsx("lang-item", {
        active: props.item.id === props.active,
      })}
    >
      <div className="lang-icon">{props.item.icon}</div>
    </div>
  );
};

export default Languages;

const LanguagesOptions = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;

  .lang-item {
    padding: 2px;
    padding-bottom: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 44px;
    cursor: pointer;

    &.active {
      border: 1px solid #dadada;
    }
  }
`;

const LanguagesMenu = styled.div`
  display: flex;
  margin: 30px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
