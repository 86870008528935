import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROUTE_LOGOUT } from "../app/routes";
import Card, { CardStyled } from "../components/Card/Card";
import persistentCompanyId from "../features/Companies/persistentCompanyId";
import useI18n from "../hooks/useI18n";
import { Logo } from "../logo";
import APIProcedures from "../api/Procedures";

export interface LayoutErrorProps {
  error?: any;
}

const LayoutError: React.FC<LayoutErrorProps> = (props) => {
  const { t } = useI18n();
  const { error } = props;

  const generateErrorMessage = React.useCallback(
    (message: string) => {
      return (
        <>
          {t("An unexpected error occurred") + ". "}
          <div className="error-red">{message}</div>
        </>
      );
    },
    [t]
  );

  const errorText = React.useMemo(() => {
    const procedure =
      error && error.response.status === 400 && error.response.config.data
        ? JSON.parse(error.response.config.data).procedureName
        : "";

    if (
      procedure === APIProcedures.RGI_MGAMA_GET_ASM_INF &&
      error.response.data?.errors
    ) {
      return error.response.data.errors[0].dataErrors[0].message;
    } else if (
      error &&
      error.response.data?.genericMessage &&
      error.response.data?.errorMessage
    ) {
      return generateErrorMessage(error.response.data.errorMessage);
    } else
      return t("An error occurred. Please try again later (in 10 minutes)");
  }, [error, t, generateErrorMessage]);

  const handleLogoutClick = useCallback((e) => {
    e.preventDefault();
    persistentCompanyId.clearValue();
    window.location.href = ROUTE_LOGOUT.path;
  }, []);

  return (
    <LayoutErrorStyled>
      <Card>
        <div className="logout">
          <Link to={ROUTE_LOGOUT.path} onClick={handleLogoutClick}>
            {t("Logout")}
          </Link>
        </div>
        <div className="logo">
          <Logo black />
        </div>
        <div className="error">{errorText}</div>
        <div className="contact">
          <span>{t("Need help?")} </span>
          <a href="https://www.rivile.lt/kontaktai/">{t("Contact us")}</a>
        </div>
      </Card>
    </LayoutErrorStyled>
  );
};

const LayoutErrorStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 0 16px;

  ${CardStyled} {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .logout {
    position: absolute;
    top: 20px;
    right: 16px;

    a {
      color: #737373;
      text-decoration: none;
    }
  }

  .logo {
    max-width: 100px;
    margin-bottom: 20px;
  }

  .error {
    margin-bottom: 70px;
    text-align: center;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 600;
  }

  .error-red {
    padding-top: 20px;
    text-align: center;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    color: #b30000;
  }

  .contact {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    span {
      display: block;
    }

    a {
      display: block;
      color: #00b373;
      text-decoration: none;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15vh;

  @media screen and (min-width: 480px) {
    ${CardStyled} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    ${CardStyled} {
      padding-left: 80px;
      padding-right: 80px;
    }

    .error {
      max-width: 480px;
    }
  }
`;

export default LayoutError;
