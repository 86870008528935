import { addQueryToUrl } from "./path";

const deobfuscateHash = (obfuscatedHash) => {
  return obfuscatedHash
    .split("")
    .map((i) => {
      return String.fromCharCode(i.charCodeAt(0) - 1);
    })
    .join("");
};

let erpBuild = false;
if (ERP_BUILD_KEY && process.env.NODE_ENV !== "test") {
  const oHash = window[ERP_BUILD_KEY];
  erpBuild = deobfuscateHash(oHash);
}

const getBuild = () => {
  return erpBuild;
};

export const addBuildQuery = (fullUrl: string) => {
  const build = getBuild();
  if (!build) return fullUrl;
  return addQueryToUrl(fullUrl, { b: build });
};

export default getBuild;
