import React, { useCallback, useState, useMemo } from "react";
import useI18n from "../../hooks/useI18n";
import styled from "styled-components";
import API from "../../api/API";
import InfiniteCardWithSearch from "../../components/InfiniteCard/InfiniteCardWithSearch";
import { FormsListAPIResponse } from "../../api/formsAPI";
import NoDataCard from "../../components/NoDataCard";
import TextField from "../../components/TextField";
import { useDebounced, escapeXML } from "@adv-libs/utils";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import FormCard from "./components/FormCard";

interface MyFormsListProps {}

const MyFormsList: React.FC<MyFormsListProps> = (props) => {
  const { t } = useI18n();
  const [filter, setFilter] = useState<string>("");
  const debouncedFiler = useDebounced(filter, 500);
  const companyContext = useCompanyContext();

  const handleSearchFilterChange = useCallback(
    (search) => {
      setFilter(search);
    },
    [setFilter]
  );

  const searchInput = useMemo(() => {
    return (
      <SearchStyled>
        <TextField
          type="text"
          value={filter}
          startIcon={"search-finder"}
          onCommit={handleSearchFilterChange}
          label={t("Search")}
        />
      </SearchStyled>
    );
  }, [handleSearchFilterChange, filter, t]);

  const request = useCallback(
    async (page: number) => {
      const filterXML =
        "<filters><search><r><k>" +
        escapeXML(debouncedFiler) +
        "</k></r></search></filters>";

      return await API.forms.getFormsList({
        clientDbId: companyContext.company.clientDbId,
        filterXML: filterXML,
        page: page,
        size: 5,
      });
    },
    [companyContext.company.clientDbId, debouncedFiler]
  );

  return (
    <InfiniteCardWithSearch
      showMore
      id="forms-selector"
      header={t("Document forms")}
      request={request}
      searchInput={searchInput}
      threshold={5}
      noBorders
    >
      {(items: FormsListAPIResponse) => {
        if (!items.length) {
          return <NoDataCard>{t("No documents forms")}</NoDataCard>;
        }
        return (
          <MyFormListStyled>
            {items.map((item, key) => {
              return (
                <FormItem key={key}>
                  <FormCard item={item} />
                </FormItem>
              );
            })}
          </MyFormListStyled>
        );
      }}
    </InfiniteCardWithSearch>
  );
};

const MyFormListStyled = styled.div`
  border-top: 1px solid #e6e6e6;
`;

const FormItem = styled.div`
  border-bottom: 1px solid #e6e6e6;
`;

const SearchStyled = styled.div`
  > div {
    margin-bottom: 0;
  }

  .r365-control {
    border: 1px solid #e6e6e6;

    .r365-control__end-icon--clickable {
      background-color: #fff;
    }
  }
`;

export default MyFormsList;
