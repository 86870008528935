import React from "react";
import { Helmet } from "react-helmet";

export interface PageTitleProps {}

const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <Helmet>
      <title>
        {(props.children ? props.children + " | " : "") + "Rivile MGAMA"}
      </title>
    </Helmet>
  );
};

export default PageTitle;
