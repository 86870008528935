enum FieldTypes {
  SELECT = "select",
  NUMBER = "number",
  TEXT = "text",
  TEXTAREA = "textarea",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  DATE = "date",
  DATERANGE = "daterange",
  LABEL = "label",
  HIDDEN = "hidden",
}

export default FieldTypes;
