import { Menu } from "@adv-libs/r365-ui";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import actionAfterCompanySelect from "../../../actions/actionAfterCompanySelect";
import API from "../../../api/API";
import useAPI from "../../../api/hooks/useAPI";
import { ROUTE_LOGOUT, ROUTE_MAIN } from "../../../app/routes";
import { userSelectors, useStore } from "../../../app/store";
import StateLoader from "../../../components/StateLoader";
import { getHistory } from "../../../features/Router/history";
import useI18n from "../../../hooks/useI18n";
import { getQuery } from "../../../utils/path";
import CompaniesBoxes from "./CompaniesBoxes";
export interface CompaniesListFormProps {}

const CompaniesListForm: React.FC<CompaniesListFormProps> = (props) => {
  const userId = useStore(userSelectors.userId) || "";
  const { t } = useI18n();

  const [state] = useAPI(
    API.companies.list,
    {
      autoStart: { userId },
      toastifyError: false,
    },
    [userId]
  );

  const onSubmit = useCallback(async (choosenDbId: any) => {
    const history = getHistory();
    const location = history.location;
    let redirectTo = ROUTE_MAIN.path;
    const redirectedFrom = location.state?.from;
    if (redirectedFrom) {
      const redirectToFromUrl = getQuery(redirectedFrom).redirectTo;
      if (redirectToFromUrl) {
        redirectTo = decodeURIComponent(redirectToFromUrl);
      }
    }
    actionAfterCompanySelect(choosenDbId, redirectTo);
  }, []);

  return (
    <>
      <Title>{t("Companies")}</Title>
      <StateLoader isLoading={state.isLoading} error={state.error}>
        {() => {
          return (
            <CompaniesListFormContainer>
              <Description>
                {t("Choose an company for your work environment")}
              </Description>

              <CompaniesList>
                <Menu flat>
                  <CompaniesBoxes
                    data={state.data.content}
                    onChange={onSubmit}
                    autoSelect
                  />
                </Menu>
              </CompaniesList>

              <Link to={ROUTE_LOGOUT.path} className="logout">
                {t("Logout")}
              </Link>
            </CompaniesListFormContainer>
          );
        }}
      </StateLoader>
    </>
  );
};

export default CompaniesListForm;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 8px;
`;

const Description = styled.h3`
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 7%;
`;

const CompaniesListFormContainer = styled.div`
  .logout {
    display: block;
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
`;

const CompaniesList = styled.div`
  margin-bottom: 20px;

  .r365-menu {
    padding-left: 0;

    .box {
      cursor: pointer;
    }
  }
`;
