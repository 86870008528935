import * as History from "history";
import { BrowserRouter } from "react-router-dom";

const modHistory = (
  router: BrowserRouter & { history: History.History<any> }
) => {
  if (router) {
    const getCurrentIndex = () => {
      return window.history.state?.state?.idx ?? 0;
    };

    const history = router.history;
    const originalPush = history.push;
    history.push = async (location: any, state?: any) => {
      const currentIndex = getCurrentIndex();
      const nextIndex = currentIndex + 1;
      if (typeof location === "string") {
        let newState;
        if (typeof state === "object") {
          newState = {
            ...state,
            idx: nextIndex,
          };
        } else if (!state) {
          newState = {
            idx: nextIndex,
          };
        } else if (typeof state === "string") {
          throw new Error("Location history state of string is not supported.");
        }
        return originalPush.call(history, location, newState);
      } else {
        let newState;
        if (typeof location.state === "object") {
          newState = {
            ...location.state,
            idx: nextIndex,
          };
        } else if (!location.state) {
          newState = {
            idx: nextIndex,
          };
        } else if (typeof location.state === "string") {
          throw new Error("Location history state of string is not supported.");
        }
        location.state = newState;
        return originalPush.call(history, location);
      }
    };

    const originalReplace = history.replace;
    history.replace = async (location: any, state?: History.LocationState) => {
      const nextIndex = getCurrentIndex();
      if (typeof location === "string") {
        let newState;
        if (typeof state === "object") {
          newState = {
            ...state,
            idx: nextIndex,
          };
        } else if (!state) {
          newState = {
            idx: nextIndex,
          };
        } else if (typeof state === "string") {
          throw new Error("Location history state of string is not supported.");
        }
        return originalReplace.call(history, location, newState);
      } else {
        let newState;
        if (typeof location?.state === "object") {
          newState = {
            ...location.state,
            idx: nextIndex,
          };
        } else if (!location?.state) {
          newState = {
            idx: nextIndex,
          };
        } else if (typeof location?.state === "string") {
          throw new Error("Location history state of string is not supported.");
        }
        if (location) {
          location.state = newState;
        }
        return originalReplace.call(history, location);
      }
    };
  }
};

export default modHistory;
