const breakpoints = {
  xs: 576,
  s: 768,
  m: 992,
  l: 1200,
  xl: 1600,
  xxl: 1920,
  xxxl: 2560
};

export default breakpoints;
