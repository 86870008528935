import { AdvStaticStatus } from "@adv-libs/r365-ui";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TasksListAPIResponseItem } from "../../../api/tasksAPI";
import { ROUTE_APPROVE_TASK, ROUTE_TASK } from "../../../app/routes";
import useI18n from "../../../hooks/useI18n";
import assureNumber from "../../../utils/assureNumber";
import { setUrlWithCompanyId } from "../../../utils/path";
import statusIconMap from "../statusIconMap";
import statusMap from "../statusMap";

export interface TaskCardProps {
  item: TasksListAPIResponseItem;
}

const TaskCard: React.FC<TaskCardProps> = (props) => {
  const { pt } = useI18n();

  const route =
    props.item.status === "1"
      ? ROUTE_APPROVE_TASK.createPath({ id: props.item.id })
      : ROUTE_TASK.createPath({ id: props.item.id });

  return (
    <TaskCardStyled>
      <Link to={setUrlWithCompanyId(route)}>
        <Top>
          <LeftSide>
            <div className="date">{props.item.item1}</div>
          </LeftSide>
          <RightSide>
            <div className="status">
              <AdvStaticStatus
                id={assureNumber(props.item.status)}
                narrow
                name={pt("task", statusMap[props.item.status])}
                icon={statusIconMap[props.item.status]}
              />
            </div>
          </RightSide>
        </Top>
        <Bottom>
          <div className="user">{props.item.item2}</div>
          <div className="title">{props.item.item3}</div>
        </Bottom>
      </Link>
    </TaskCardStyled>
  );
};

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Bottom = styled.div``;

const RightSide = styled.div``;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

const TaskCardStyled = styled.div`
  background: ${({ theme }) => theme.greyColor};
  border-radius: 6px;
  transition: background 0.05s linear;

  &:hover {
    background: #e5e5e8;
  }

  a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 10px 10px 10px 16px;
  }

  .date {
    font-size: 12px;
    font-weight: 600;
  }

  .user {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .title {
    font-weight: 400;
    font-size: 12px;
    color: #737373;
  }

  .status {
    .r365-status {
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
    }
  }
`;

export default TaskCard;
