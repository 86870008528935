import { AdvFormAPI } from "@adv-libs/adv-form";
import { Button, AdvFormGroup } from "@adv-libs/r365-ui";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { ROUTE_DOCUMENTS } from "../../app/routes";
import BackButtonDesktop from "../../components/BackButtonDesktop";
import Card, { CardStyled } from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import Form from "../../components/form/Form";
import SubmitButton from "../../components/form/SubmitButton";
import NoDataCard from "../../components/NoDataCard";
import PageTitle from "../../components/PageTitle";
import StateLoader from "../../components/StateLoader";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import PopStateItem from "../../features/PopState/PopStateItem";
import goSafeBack, { createGoSafeBack } from "../../features/Router/goSafeBack";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import toastSuccess from "../../features/ToastMessage/toastSuccess";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import { setUrlWithCompanyId } from "../../utils/path";
import DocumentFormField from "./components/DocumentFormField";
import { useRouteMatch } from "react-router-dom";
import toastError from "../../features/ToastMessage/toastError";
import types from "./components/FieldTypes";
import { escapeXML } from "@adv-libs/utils";
import { useJexl } from "../../hooks";
import { contextType } from "../../api/documentsAPI";

export interface MyDocumentFormProps {}

const MyDocumentForm: React.FC<MyDocumentFormProps> = (props) => {
  const companyContext = useCompanyContext();
  const { t, pt } = useI18n();
  const [formAPI, setFormAPI] = useState<AdvFormAPI>();
  const [validation, setValidation] = useState(yup.object({}));
  const [context, setContext] = useState<contextType>();
  const [hiddenValue, setHiddenValue] = useState({});
  const [validationContext, setValidationContext] = useState({});
  const {
    params: { id: formId },
  } = useRouteMatch<any>();

  const [state] = useAPI(
    API.documents.documentsForm,
    {
      autoStart: {
        clientDbId: companyContext.company.clientDbId,
        formId: formId,
      },
    },
    [companyContext.company.clientDbId]
  );

  const { compileExpressions, evaluateExpr, error: JexlError } = useJexl();

  const generateXML = useCallback(
    (values: { [key: string]: any }) => {
      const xml = ["<result>"];
      let no = 1;

      if (context.field) {
        context.field.forEach((item) => {
          const isFieldinValues = item.name in values;
          const fieldValue = values[item.name];

          if (
            (item.type === types.LABEL && item.visible === true) ||
            item.type === types.HIDDEN
          ) {
            const isHiddenValue = item.name in hiddenValue;

            xml.push(`<field>`);
            xml.push(`<id>${item.name}</id>`);
            xml.push(`<type>${item.type}</type>`);
            xml.push(
              `<caption>${item.label ? escapeXML(item.label) : ""}</caption>`
            );

            if (item.type === types.HIDDEN && isHiddenValue) {
              xml.push(
                `<value>${
                  hiddenValue[item.name]
                    ? escapeXML(hiddenValue[item.name])
                    : ""
                }</value>`
              );
            } else {
              xml.push(
                `<value>${item.default ? escapeXML(item.default) : ""}</value>`
              );
            }

            xml.push(`<o>${no}</o>`);
            xml.push(`</field>`);
            no += 1;
          } else if (isFieldinValues) {
            xml.push(`<field>`);
            xml.push(`<id>${item.name}</id>`);
            xml.push(`<type>${item.type}</type>`);
            xml.push(
              `<caption>${item.label ? escapeXML(item.label) : ""}</caption>`
            );
            xml.push(`<o>${no}</o>`);
            no += 1;

            if (fieldValue) {
              if (Array.isArray(fieldValue)) {
                xml.push(`<value>${fieldValue.join(" - ")}</value>`);
              } else if (
                typeof fieldValue === "object" &&
                fieldValue !== null &&
                !Array.isArray(fieldValue)
              ) {
                xml.push(`<value>${fieldValue.id}</value>`);
                xml.push(
                  `<value_name>${
                    fieldValue.name ? escapeXML(fieldValue.name) : ""
                  }</value_name>`
                );
              } else xml.push(`<value>${escapeXML("" + fieldValue)}</value>`);
            }

            xml.push(`</field>`);
          }
        });
      }

      xml.push("</result>");

      return xml.join("");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [context, hiddenValue]
  );

  const saveHiddenValue = useCallback((field: string, value: any) => {
    setHiddenValue((prev) => ({ ...prev, [field]: value }));
  }, []);

  const changeFieldValue = useCallback(
    (field, value) => {
      if (formAPI) formAPI.setValue(field, value, { silent: true });
    },
    [formAPI]
  );

  const changeFieldAttributes = useCallback(
    (fieldName, attribute, value) => {
      if (state.data?.isFormDynamic) {
        setContext((prevContext) => {
          const { field, ...restContext } = prevContext;
          const newFields = field.map((item) => {
            if (item.name === fieldName) {
              return { ...item, [attribute]: value };
            }
            return item;
          });

          return { ...restContext, field: newFields };
        });

        if (attribute === "required")
          setValidationContext((prev) => ({ ...prev, [fieldName]: value }));
      }
    },
    [state.data]
  );

  const onFieldChange = useCallback(
    (field, value) => {
      changeFieldAttributes(field, "value", value);
    },
    [changeFieldAttributes]
  );

  useEffect(() => {
    if (state.data?.validation)
      setValidation(yup.object(state.data.validation));

    if (state.data?.context) setContext(state.data.context);
    if (state.data?.dynamicAttr) compileExpressions(state.data.dynamicAttr);
  }, [t, state.data, compileExpressions]);

  const handleSubmit = useCallback(
    async (values, formAPI: AdvFormAPI) => {
      const xml = generateXML(values);
      try {
        await API.documents.documentsRequest({
          clientDbId: companyContext.company.clientDbId,
          xml: xml,
          formId: state.data?.formId,
        });

        formAPI.setTouched(false);
        goSafeBack(ROUTE_DOCUMENTS.path);
        setTimeout(() => {
          toastSuccess(t("Request is created"));
        }, 1);
      } catch (err) {
        toastError(err);
      }
    },
    [companyContext.company.clientDbId, t, state.data, generateXML]
  );

  const handlePopStateItem = useCallback(() => {
    if (formAPI && formAPI.isTouched()) {
      return t("Are you sure to exit?");
    }
  }, [formAPI, t]);

  return (
    <>
      <PageTitle>{state.data?.formName}</PageTitle>
      <ScrollRestoration id="documents-request" restoreTop />
      <PopStateItem
        name="documents-request-form"
        onPopState={handlePopStateItem}
      >
        <MyDocumentsFormStyled>
          <Grid>
            <GridCol col-l="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
              <Card>
                <BackButtonDesktop />
                <CardBody>
                  <StateLoader
                    error={state.error || JexlError}
                    isLoading={state.isLoading}
                  >
                    {() => {
                      if (state.data?.rows?.length > 0) {
                        return (
                          <>
                            <Header>{state.data?.formName}</Header>
                            <Form
                              onSubmit={handleSubmit}
                              onFormReady={setFormAPI}
                              validation={validation}
                              showGenericError
                              toastifyError={false}
                              initialValues={state.data.initialValues}
                              onFieldChange={onFieldChange}
                              validationContext={validationContext}
                            >
                              {state.data.rows.map((item, key) => (
                                <AdvFormGroup noMargin key={key}>
                                  {item.map((field, key) => (
                                    <DocumentFormField
                                      field={field}
                                      key={key}
                                      data={state.data.data}
                                      evalExpr={evaluateExpr}
                                      context={context}
                                      changeFieldValue={changeFieldValue}
                                      changeFieldAttributes={
                                        changeFieldAttributes
                                      }
                                      saveHiddenValue={saveHiddenValue}
                                    />
                                  ))}
                                </AdvFormGroup>
                              ))}

                              <FormActions>
                                <div className="close-button">
                                  <Button
                                    fill
                                    onClick={createGoSafeBack(
                                      setUrlWithCompanyId(ROUTE_DOCUMENTS.path)
                                    )}
                                  >
                                    {t("Close")}
                                  </Button>
                                </div>
                                <div className="submit-button">
                                  <SubmitButton>{t("Submit")}</SubmitButton>
                                </div>
                              </FormActions>
                            </Form>
                          </>
                        );
                      } else {
                        return (
                          <NoDataCard>
                            {pt("documents", "No information available")}
                          </NoDataCard>
                        );
                      }
                    }}
                  </StateLoader>
                </CardBody>
              </Card>
            </GridCol>
          </Grid>
        </MyDocumentsFormStyled>
      </PopStateItem>
    </>
  );
};

const FormActions = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  /* gap: 14px; */
  /* justify-content: flex-end; */

  .close-button {
    order: 2;
  }

  .submit-button {
    order: 1;
  }

  .r365-button {
    .r365-button__content {
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;

    .close-button {
      order: 1;
    }

    .submit-button {
      order: 2;
    }

    .r365-button {
      min-width: 200px;
    }
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 6px;
`;

const MyDocumentsFormStyled = styled.div`
  margin: 0 auto;

  form {
    ${CardStyled} {
      margin: 6px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export default MyDocumentForm;
