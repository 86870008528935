import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import actionLogout from "../actions/actionLogout";

export interface LogoutProps {}

const Logout: React.FC<LogoutProps> = (props) => {
  const history = useHistory();

  useLayoutEffect(() => {
    actionLogout(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
