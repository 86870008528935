class AbstractError {
  public name: string;
  public __isError: boolean = true;
  public title: string;
  public intent: string;
  public description: string;
  public stack: string;
  public fields: Record<string, { description: string; intent?: string }[]> = {};
  public details: string[] = [];

  public toString() {
    return [this.name, this.description].filter(Boolean).join(": ");
  }

  public toJSON() {
    return {
      name: this.name,
      title: this.title,
      description: this.description,
      intent: this.intent,
      fields: this.fields,
      details: this.details,
      __isJSONError: true,
    };
  }

  public print() {
    if (typeof this.stack !== "undefined") {
      console.error(this.stack);
    } else {
      console.error(this.toJSON());
    }
  }

  public static isRFC7807Error = (err) => {
    if (!err.response) return false;
    const data = err.response.data;
    if (typeof data === "object") {
      if ("title" in data || "detail" in data || "violations" in data) {
        return true;
      }
    }
    return false;
  };
}

export default AbstractError;
