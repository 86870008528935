import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvActiveButton,
  AdvActiveButtonProps,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormActiveButtonFieldProps
  extends Omit<AdvActiveButtonProps, "value" | "onCommit"> {
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  size?: any;
}

const FormActiveButtonField: React.FC<FormActiveButtonFieldProps> = (props) => {
  const { name, size, ...controlProps } = props;

  const formIsSubmitting = useFormIsSubmitting();

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  return (
    <AdvFormField
      fieldName={name}
      control={AdvActiveButton}
      controlProps={controlProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
    ></AdvFormField>
  );
};

export default FormActiveButtonField;
