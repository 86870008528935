import * as yup from "yup";
import AbstractError from "../../features/Error/AbstractError";
import i18n from "../../utils/i18n";

class FormValidationError extends AbstractError {
  public name = "FormValidationError";

  constructor(options: { validationError: yup.ValidationError }) {
    super();
    this.intent = "danger";
    const { validationError } = options;

    this.description = i18n.t("There are errors in the form");

    this.fields = validationError.inner.reduce((result, { path, errors }) => {
      const pathInDotNotation = path.replace(/\[([0-9]+)\]/g, ".$1");
      return {
        ...result,
        [pathInDotNotation]: errors.map((message) => {
          return { description: message, intent: "danger" };
        }),
      };
    }, {});
  }
}

export default FormValidationError;
