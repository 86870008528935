import { AdvStaticStatus } from "@adv-libs/r365-ui";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FormsListAPIResponseItem } from "../../../api/formsAPI";
import { ROUTE_FORM } from "../../../app/routes";
import { setUrlWithCompanyId } from "../../../utils/path";
import useI18n from "../../../hooks/useI18n";

export interface FormCardProps {
  item: FormsListAPIResponseItem;
}

const FormCard: React.FC<FormCardProps> = (props) => {
  const { t } = useI18n();
  const statusColor = props.item.isActive
    ? "rgba(204, 235, 205, 1)"
    : "rgba(246, 221, 221, 1)";
  const textColor = props.item.isActive
    ? "rgba(13, 128, 95, 1)"
    : "rgba(179, 0, 0, 1)";
  const statusName = props.item.isActive ? t("Active") : t("Inactive");

  return (
    <FormCardStyled inactive={props.item.isActive}>
      <Link
        to={setUrlWithCompanyId(ROUTE_FORM.createPath({ id: props.item.id }))}
      >
        <Top>
          {statusName && (
            <div className="status" style={{ color: statusColor }}>
              <AdvStaticStatus
                name={statusName}
                background={statusColor}
                narrow
                template={false}
                color={textColor}
              />
            </div>
          )}
        </Top>

        <Bottom>
          <div className="title">{props.item.title}</div>
        </Bottom>
      </Link>
    </FormCardStyled>
  );
};

const Top = styled.div`
  display: flex;
`;

const Bottom = styled.div`
  display: flex;
`;

const FormCardStyled = styled.div<{ inactive?: boolean }>`
  &:hover {
    background: #e5e5e8;
  }

  a {
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-decoration: none;
    color: inherit;
    padding: 12px 16px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    padding: 0 4px;
    color: rgba(0, 0, 0, 1);
    ${({ inactive }) => (!inactive ? "color: rgba(133,133,133,1);" : null)};
  }

  .status {
    .r365-status {
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      min-width: 70px;
      justify-content: center;
    }
  }
`;

export default FormCard;
