import React from "react";
import usePopState from "./usePopState";

export interface PopStateInjectionProps {}

const PopStateInjection: React.FC<PopStateInjectionProps> = () => {
  usePopState();
  return null;
};

export default PopStateInjection;
