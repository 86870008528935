import { statusCodes } from "@adv-libs/utils";
import i18next from "i18next";
import i18n from "../../utils/i18n";
import AbstractError from "./AbstractError";

const formatStatusTitle = (status: number) => {
  const title = statusCodes[status] ? statusCodes[status] : null;
  return i18n.pt("http", title, { ns: "adv-libs" });
};

class ServerError extends AbstractError {
  public name = "ServerError";

  constructor(
    options: { status?: number; data: string | any } = { data: null }
  ) {
    super();
    this.intent = "danger";

    if (typeof options.data === "string") {
      if (options.status) {
        this.title = formatStatusTitle(options.status);
      }
      this.description = options.data;
    } else {
      let title = options.data ? options.data.title : null;
      let detail = options.data ? options.data.detail : null;
      if (!title && options.status) {
        title = formatStatusTitle(options.status);
      }
      if (detail && title) {
        this.title = title;
        this.description = detail;
      } else if (title && !detail) {
        this.description = title;
      } else if (!title && detail) {
        this.description = detail;
      } else {
        this.description = i18next.t(
          "Something goes wrong... Contact with administrator"
        );
      }

      if (options.data && options.data.violations) {
        this.fields = options.data.violations.reduce((result, item) => {
          // BE workaround
          const fieldName = item.field.replace(/^[a-z0-9]+\.command\./gi, "");
          if (!result[fieldName]) {
            result[fieldName] = [
              { description: item.message, intent: "danger" },
            ];
          } else {
            result[fieldName].push({
              description: item.message,
              intent: "danger",
            });
          }
          return result;
        }, {});
      }
    }
    this.update();
  }

  update() {
    this.details = Object.values(this.fields).flatMap((field) => {
      return field.map(({ description }) => {
        return description;
      });
    });
  }
}

export const isServerError = (error: any): error is ServerError => {
  return error.name === "ServerError";
};

export default ServerError;
