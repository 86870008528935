import i18next from "i18next";
import ClientError from "./ClientError";
import ServerError from "./ServerError";

const serializeError = (err) => {
  if (!err) {
    const _err = new Error(
      i18next.t("Received falsy error. Something wrong with error handling.")
    );
    console.error(_err);
    return new ClientError({ message: _err.toString() }).toJSON();
  }
  if (typeof err === "string") {
    return new ClientError({ message: err }).toJSON();
  } else if (typeof err === "boolean") {
    return new ClientError({
      message: i18next.t(
        "Received error of type boolean. Something wrong with error handling"
      ),
    }).toJSON();
  } else if (typeof err === "object" && err) {
    if (err.__isError) {
      // R365 Error
      return err.toJSON();
    }
    if (err.__isJSONError) {
      // Serialized R365 Error
      return err;
    }
    if (
      err.graphQLErrors?.length &&
      err.graphQLErrors[0].extensions?.rfcException
    ) {
      // GQL RCF exception
      return new ServerError({
        data: err.graphQLErrors[0].extensions.rfcException,
      }).toJSON();
    } else if (err.networkError) {
      console.error(err);
      return new ServerError({
        data: {
          title: err.networkError.name,
          detail: err.networkError.message,
        },
      }).toJSON();
    } else if (err.isAxiosError) {
      if (err.response) {
        if (err.response.data instanceof ArrayBuffer) {
          const buffer = Buffer.from(err.response.data);
          return new ServerError({
            status: err.response.status,
            data: buffer.toString("utf-8"),
          }).toJSON();
        }
        if (err.response.data?.genericMessage) {
          return new ServerError({
            data: {
              title: i18next.t("Error"),
              detail: err.response.data?.errorMessage
                ? i18next.t("An unexpected error occurred") +
                  ". " +
                  err.response.data?.errorMessage
                : i18next.t("An unexpected error occurred"),
            },
          });
        }
        if (Array.isArray(err.response.data?.errors)) {
          let detail = "";
          const messageArray = err.response.data?.errors;
          for (let i = 0; i < messageArray.length; i++) {
            if (
              messageArray[i].dataErrors &&
              messageArray[i].dataErrors.length > 0
            ) {
              for (let j = 0; j < messageArray[i].dataErrors.length; j++) {
                detail += `${
                  messageArray[i].dataErrors[j].code === "0"
                    ? ""
                    : messageArray[i].dataErrors[j].code
                } ${messageArray[i].dataErrors[j].message}. \n`;
              }
            }
          }

          return new ServerError({
            data: {
              title:
                err.response.data?.errorMessage ||
                err.response.data?.message ||
                i18next.t("Error"),
              detail,
            },
          });
        }
        if (err.response.data?.errorMessage) {
          return new ServerError({
            status: err.response.status,
            data: {
              detail: err.response.data?.errorMessage,
            },
          });
        }
        if (err.response.data?.message) {
          return new ServerError({
            status: err.response.status,
            data: {
              detail: err.response.data?.message,
            },
          });
        }
        return new ServerError({
          status: err.response.status,
          data: err.response.data,
        }).toJSON();
      } else {
        return new ServerError({
          data: err.message,
        });
      }
    } else if (err.message && err.stack) {
      // Javascript exception
      console.error(err);
      return new ClientError({ message: err.message }).toJSON();
    } else if (err) {
      console.warn(
        "Simple object passed in to error serializations. Unexpected issues can occur"
      );
      console.error(err);
      return err;
    }
  } else if (typeof err === "string") {
    return new ClientError({ message: err }).toJSON();
  }
};

export default serializeError;
