import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`${css`
  * {
    letter-spacing: 0.04em;
  }

  body {
    font-family: "Inter", sans-serif;
    background-color: #f5f5f8;
    color: #292e3a;
  }

  html,
  body {
    height: 100%;
    margin: 0;
  }

  #root {
    height: 100%;

    & > div:first-child {
      height: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  h1 {
    font-size: 1.75rem; // 28px
  }

  h2 {
    font-size: 1.125rem; // 18px
  }

  h3 {
    font-size: 0.875rem; // 14px
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  h4 {
    font-size: 12px; // 12px
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  .h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .subheading {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
  }

  .r365-button__content {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
`}`;

export default GlobalStyle;
