import Client from "./core/Client";
import createAPIHandler from "./utils/createAPIHandler";

export type LoginAPIPayload = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export type ChangePasswordAPIPayload = {
  oldPassword: string;
  password: string;
  repeatPassword: string;
};

export type ProfileAPIResponse = {
  userId: string;
  fullName: string;
  email: string;
  role: string;
};

export const AuthAPIRequest = () => {
  return {
    login: createAPIHandler(async (payload: LoginAPIPayload) => {
      const response = await Client.post("/auth/login", {
        username: payload.username,
        password: payload.password,
        device: navigator.userAgent,
        application: "MGAMA",
        rememberMe: payload.rememberMe,
      });
      const expiration = response.headers["x-token-expires"];
      return expiration;
    }),
    refresh: createAPIHandler(async () => {
      const response = await Client.post("/auth/refresh");
      const expiration = response.headers["x-token-expires"];
      return expiration;
    }),
    profile: createAPIHandler<any, ProfileAPIResponse>(async () => {
      const response = await Client.get("/auth/profile");
      return response.data as any;
    }),
    changePassword: createAPIHandler(
      async (payload: ChangePasswordAPIPayload) => {
        const response = await Client.post("/auth/change-password", {
          oldPassword: payload.oldPassword,
          password: payload.password,
          repeatPassword: payload.repeatPassword,
        });
        return response.data;
      }
    ),
    logout: createAPIHandler(async () => {
      const response = await Client.post("/auth/logout");
      return response.data;
    }),
  };
};
