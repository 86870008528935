import { Button as R365Button, ButtonProps } from "@adv-libs/r365-ui";
import styled from "styled-components";

interface IButton extends React.PropsWithChildren<ButtonProps> {
  small?: boolean;
}

function Button(props: IButton) {
  return <ButtonStyle {...props} />;
}

export default Button;

const ButtonStyle = styled(R365Button)`
  margin-bottom: ${(props) => `${props.theme.spacing * 3}px`};
  font-size: 12px;

  width: 100%;

  div {
    width: 100%;
  }

  button.r365-button__content {
    width: 100%;
  }

  .r365-button__title-text {
    font-size: ${(props) =>
      (props as any).small
        ? "0.75rem"
        : undefined}; /*Transient props v5.1 in styled-components*/
  }
`;
