import { CalendarDataType } from "@adv-libs/calendar";
import {
  createActionCreators,
  createReducerFunction,
  ImmerReducer,
} from "immer-reducer";
import { CalendarStateType } from "./types";

const defaultCurrentMonth = new Date().getMonth();
const defaultCurrentYear = new Date().getFullYear();

const initialCalendarState: CalendarStateType = {
  currentYear: defaultCurrentYear,
  currentMonth: defaultCurrentMonth,
  loadingMonths: {},
  cachedEvents: {},
};

class CalendarStateReducer extends ImmerReducer<CalendarStateType> {
  setLoadingMonths(cacheKeys: string[], loading: boolean) {
    cacheKeys.forEach((cacheKey) => {
      this.draftState.loadingMonths[cacheKey] = loading;
    });
  }

  setEvents(cacheKeys: string[], data: CalendarDataType) {
    this.draftState.cachedEvents = {};
    cacheKeys.forEach((cacheKey) => {
      this.draftState.cachedEvents[cacheKey] = data;
    });
  }

  setCurrentDate(year: number, month: number) {
    this.draftState.currentYear = year;
    this.draftState.currentMonth = month;
  }
}

const calendarStateActions = createActionCreators(CalendarStateReducer);
const calendarStateReducer = createReducerFunction(CalendarStateReducer);

export {
  initialCalendarState,
  calendarStateReducer,
  calendarStateActions,
  CalendarStateReducer,
};
