import AbstractError from "./AbstractError";
import i18next from "i18next";

class ClientError extends AbstractError {
  public name = "ClientError";

  constructor(options: { message: string; stack?: string }) {
    super();

    this.intent = "danger";
    this.title = i18next.t("Error");
    this.description = options.message;
    this.stack = options.stack;
  }

  public static fromNativeError(err: Error) {
    const clientError = new ClientError({ message: err.toString() });
    clientError.stack = err.stack;
    return clientError;
  }
}

export default ClientError;
