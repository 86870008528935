import { Dropdown, DropdownInstance, DropdownProps } from "@adv-libs/r365-ui";
import React, { useCallback, useRef } from "react";
import getPopStateName from "../../features/PopState/getPopStateName";
import PopStateItem from "../../features/PopState/PopStateItem";
import { getHistory } from "../../features/Router/history";

export interface DropdownWithBackProps extends DropdownProps {
  onBackClose?: () => any;
}

const POP_STATE_NAME = "dropdown-back";

const DropdownWithBack: React.FC<DropdownWithBackProps> = (props) => {
  const { children, onClosed, ...restProps } = props;
  const childrens = React.Children.toArray(children);

  const triggerChildren = childrens[0];
  const menuChildren = childrens[1];

  const dropdownRef = useRef<DropdownInstance>();

  const handleClosed = useCallback(() => {
    // Deferred
    setTimeout(() => {
      const history = getHistory();
      const state = history.location.state;
      if (
        state?.createdUrl &&
        state?.popState === getPopStateName(POP_STATE_NAME)
      ) {
        getHistory().goBack();
      }
    }, 0);
    if (onClosed) {
      onClosed();
    }
  }, [onClosed]);

  const handleBack = useCallback(() => {
    dropdownRef.current.close();
    if (typeof props.onBackClose === "function") {
      props.onBackClose();
    }
  }, [props]);

  const handlePopState = useCallback(() => {}, []);

  return (
    <Dropdown {...restProps} ref={dropdownRef} onClosed={handleClosed}>
      {triggerChildren}
      <PopStateItem
        name={POP_STATE_NAME}
        onBack={handleBack}
        onPopState={handlePopState}
        createUrl
      >
        {menuChildren}
      </PopStateItem>
    </Dropdown>
  );
};

export default DropdownWithBack;
