import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useCacheContext } from "../../features/Cache/CacheContext";
import serializeError from "../../features/Error/serializeError";
import { APIRequestOptions } from "../types";

export interface UseAPIOptions<P extends {} = {}> {
  autoStart?: boolean | P;
  cache?: {
    id: string;
    keepOn: string[] | true;
  };
  toastifyError?: boolean;
}

export interface UseAPIState<R extends {} = {}> {
  isLoading: boolean;
  error: any;
  data: R | null;
}

const initialState: UseAPIState = {
  isLoading: false,
  error: null,
  data: null,
};

const CACHE_SCOPE = "api";

const useAPI = <P, R>(
  request: (payload?: P, options?: APIRequestOptions) => Promise<R>,
  options: UseAPIOptions<P> = {},
  deps: any[] = []
) => {
  const mountedRef = useRef(true);
  const autoStart = options?.autoStart ?? true;

  const cacheContext = useCacheContext();

  const currentInitialState = useMemo(() => {
    let cache = options.cache
      ? cacheContext.getCache(CACHE_SCOPE, options.cache.id)
      : null;

    if (cache) {
      return cache;
    } else if (autoStart) {
      return { ...initialState, isLoading: true };
    } else {
      return initialState;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState<UseAPIState<R>>(
    currentInitialState as UseAPIState<R>
  );

  const fetch = useCallback(
    async (payload: P | boolean) => {
      try {
        let response: R;
        setState((state) => {
          return { ...state, isLoading: true, error: null };
        });
        if (typeof payload === "boolean") {
          response = await request(undefined, {
            toastifyError: options.toastifyError,
          });
        } else {
          response = await request(payload, {
            toastifyError: options.toastifyError,
          });
        }
        if (!mountedRef.current) return;
        setState((state) => {
          return { ...state, isLoading: false, data: response };
        });
      } catch (err) {
        console.error(err);
        if (!mountedRef.current) return;
        setState((state) => {
          return { ...state, isLoading: false, error: serializeError(err) };
        });
      }
    },
    [options.toastifyError, request]
  );

  useLayoutEffect(() => {
    const cache = options.cache
      ? cacheContext.getCache(CACHE_SCOPE, options.cache.id)
      : null;
    if (autoStart && !cache) {
      fetch(autoStart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  useLayoutEffect(() => {
    if (options.cache && !state.isLoading && !state.error) {
      cacheContext.setCache(
        CACHE_SCOPE,
        options.cache.id,
        options.cache.keepOn,
        state
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return [state, fetch] as [typeof state, typeof fetch];
};

export default useAPI;
