import Url from "domurl";
import persistentCompanyId from "../features/Companies/persistentCompanyId";

export const addQuery = (query: Record<string, any>) => {
  const url = new Url(window.location.href);
  for (const queryName in query) {
    url.query[queryName] = query[queryName];
  }
  return url.path + "?" + url.query.toString();
};

export const setUrlWithCompanyId = (path: string) => {
  const orgId = persistentCompanyId.getValue();
  return addQueryToUrl(path, { o: orgId });
};

export const addQueryToUrl = (fullUrl: string, query: Record<string, any>) => {
  const url = new Url(fullUrl);
  for (const queryName in query) {
    url.query[queryName] = query[queryName];
  }
  return url.path + "?" + url.query.toString();
};

export const removeQueryParam = (fullUrl: string, paramName: string) => {
  const url = new Url(fullUrl);
  delete url.query[paramName];
  return url.path + "?" + url.query.toString();
};

const createPath = (path: string, values: Record<any, any>) => {
  for (const property in values) {
    path = path.replace(":" + property, values[property]);
  }
  return path;
};

export const getQuery = (url: string) => {
  const parsedUrl = new Url<any>(url);
  return parsedUrl.query;
};

export default createPath;
