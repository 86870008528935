let __modalSetterAdd;
let __modalSetterRemove;
export const __addModalSetter = ({ addModal, removeModal }) => {
  __modalSetterAdd = addModal;
  __modalSetterRemove = removeModal;
};

class ModalRegister {
  public addModal(modal: { id; children }) {
    if (__modalSetterAdd) {
      __modalSetterAdd(modal);
    }
  }

  public removeModal(modalId) {
    if (__modalSetterRemove) {
      __modalSetterRemove(modalId);
    }
  }
}

const modalRegister = new ModalRegister();

export default modalRegister;
