import { useCallback } from "react";
import { useAppDispatch } from "../../../app/redux/reduxStore";
import { closeModal } from "../reducer";
import useModal from "./useModal";

const useCloseModal = (modalName?: string, onClose?: (...args: any) => any) => {
  const dispatch = useAppDispatch();

  const modalContext = useModal();

  const finalModalName = modalName || modalContext?.modalName;

  const close = useCallback(
    (...args: any) => {
      if (typeof onClose === "function") {
        onClose(...args);
      }
      dispatch(closeModal({ modalName: finalModalName }));
    },
    [dispatch, finalModalName, onClose]
  );

  return close;
};

export default useCloseModal;
