import styled from "styled-components";
import { Icon } from "@adv-libs/icons";

interface IFloatingButton {
  onClick?: () => {};
}

function FloatingButton(props: IFloatingButton) {
  return (
    <ButtonStyle {...props}>
      <Icon className="floating-button-icon" src="/icons/icon-add.svg" />
    </ButtonStyle>
  );
}

export default FloatingButton;

const ButtonStyle = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 56px;
  height: 56px;
  border-radius: 16px;
  background-color: #00b373;
  color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);

  .floating-button-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      height: 2em;
      width: 2em;
    }
  }
`;
