import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvSelect2,
  AdvSelectProps2,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormSelectFieldProps {
  name: string;
  submitFieldName?: string;
  size?: any;
}

const FormSelectField: React.FC<
  FormSelectFieldProps &
    Omit<AdvSelectProps2, "multiple" | "onCommit" | "value">
> = (props) => {
  const { name, size, submitFieldName, ...restProps } = props;

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  const formIsSubmitting = useFormIsSubmitting();

  return (
    <AdvFormField
      fieldName={name}
      submitFieldName={submitFieldName}
      control={AdvSelect2}
      controlProps={restProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
    />
  );
};

export default React.memo(FormSelectField);
