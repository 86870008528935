import { Icon } from "@adv-libs/icons";
import { AdvStaticStatus } from "@adv-libs/r365-ui";
import initEvents from "./features/Events/initEvents";

Icon.setUrl(
  window.location.protocol +
    "//" +
    window.location.host +
    process.env.REACT_APP_ICONS_URL
);

Icon.setDisabled(process.env.NODE_ENV === "test");

AdvStaticStatus.addTemplate("default", {
  1: { color: "#6F6006", background: "#F7E28E" },
  2: { color: "#0D805F", background: "#CCEBCD" },
  3: { color: "#B30000", background: "#F6DDDD" },
});

initEvents();
