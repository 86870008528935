import i18next, { TOptions } from "i18next";

const i18n = {
  t: function(key: string, options?: TOptions) {
    return i18next.t(key, options);
  },
  nt: function(key: string, plural: string, count: number, options?: TOptions) {
    return i18next.t(key, {
      ...options,
      count,
      defaultValue_zero: plural,
      defaultValue_one: key,
      defaultValue_two: plural,
      defaultValue_few: plural,
      defaultValue_many: plural,
      defaultValue_other: plural
    });
  },
  pt: function(context: string, key: string, options?: TOptions) {
    return i18next.t(key, { ...options, context });
  },
  npt: function(context: string, key: string, plural: string, count: number, options?: TOptions) {
    return i18next.t(key, {
      ...options,
      count,
      context,
      defaultValue_zero: plural,
      defaultValue_one: key,
      defaultValue_two: plural,
      defaultValue_few: plural,
      defaultValue_many: plural,
      defaultValue_other: plural
    });
  }
};

export default i18n;
