import React from "react";
import { useRouteMatch } from "react-router-dom";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import MyTaskView from "./MyTaskView";

export interface MyTaskApproveProps {}

const MyTaskApprove: React.FC<MyTaskApproveProps> = (props) => {
  const {
    params: { id: taskId },
  } = useRouteMatch<any>();

  return (
    <>
      <ScrollRestoration id="task" restoreTop />
      <MyTaskView taskId={taskId} approve />
    </>
  );
};

export default MyTaskApprove;
