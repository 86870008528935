const createKey = (storageKey: string, subkey?: string) => {
  return storageKey + (subkey ? "-" + subkey : "");
};

const createPersistentStorage = <T = any>(
  storageKey: string,
  type: "integer" | "float" | "object" | "string"
) => {
  const setValue = (value: T, subkey?: string) => {
    try {
      let serializedValue: any = value;
      if (type === "integer" || type === "float") {
        serializedValue = String(value);
      } else if (type === "object") {
        serializedValue = JSON.stringify(value);
      } else if (type === "string") {
        serializedValue = value;
      }
      localStorage.setItem(createKey(storageKey, subkey), serializedValue);
    } catch (err) {
      console.warn(err);
    }
  };

  const unserializeValue = (unserializedValue) => {
    try {
      if (unserializedValue === null) return null;
      if (type === "integer") {
        return parseInt(unserializedValue);
      } else if (type === "float") {
        return parseFloat(unserializedValue);
      } else if (type === "object") {
        return JSON.parse(unserializedValue);
      } else if (type === "string") {
        return String(unserializedValue);
      } else {
        return unserializedValue;
      }
    } catch (err) {
      console.warn(err);
      return null;
    }
  };

  const getValue = (subkey?: string): T => {
    const unserializedValue: string | null = localStorage.getItem(
      createKey(storageKey, subkey)
    );
    return unserializeValue(unserializedValue);
  };

  const clearValue = (subkey?: string) => {
    try {
      localStorage.removeItem(createKey(storageKey, subkey));
    } catch (err) {
      console.warn(err);
    }
  };

  const getAll = (startsWithSubKey?: string): Record<string, T> => {
    const keyToFind = createKey(storageKey, startsWithSubKey);
    const keys = Object.keys(localStorage);
    const filteredKeys = keys.filter((keyName) =>
      keyName.startsWith(keyToFind)
    );
    return filteredKeys.reduce((result, keyName) => {
      const unserializedValue: string | null = localStorage.getItem(keyName);
      const strictKeyName = keyName.replace(keyToFind, "");
      result[strictKeyName] = unserializeValue(unserializedValue);
      return result;
    }, {});
  };

  const clearAll = (startsWithSubKey?: string) => {
    if (startsWithSubKey) {
      const keyToFind = createKey(storageKey, startsWithSubKey);
      const keys = Object.keys(localStorage);
      const filteredKeys = keys.filter((keyName) =>
        keyName.startsWith(keyToFind)
      );
      for (const key of filteredKeys) {
        localStorage.removeItem(key);
      }
    } else {
      localStorage.removeItem(storageKey);
      const keys = Object.keys(localStorage);
      const filteredKeys = keys.filter((keyName) =>
        keyName.startsWith(storageKey + "-")
      );
      for (const key of filteredKeys) {
        localStorage.removeItem(key);
      }
    }
  };

  return { setValue, getValue, clearValue, getAll, clearAll };
};

export default createPersistentStorage;
