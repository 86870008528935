import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  ROUTE_FORMS,
  ROUTE_USER_SETTINGS,
  ROUTE_MAIN,
} from "../../../app/routes";
import useI18n from "../../../hooks/useI18n";
import { setUrlWithCompanyId } from "../../../utils/path";
import { Button } from "@adv-libs/r365-ui";
import { getHistory } from "../../../features/Router/history";
import { useCompanyContext } from "../../../features/Companies/CompanyContext";
import { useIsMobile } from "@adv-libs/utils";
import { minScreenLength } from "../../../style/theme";

export interface SettingsMenuProps {}

const BackButtonIcon = ["arrow-left", 2174];

const SettingsMenu: React.FC<SettingsMenuProps> = (props) => {
  const { t } = useI18n();
  const companyContext = useCompanyContext();
  const isGamaAdmin = useMemo(() => {
    return companyContext.user?.lygis === "9";
  }, [companyContext.user]);
  const isMobile = useIsMobile(minScreenLength);

  return (
    <SettingsMenuStyled isMobile={isMobile}>
      <HeaderStyled isMobile={isMobile}>
        <Button
          onClick={() =>
            getHistory().push(setUrlWithCompanyId(ROUTE_MAIN.path))
          }
          icon={BackButtonIcon}
          gray
        ></Button>
        <span className="nav-title">{t("Settings")}</span>
      </HeaderStyled>

      <MenuItem isMobile={isMobile}>
        <NavLink to={setUrlWithCompanyId(ROUTE_USER_SETTINGS.path)}>
          <span className="nav-item-title">{t("Profile settings")}</span>
        </NavLink>
      </MenuItem>

      {isGamaAdmin && (
        <MenuItem isMobile={isMobile}>
          <NavLink to={setUrlWithCompanyId(ROUTE_FORMS.path)}>
            <span className="nav-item-title">{t("Document forms")}</span>
          </NavLink>
        </MenuItem>
      )}
    </SettingsMenuStyled>
  );
};

export const SettingsMenuStyled = styled.div<{ isMobile?: boolean }>`
  flex-direction: column;
  gap: 4px;

  ${({ isMobile }) => (isMobile ? "display: flex;gap:8.5px;" : null)}

  height: 100%;
  padding: 0px 16px;
  padding-top: 24px;
  padding-bottom: 30px;

  background: white;
  border-right: 1px solid rgb(234, 234, 234);
`;

const HeaderStyled = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 12px;

  .nav-title {
    padding: 10px;
    ${({ isMobile }) => (isMobile ? "font-size: 18px;font-weight: 500;" : null)}
  }
`;

const MenuItem = styled.div<{ disabled?: boolean; isMobile?: boolean }>`
  a {
    ${(props) =>
      props.disabled &&
      `pointer-events: none;

    `}
    display: block;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;

    color: ${(props) =>
      props.disabled ? props.theme.disabledColor : `rgb(41, 46, 58)`};
    text-decoration: none;
    border-radius: 6px;
    font-weight: 600;

    ${({ isMobile }) => (isMobile ? "font-size: 16px;font-weight: 500;" : null)}

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .r365-icon {
      font-size: 20px;
      margin-right: 8px;
    }

    transition: background 0.05s linear, color 0.05s linear;

    &:hover {
      background: rgb(241, 241, 241);
    }

    &.active {
      background: rgba(0, 179, 115, 0.1);
      color: rgb(13, 128, 95);
    }
  }
`;

export default SettingsMenu;
