import { setUrlWithCompanyId } from "../../utils/path";
import hasPreviousLocation from "./hasPreviousLocation";
import { getHistory } from "./history";

const goSafeBack = (fallbackUrl: string) => {
  if (hasPreviousLocation()) {
    getHistory().goBack();
  } else {
    // Might be changed with replace
    getHistory().push(setUrlWithCompanyId(fallbackUrl));
  }
};

export const createGoSafeBack = (fallbackUrl: string) => {
  return () => goSafeBack(fallbackUrl);
};

export default goSafeBack;
