enum Modules {
  BUSINESS_TRIP = "KO",
  CALENDAR = "KAL",
  TASKS = "TV",
  VACATIONS = "AT",
  DOCUMENTS = "OD",
  VACATION_REQUEST_BTN = "ATPR",
}

export default Modules;
