import { CalendarEventType, CalendarGroupType } from "@adv-libs/calendar";
import { assureArray } from "@adv-libs/utils";
import i18n from "i18next";
import { DataViewSchemaType } from "../components/DataView/types";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import {
  ApproveHistoryAPIResponse,
  ApproveHistoryAPIResponseOriginal,
} from "./types";
import createAPIHandler from "./utils/createAPIHandler";

export type GetCalendarDataAPIPayload = {
  clientDbId: string;
  user: string;
  from?: string;
  to?: string;
  filterXML?: string;
};

export type GetCalendarDataAPIResponeOriginal = {
  app: {
    events: {
      r: {
        id: string;
        link: string;
        group_id: string;
        endDate: string;
        startDate: string;
        title: string;
        title_m: string;
        color: string;
        mask: string;
      }[];
    };
    groups: {
      r: {
        id: string;
        title: string;
        apr1: string;
        apr2: string;
      };
    };
  };
};

export type GetCalendarDataAPIResponse = {
  events: CalendarEventType[];
  groups: CalendarGroupType[];
};

export type getCalendarEventDetailAPIPayload = {
  clientDbId: string;
  user: string;
  id: string;
};

export type GetCalendarEventDetailAPIResponseOriginal = {
  data: {
    config: {
      r:
        | {
            key: string;
            value: string;
          }
        | {
            key: string;
            value: string;
          }[];
    };
    data: {
      schema: DataViewSchemaType;
    };
  };
};

export type GetCalendarEventDetailAPIResponse = {
  schema: DataViewSchemaType;
  showHistory: boolean;
};

export type GetCalendarEventDetailHistoryAPIResponseOriginal =
  ApproveHistoryAPIResponseOriginal;

export type GetCalendarEventDetailHistoryAPIResponse =
  ApproveHistoryAPIResponse;

// const colorSchema = {
//   D: "#1dee1d",
//   S: "#bbbbff",
//   P: "orange",
// };

const CalendarAPIRequest = () => {
  return {
    getCalendarData: createAPIHandler(
      async (
        payload: GetCalendarDataAPIPayload
      ): Promise<GetCalendarDataAPIResponse> => {
        const response = await Client.post<GetCalendarDataAPIResponeOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_PERS_KAL,
            clientDbId: payload.clientDbId,
            data: {
              user: payload.user,
              from: payload.from,
              to: payload.to,
              filter_xml: payload.filterXML,
              lang: i18n.language,
            },
          }
        );

        const events = assureArray(response.data?.app?.events?.r);
        const groups = assureArray(response.data?.app?.groups?.r);

        const mappedEvents = events.map((event) => {
          return {
            id: event.id,
            link: event.link,
            groupId: event.group_id,
            title: event.title,
            title_m: event.title_m,
            color: event.color,
            mask: event.mask === "1",
            startDate: new Date(event.startDate + "T00:00:00"),
            endDate: new Date(event.endDate + "T00:00:00"),
          };
        });
        return {
          events: mappedEvents,
          groups: groups,
        };
      }
    ),
    getCalendarEventDetail: createAPIHandler(
      async (
        payload: getCalendarEventDetailAPIPayload
      ): Promise<GetCalendarEventDetailAPIResponse> => {
        const response =
          await Client.post<GetCalendarEventDetailAPIResponseOriginal>(
            "/client/application",
            {
              procedureName: APIProcedures.RGI_MGAMA_GET_PERS_KAL_INFO,
              clientDbId: payload.clientDbId,
              data: {
                id: payload.id,
                user: payload.user,
                lang: i18n.language,
              },
            }
          );

        const config = assureArray(response.data?.data?.config?.r);
        const showHistoryConfig = config.find(
          (item) => item.key === "SHOW_HISTORY"
        );
        const showHistory = showHistoryConfig?.value === "true" ? true : false;
        const schema = assureArray(response.data?.data?.data?.schema);

        return {
          schema,
          showHistory,
        };
      }
    ),
    getCalendarEventDetailHistory: createAPIHandler(
      async (
        payload: getCalendarEventDetailAPIPayload
      ): Promise<GetCalendarEventDetailHistoryAPIResponse> => {
        const response =
          await Client.post<GetCalendarEventDetailHistoryAPIResponseOriginal>(
            "/client/application",
            {
              procedureName: APIProcedures.RGI_MGAMA_GET_PERS_KAL_INFO,
              clientDbId: payload.clientDbId,
              data: {
                id: payload.id,
                user: payload.user,
                busHistory: 1,
                lang: i18n.language,
              },
            }
          );

        const data = assureArray(response.data?.row?.rez?.busenos);

        return data.map((item) => {
          return {
            comment: item.komentaras,
            date: item.korData,
            result: item.busena,
            statusColor: item.statusasSpalva,
            statusId: item.statusasId,
            statusName: item.statusasPavadinimas,
            user: item.vartotojas,
          };
        });
      }
    ),
    getFilters: createAPIHandler(
      async (payload: GetCalendarDataAPIPayload): Promise<any> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_PERS_KAL,
          clientDbId: payload.clientDbId,
          data: {
            user: payload.user,
            from: payload.from,
            to: payload.to,
            filter_xml: payload.filterXML,
            lang: i18n.language,
            response_type: 5,
          },
        });

        const kodas_4a = assureArray(response.data?.filters?.kodas_4a?.r);
        const kodas_is = assureArray(response.data?.filters?.kodas_is?.r);
        const kodas_k12 = assureArray(response.data?.filters?.kodas_k12?.r);
        const tipas = assureArray(response.data?.filters?.tipas?.r);

        return {
          kodas_4a: kodas_4a.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
              selected: item.f === "1" ? true : false,
            };
          }),
          kodas_is: kodas_is.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
              selected: item.f === "1" ? true : false,
            };
          }),
          kodas_k12: kodas_k12.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
              selected: item.f === "1" ? true : false,
            };
          }),
          tipas: tipas.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
              selected: item.f === "1" ? true : false,
            };
          }),
        };
      }
    ),
  };
};

export default CalendarAPIRequest;
