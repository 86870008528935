import React from "react";
import styled from "styled-components";

export interface NoDataCardProps {}

const NoDataCard: React.FC<NoDataCardProps> = (props) => {
  return <NoDataCardStyled>{props.children}</NoDataCardStyled>;
};

const NoDataCardStyled = styled.div`
  border-radius: 6px;
  background: ${({ theme }) => theme.greyColor};
  padding: 24px 3px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #737373;
  margin-bottom: 10px;
`;

export default NoDataCard;
