import styled from "styled-components";

interface IInfoField {
  label?: string;
  data?: string | number;
}

function InfoField(props: IInfoField) {
  const { label, data } = props;
  return (
    <InfoFieldContainer>
      <div className="label">{label}</div>
      <div className="data">{data}</div>
    </InfoFieldContainer>
  );
}

export default InfoField;

const InfoFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.fontColorFaded};
  }

  .data {
    font-size: 0.875rem;
    font-weight: normal;
  }
`;
