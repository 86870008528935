import styled from "styled-components";

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 24px;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    gap: 48px;
  }
`;
