import React from "react";
import styled from "styled-components";
import PageTitle from "../../components/PageTitle";
import useI18n from "../../hooks/useI18n";
import { Logo } from "../../logo";
import { Languages, LoginFooter, LoginForm } from "./components";

function Auth() {
  const { t } = useI18n();

  return (
    <>
      <PageTitle>{t("Login")}</PageTitle>
      <Container>
        <Item>
          <LoginContainer>
            <LoginForm />
            <Languages />
            <LoginFooter />
          </LoginContainer>
        </Item>

        <Item green>
          <LogoContainer>
            <LogoImage>
              <Logo width="100px" />
            </LogoImage>
          </LogoContainer>
        </Item>
      </Container>
    </>
  );
}

export default React.memo(Auth);

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-width: 280px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    flex-direction: row;
    justify-content: center;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    min-height: 600px;
    height: 70%;
    max-height: 745px;
  }
`;

const Item = styled.div<{
  green?: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.green ? "20%" : "80%")};
  min-height: ${(props) => (props.green ? "100px" : "400px")};

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    width: 50%;
    max-width: 500px;
    height: 100%;
  }

  background-color: ${(props) =>
    props.green ? props.theme.darkBackground : props.theme.whiteColor};
`;

const LoginContainer = styled.div`
  padding: 5% 15px;
  background-color: ${(props) => props.theme.whiteColor};

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    padding: 14% 10% 5% 10%;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.minScreenLength - 1}px) {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
  }
`;

const LogoContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const LogoImage = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;

export { LogoImage, LogoContainer, LoginContainer, Item, Container };
