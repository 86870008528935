import { assureArray, escapeXML } from "@adv-libs/utils";
import { addQueryToUrl, getQuery } from "../../utils/path";
import { getHistory } from "../Router/history";

export interface PutXMLFilterToUrlOptions {
  filterProperties: string[];
  filterValue: any;
  location: any;
}

export const putXMLFilterToUrl = (options: PutXMLFilterToUrlOptions) => {
  const query = {};
  for (const propertyName of options.filterProperties) {
    const filterValue = options.filterValue[propertyName];
    query[propertyName] = filterValue
      ? assureArray(filterValue).map((item) => item.id)
      : undefined;
  }
  const history = getHistory();
  const url = addQueryToUrl(
    options.location.pathname + options.location.search,
    query
  );
  history.replace(url);
};

export interface GetXMLFilterFromUrlOptions {
  filterProperties: string[];
  location: any;
}

export const getXMLFilterFromUrl = (options: GetXMLFilterFromUrlOptions) => {
  const filterValue = getXMLFilterInitialValuesFromUrl(options);

  const xml = ["<filters>"];
  for (const propertyName of options.filterProperties) {
    const propertyFilterValue = filterValue[propertyName];
    if (propertyFilterValue) {
      xml.push(`<${propertyName}>`);
      assureArray(propertyFilterValue).forEach((id) => {
        xml.push(`<r>`);
        xml.push(`<k>${escapeXML(id)}</k>`);
        xml.push(`</r>`);
      });
      xml.push(`</${propertyName}>`);
    }
  }
  xml.push("</filters>");
  return xml.join("");
};

export interface GetXMLFilterInitialValuesFromUrl {
  filterProperties: string[];
  location: any;
}

export const getXMLFilterInitialValuesFromUrl = (
  options: GetXMLFilterInitialValuesFromUrl
) => {
  const query = getQuery(options.location.pathname + options.location.search);

  const filterValue = {};

  for (const propertyName of options.filterProperties) {
    const propertyFilterValue = query[propertyName];
    filterValue[propertyName] = propertyFilterValue
      ? assureArray(propertyFilterValue)
      : undefined;
  }

  return filterValue;
};
