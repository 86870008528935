import { useMemo } from "react";
import { Icon } from "@adv-libs/icons";
import { Button, Menu, MenuListItem } from "@adv-libs/r365-ui";
import { useIsMobile } from "@adv-libs/utils";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ROUTE_CALENDAR,
  ROUTE_LOGOUT,
  ROUTE_MAIN,
  ROUTE_TASKS,
  ROUTE_VACATIONS,
  ROUTE_BUSINESS_TRIPS,
  ROUTE_DOCUMENTS,
  ROUTE_SETTINGS,
} from "../../../app/routes";
import DropdownWithBack from "../../../components/DropdownWithBack/DropdownWithBack";
import { useCompanyContext } from "../../../features/Companies/CompanyContext";
import CompanyHeader from "../../../features/Companies/CompanyHeader/CompanyHeader";
import { waitForModalClose } from "../../../features/PopState/useResetHistory";
import { getHistory } from "../../../features/Router/history";
import useI18n from "../../../hooks/useI18n";
import { Logo } from "../../../logo";
import { minScreenLength } from "../../../style/theme";
import { setUrlWithCompanyId } from "../../../utils/path";
import UserImage from "./components/UserImage";
import { useModulesContext } from "../../../features/ModulesList/ModulesContext";
import Modules from "../../../features/ModulesList/Modules";
import toastInfo from "../../../features/ToastMessage/toastInfo";

const createMenuClickHandler = (
  link: string,
  disabled?: boolean,
  message?: string
) => {
  if (disabled && message) {
    return () => toastInfo(message);
  } else if (disabled) {
    return () => null;
  } else
    return async () => {
      await waitForModalClose();
      getHistory().push(link);
    };
};

const HeaderMenu: React.FC = () => {
  const { t } = useI18n();

  const companyContext = useCompanyContext();
  const modulesContext = useModulesContext();

  const isMobile = useIsMobile(minScreenLength);

  const modules = useMemo(() => {
    if (modulesContext) {
      return {
        business: modulesContext.find(
          (item) => item.module === Modules.BUSINESS_TRIP
        ) || { show: false, disabled: true, errorMessage: "" },
        calendar: modulesContext.find(
          (item) => item.module === Modules.CALENDAR
        ) || { show: false, disabled: true, errorMessage: "" },
        vacation: modulesContext.find(
          (item) => item.module === Modules.VACATIONS
        ) || { show: false, disabled: true, errorMessage: "" },
        tasks: modulesContext.find((item) => item.module === Modules.TASKS) || {
          show: false,
          disabled: true,
          errorMessage: "",
        },
        documents: modulesContext.find(
          (item) => item.module === Modules.DOCUMENTS
        ) || {
          show: false,
          disabled: true,
          errorMessage: "",
        },
      };
    }
    return null;
  }, [modulesContext]);

  if (!companyContext) return null;

  const fullName = [companyContext.user.vardas, companyContext.user.pavarde]
    .filter(Boolean)
    .join(" ");

  return (
    <HeaderMenuStyled>
      <CompanyHeader />
      <MenuBar>
        <LeftSide>
          <Link to={setUrlWithCompanyId(ROUTE_MAIN.path)}>
            <Logo black small />
          </Link>
        </LeftSide>

        {isMobile ? (
          <RightSide>
            <DropdownWithBack>
              <ProfileContainer>
                <UserImage name={fullName} />
                <Icon icon={"caret-down"} />
              </ProfileContainer>
              <Menu>
                <MenuListItem>
                  <Button
                    iconSrc="/icons/icon-nav-dashboard.svg"
                    onClick={createMenuClickHandler(
                      setUrlWithCompanyId(ROUTE_MAIN.path)
                    )}
                  >
                    {t("Dashboard")}
                  </Button>
                </MenuListItem>
                {modules.tasks?.show && (
                  <MenuListItem>
                    <Button
                      iconSrc="/icons/icon-nav-tasks.svg"
                      onClick={createMenuClickHandler(
                        setUrlWithCompanyId(ROUTE_TASKS.path),
                        modules.tasks.disabled,
                        modules.tasks.errorMessage
                      )}
                      className={
                        modules.tasks.disabled ? "r365-button--disabled" : null
                      }
                    >
                      {t("Approvals")}
                    </Button>
                  </MenuListItem>
                )}
                {modules.vacation?.show && (
                  <MenuListItem>
                    <Button
                      iconSrc="/icons/icon-nav-vacations.svg"
                      onClick={createMenuClickHandler(
                        setUrlWithCompanyId(ROUTE_VACATIONS.path),
                        modules.vacation.disabled,
                        modules.vacation.errorMessage
                      )}
                      className={
                        modules.vacation.disabled
                          ? "r365-button--disabled"
                          : null
                      }
                    >
                      {t("Vacations")}
                    </Button>
                  </MenuListItem>
                )}
                {modules.business?.show && (
                  <MenuListItem>
                    <Button
                      iconSrc="/icons/icon-nav-business-trip.svg"
                      onClick={createMenuClickHandler(
                        setUrlWithCompanyId(ROUTE_BUSINESS_TRIPS.path),
                        modules.business.disabled,
                        modules.business.errorMessage
                      )}
                      className={
                        modules.business.disabled
                          ? "r365-button--disabled"
                          : null
                      }
                    >
                      {t("Business trips")}
                    </Button>
                  </MenuListItem>
                )}
                {modules.calendar?.show && (
                  <MenuListItem>
                    <Button
                      iconSrc="/icons/icon-nav-calendar.svg"
                      onClick={createMenuClickHandler(
                        setUrlWithCompanyId(ROUTE_CALENDAR.path),
                        modules.calendar.disabled,
                        modules.calendar.errorMessage
                      )}
                      className={
                        modules.calendar.disabled
                          ? "r365-button--disabled"
                          : null
                      }
                    >
                      {t("Calendar")}
                    </Button>
                  </MenuListItem>
                )}
                {modules.documents?.show && (
                  <MenuListItem>
                    <Button
                      iconSrc="/icons/icon-nav-documents.svg"
                      onClick={createMenuClickHandler(
                        setUrlWithCompanyId(ROUTE_DOCUMENTS.path),
                        modules.documents.disabled,
                        modules.documents.errorMessage
                      )}
                      className={
                        modules.documents.disabled
                          ? "r365-button--disabled"
                          : null
                      }
                    >
                      {t("Documents")}
                    </Button>
                  </MenuListItem>
                )}
                <MenuListItem>
                  <Button
                    iconSrc="/icons/icon-nav-settings2.svg"
                    onClick={createMenuClickHandler(ROUTE_SETTINGS.path)}
                  >
                    {t("Settings")}
                  </Button>
                </MenuListItem>

                <MenuListItem>
                  <Button
                    icon="logout"
                    onClick={createMenuClickHandler(ROUTE_LOGOUT.path)}
                  >
                    {t("Logout")}
                  </Button>
                </MenuListItem>
              </Menu>
            </DropdownWithBack>
          </RightSide>
        ) : null}
      </MenuBar>
    </HeaderMenuStyled>
  );
};

export default HeaderMenu;

const LeftSide = styled.div`
  .logo {
    padding-top: 5px;
    max-width: 50px;
    height: auto;
  }
`;

const ProfileContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  .r365-icon {
    font-size: 10px;
    margin-left: 6px;
  }
`;

const RightSide = styled.div`
  .r365-menu {
    width: 100vw;
    height: 100vh;
    max-height: none;
    border-radius: 0px;
    border-top: 2px solid #eaeaea;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .r365-menu-list-item {
      padding: 0 16px;

      .r365-button__title-text {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0;
      }

      .r365-icon {
        font-size: 24px;
      }
    }
  }
`;

const MenuBar = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.headerBackground};
  height: ${({ theme }) => theme.headerHeight}px;
  padding: 0 16px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  z-index: 200;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    z-index: 100;
  }
`;

const HeaderMenuStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
`;
