import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import {
  JobPositionListAPIResponseItem,
  VacationsListAPIResponse,
} from "../../api/vacationsAPI";
import { ROUTE_VACATION, ROUTE_VACATIONS } from "../../app/routes";
import InfiniteCard from "../../components/InfiniteCard/InfiniteCard";
import NoDataCard from "../../components/NoDataCard";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import useI18n from "../../hooks/useI18n";
import VacationCard from "./components/VacationCard";

export interface MyVacationsHistoryProps {
  jobPosition: JobPositionListAPIResponseItem;
}

const MyVacationsHistory: React.FC<MyVacationsHistoryProps> = (props) => {
  const companyContext = useCompanyContext();
  const { t } = useI18n();

  const cacheKeepOn = useMemo(() => {
    return [ROUTE_VACATIONS.path, ROUTE_VACATION.path];
  }, []);

  const request = useCallback(
    async (page: number) => {
      return await API.vacations.list({
        clientDbId: companyContext.company.clientDbId,
        page: page,
        size: 10,
        jobPosition: props.jobPosition?.id,
      });
    },
    [companyContext.company.clientDbId, props.jobPosition]
  );

  if (!props.jobPosition) return null;

  return (
    <MyVacationsHistoryStyled>
      <InfiniteCard
        id="vacations-history"
        showMore
        keepOn={cacheKeepOn}
        header={t("Vacation history")}
        request={request}
        threshold={9}
      >
        {(items: VacationsListAPIResponse) => {
          if (!items.length) {
            return <NoDataCard>{t("No vacation history")}</NoDataCard>;
          }
          return (
            <MyVacationsItems>
              {items.map((item, key) => {
                return <VacationCard past key={key} item={item} showStatus />;
              })}
            </MyVacationsItems>
          );
        }}
      </InfiniteCard>
    </MyVacationsHistoryStyled>
  );
};

const MyVacationsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MyVacationsHistoryStyled = styled.div``;

export default MyVacationsHistory;
