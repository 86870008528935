import React, { useLayoutEffect, useMemo, useState } from "react";
import { JobPositionListAPIResponseItem } from "../../api/vacationsAPI";
import { ROUTE_VACATION, ROUTE_VACATIONS } from "../../app/routes";
import Accordion from "../../components/Accordion/Accordion";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import { useCacheContext } from "../../features/Cache/CacheContext";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import MyVacationsHistory from "./MyVacationsHistory";
import MyVacationsSelector from "./MyVacationsSelector";

export interface MyVacationsProps {}

const CACHE_SCOPE = "vacations";

const MyVacations: React.FC<MyVacationsProps> = (props) => {
  const cacheContext = useCacheContext();
  const { t } = useI18n();

  const [jobPosition, setJobPosition] =
    useState<JobPositionListAPIResponseItem>(
      cacheContext.getCache(CACHE_SCOPE, "job-position")
    );

  const keepOn = useMemo(() => {
    return [ROUTE_VACATIONS.path, ROUTE_VACATION.path];
  }, []);

  useLayoutEffect(() => {
    cacheContext.setCache(CACHE_SCOPE, "job-position", keepOn, jobPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPosition]);

  return (
    <>
      <PageTitle>{t("Vacations")}</PageTitle>
      <ScrollRestoration id="vacations" keepOn={keepOn} />
      <Grid>
        <GridCol col-s="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
          <PageContent>
            <Accordion
              title={t("Vacations")}
              iconSrc="/icons/icon-nav-vacations.svg"
              showBackButton
              expandedByDefault
              disabled
              minHeight={200}
            >
              <MyVacationsSelector
                jobPosition={jobPosition}
                onJobPositionChanged={setJobPosition}
              />
            </Accordion>
            <MyVacationsHistory jobPosition={jobPosition} />
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

export default MyVacations;
