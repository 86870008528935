import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import breakpoints from "../../style/breakpoints";

export interface AccordionFooterButtonProps {
  onClick?: (e) => any;
  to?: string;
}

const AccordionFooterButton: React.FC<AccordionFooterButtonProps> = (props) => {
  return (
    <AccordionFooterButtonStyled>
      <Link onClick={props.onClick} to={props.to}>
        {props.children}
      </Link>
    </AccordionFooterButtonStyled>
  );
};

const AccordionFooterButtonStyled = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 0;

  a {
    text-decoration: none;
    color: black;
    padding: 6px 20px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    border-radius: 6px;
    transition: opacity 0.1s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  padding-left: 8px;
  padding-right: 8px;

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default AccordionFooterButton;
