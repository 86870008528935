import { useCallback, useState, useEffect } from "react";
import { createJexl } from "@adv-libs/utils";
import { Jexl } from "@adv-libs/jexl";

export type UseJexlCompilatorType = {
  name: string;
  attr: string;
  jexl: string;
}[];

export type useJexlResultType = {
  compileExpressions: (expressions: UseJexlCompilatorType) => any;
  evaluateExpr: (
    field: string,
    attribute: string,
    context: { [key: string]: any }
  ) => any;
  error: any;
};

export default function useJexl(): useJexlResultType {
  const [jexl, setJexl] = useState<Jexl>();
  const [compiledExpr, setCompiledExpr] =
    useState<{ name: string; attr: string; expr: any }[]>();
  const [error, setError] = useState();

  useEffect(() => {
    const jexlInstance = createJexl();
    setJexl(jexlInstance);
  }, []);

  useEffect(() => {
    if (jexl) {
      jexl.addTransform("split", (val, char) => val.split(char));
      jexl.addTransform("slice", (val, startIndex, endIndex) =>
        val.slice(startIndex, endIndex)
      );
      jexl.addTransform("lower", (val) => val.toLowerCase());
      jexl.addFunction("today", () => new Date().toJSON().slice(0, 10));
    }
  }, [jexl]);

  const compileExpressions = useCallback(
    (expressions: UseJexlCompilatorType) => {
      try {
        const exprObject = expressions.map((item) => {
          const expr = jexl.compile(item.jexl);
          return { name: item.name, attr: item.attr, expr: expr };
        });
        setCompiledExpr(exprObject);
      } catch (err) {
        setError(err);
      }
    },
    [jexl]
  );

  const evaluateExpr = useCallback(
    (fieldName, attribute, context) => {
      try {
        const field =
          compiledExpr &&
          compiledExpr.find(
            (el) => el.name === fieldName && el.attr === attribute
          );

        if (field) {
          const expr = field.expr;
          const result = expr.evalSync(context);
          return result;
        }
        return;
      } catch (err) {
        setError(err);
        return;
      }
    },
    [compiledExpr]
  );

  return { compileExpressions, evaluateExpr, error };
}
