import React from "react";
import { LogoGenerator } from "../../../../components";

export interface UserImageProps {
  name: string;
}

const UserImage: React.FC<UserImageProps> = (props) => {
  return <LogoGenerator name={props.name} />;
};

export default UserImage;
