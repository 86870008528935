import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import i18next from "i18next";
import actionLogout from "../../actions/actionLogout";
import refreshToken, {
  isJustRefreshed,
  isTokenExpired,
  onSuccessedRequest,
} from "../../features/AuthToken/AuthToken";
import { getHistory } from "../../features/Router/history";
import convertToString from "../../utils/convertToString";

const API_URI =
  window.__ENV.REACT_APP_API === undefined ? "" : window.__ENV.REACT_APP_API;
const API_TIMEOUT =
  window.__ENV.REACT_APP_API_TIMEOUT_MS === undefined
    ? 10000
    : parseInt(window.__ENV.REACT_APP_API_TIMEOUT_MS);

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URI,
  timeout: API_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
};

const Client: AxiosInstance = axios.create(axiosConfig);

let initialPageLoad = true;

Client.interceptors.request.use(async function (request) {
  request.headers["Accept-Language"] = i18next.language;

  request.withCredentials = true;

  if (request?.url?.endsWith?.("/auth/login")) {
    return request;
  }

  if (request?.url?.endsWith?.("/auth/refresh")) {
    return request;
  }

  if (request?.url?.endsWith?.("/auth/logout")) {
    return request;
  }

  const needTokenRefresh = isTokenExpired() || initialPageLoad;
  if (needTokenRefresh) {
    try {
      await refreshToken();
      initialPageLoad = false;
      return request;
    } catch (err) {
      console.error(err);
      const error = new Error("Failed to refresh access token before request");
      (error as any).isAxiosError = true;
      (error as any).response = {
        status: 401,
      };
      throw error;
    }
  }

  return request;
}, Promise.reject);

Client.interceptors.response.use(
  function (response) {
    onSuccessedRequest();
    if (response.data) {
      return {
        ...response,
        data: convertToString(response.data),
      };
    } else {
      return response;
    }
  },
  async function (err) {
    if (err.isAxiosError && err.response && err.response.status === 401) {
      if (err.request?.responseURL?.endsWith?.("/auth/login")) {
        return Promise.reject(err);
      }

      if (err.request?.responseURL?.endsWith?.("/auth/refresh")) {
        return Promise.reject(err);
      }

      if (err.request?.responseURL?.endsWith?.("/auth/logout")) {
        return Promise.reject(err);
      }

      try {
        if (isJustRefreshed()) {
          throw err;
        }
        const result = await refreshToken();
        if (result) {
          return Client.request(err.config);
        }
        // eslint-disable-next-line no-empty
      } catch (err) {
        console.error(err);
        actionLogout(getHistory(), err);
        return Promise.reject(err);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export default Client;
