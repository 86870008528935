import React, { useImperativeHandle, useMemo } from "react";
import styled from "styled-components";
import useAPI from "../../../api/hooks/useAPI";
import {
  APIRequestOptions,
  ApproveHistoryAPIPayload,
  ApproveHistoryAPIResponse,
} from "../../../api/types";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import NoDataCard from "../../../components/NoDataCard";
import StateLoader from "../../../components/StateLoader";
import useI18n from "../../../hooks/useI18n";
import MyApproveHistoryCard from "./components/ApproveHistoryCard";

export interface MyApproveHistoryProps {
  id: any;
  clientDbId: string;
  user: string;
  formId?: string;
  lang?: string;
  request: (
    payload?: ApproveHistoryAPIPayload,
    options?: APIRequestOptions
  ) => Promise<ApproveHistoryAPIResponse>;
}

export interface MyApproveHistoryInstance {
  reload: () => Promise<void>;
}

const MyApproveHistory = React.forwardRef<
  MyApproveHistoryInstance,
  React.PropsWithChildren<MyApproveHistoryProps>
>((props, ref) => {
  const { t } = useI18n();

  const apiPayload = useMemo(() => {
    return {
      id: props.id,
      clientDbId: props.clientDbId,
      user: props.user,
      formId: props.formId,
      lang: props.lang,
    };
  }, [props.clientDbId, props.id, props.user, props.lang, props.formId]);

  const [state, fetch] = useAPI(
    props.request,
    {
      autoStart: apiPayload,
    },
    [apiPayload, props.request]
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        reload: async () => {
          await fetch(apiPayload);
        },
      };
    },
    [apiPayload, fetch]
  );

  return (
    <Card>
      <CardBody>
        <StateLoader error={state.error} isLoading={state.isLoading}>
          {() => {
            return (
              <>
                <CardHeader>{t("Approval history")}</CardHeader>
                <MyTaskApproveHistoryStyled>
                  {state.data.length ? (
                    state.data.map((item, key) => {
                      return <MyApproveHistoryCard key={key} item={item} />;
                    })
                  ) : (
                    <NoDataCard>{t("No items")}</NoDataCard>
                  )}
                </MyTaskApproveHistoryStyled>
              </>
            );
          }}
        </StateLoader>
      </CardBody>
    </Card>
  );
});

const MyTaskApproveHistoryStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default MyApproveHistory;
