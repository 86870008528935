import styled from "styled-components";
import useI18n from "../../../hooks/useI18n";

const currentYear = new Date().getFullYear();

function LoginFooter() {
  const { t } = useI18n();

  return (
    <Footer>
      <div>
        <h4>
          {t("Need help?")}{" "}
          <a href="https://www.rivile.lt/kontaktai/">{t("Contact us")}</a>
        </h4>
      </div>
      <div>
        <h4>
          {" "}
          {`©1993-${currentYear} UAB "Rivilė" | ${t("All rights reserved")}`}
        </h4>
      </div>
    </Footer>
  );
}

export default LoginFooter;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10%;

  div:first-child {
    margin-bottom: 7%;
  }

  div:nth-child(2) {
    color: #737373;
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: inherit;
    color: #00b373;
  }
`;
