import Client from "./core/Client";
import APIProcedures from "./Procedures";
import createAPIHandler from "./utils/createAPIHandler";

export type GetFileAPIPayload = {
  id: string;
  clientDbId: string;
};

export type GetFileAPIResponse = {
  content: string;
  link: string;
};

export type GetFileAPIResponseOriginal = {
  docs: {
    NUORODA: string;
    DOC: string;
  };
};

const FileAPIRequest = () => {
  return {
    getFile: createAPIHandler(
      async (payload: GetFileAPIPayload): Promise<GetFileAPIResponse> => {
        const response = await Client.post<GetFileAPIResponseOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_DOC,
            clientDbId: payload.clientDbId,
            data: {
              Id: payload.id,
            },
          }
        );

        return {
          content: response.data.docs.DOC,
          link: response.data.docs.NUORODA,
        };
      }
    ),
  };
};

export default FileAPIRequest;
