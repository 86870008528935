import styled from "styled-components";
import breakpoints from "../../style/breakpoints";
import { GridContainerStyled, GridStyled } from "../../style/Grid";

const FormActions = styled.div`
  display: flex;
  gap: 14px;

  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  flex-direction: column;
  padding-top: 16px;
  background: white;
  border-top: 1px solid #e5e5e5;

  z-index: 2;

  .r365-button {
    .r365-button__content {
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  .buttons-container {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .r365-button {
      flex-grow: 1;
    }
  }

  .back-button {
    margin-bottom: 16px;
  }

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    position: relative;
    margin-bottom: 0;
    bottom: 0;
    align-items: flex-end;
    background: transparent;
    border-top: none;

    .r365-button {
      min-width: 200px;
    }

    .buttons-container {
      margin-bottom: 0;
      display: inline-flex;
    }

    .back-button {
      display: none;
    }

    ${GridStyled} {
      padding: 0;

      ${GridContainerStyled} {
        display: block;
      }
    }
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
`;

const ViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    .r365-form-field-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const ViewInnerStyled = styled.div`
  /** Layout */
  .top {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 8px;

    .left {
      display: flex;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  @media screen and (min-width: ${breakpoints.xs}px) {
    .top {
      flex-direction: row;
      justify-content: space-between;

      .right {
        .create-date,
        .update-date {
          justify-content: flex-end;
        }
      }
    }
  }

  /** Styles */
  .top {
    .left {
      .dynamic-status {
        .r365-status {
          height: 24px;

          &__label {
            font-weight: 600;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .right {
      .create-date,
      .update-date {
        color: #999999;
        font-size: 10px;
        font-weight: 400;

        display: flex;
        gap: 4px;
      }
    }
  }
`;

const DataViewPageSharedStyles = {
  FormActions,
  Header,
  ViewStyled,
  ViewInnerStyled,
};

export default DataViewPageSharedStyles;
