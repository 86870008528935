import React, { useCallback } from "react";
import useI18n from "../../hooks/useI18n";
import { Button } from "@adv-libs/r365-ui";
import { FloatingButton } from "../../components";
import styled from "styled-components";
import { getHistory } from "../../features/Router/history";
import { setUrlWithCompanyId } from "../../utils/path";
import { ROUTE_DOCUMENTS_REQUEST } from "../../app/routes";
import { openDynamicModal } from "../../features/Modal/openDynamicModal";
import DocumentMenu from "./components/DocumentMenu";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { useIsMobile } from "@adv-libs/utils";
import { minScreenLength } from "../../style/theme";

export interface MyDocumentsFormSelectorProps {}

let id: string | number;

const MyDocumentsFormSelector: React.FC<MyDocumentsFormSelectorProps> = (
  props
) => {
  const { t } = useI18n();

  const companyContext = useCompanyContext();
  const isMobile = useIsMobile(minScreenLength);

  const setFormId = useCallback((formId: string) => {
    id = formId;
  }, []);

  const openModal = useCallback(() => {
    return new Promise(async (resolve) => {
      await openDynamicModal({
        maxWidth: 400,
        props: {
          onClick: setFormId,
          companyId: companyContext.company.clientDbId,
        },
        onClose: (resolveValue: boolean | undefined) => resolve(!!resolveValue),
        Component: DocumentMenu,
      });
    });
  }, [setFormId, companyContext.company.clientDbId]);

  const handleClick = useCallback(async () => {
    const result = await openModal();

    if (!result) return;

    getHistory().push(
      setUrlWithCompanyId(ROUTE_DOCUMENTS_REQUEST.createPath({ id: id }))
    );
  }, [openModal]);

  return (
    <DocumentsFormSelectorStyled>
      {isMobile ? (
        <FloatingButton onClick={handleClick} />
      ) : (
        <Button
          fill
          primary
          iconSrc="/icons/icon-add.svg"
          onClick={handleClick}
        >
          {t("New")}
        </Button>
      )}
    </DocumentsFormSelectorStyled>
  );
};

const DocumentsFormSelectorStyled = styled.div`
  display: flex;
  justify-content: flex-end;

  .r365-button {
    min-width: 120px;
  }

  .r365-button * {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;

export default MyDocumentsFormSelector;
