import { useTranslation, UseTranslationOptions } from "react-i18next";
import { useCallback, useMemo } from "react";
import { TOptions } from "i18next";

export default function useI18n(ns?: string, options?: UseTranslationOptions) {
  const { t, i18n, ready } = useTranslation(ns, options);

  const nt = useCallback(
    (key: string, plural: string, count: number, ntOptions?: TOptions) => {
      return t(key, {
        ...ntOptions,
        count,
        defaultValue_zero: plural,
        defaultValue_one: key,
        defaultValue_two: plural,
        defaultValue_few: plural,
        defaultValue_many: plural,
        defaultValue_other: plural
      });
    },
    [t]
  );

  const pt = useCallback(
    (context: string, key: string, ntOptions?: TOptions) => {
      return t(key, { ...ntOptions, context });
    },
    [t]
  );

  const npt = useCallback(
    (context: string, key: string, plural: string, count: number, ntOptions?: TOptions) => {
      return t(key, {
        ...ntOptions,
        count,
        context,
        defaultValue_zero: plural,
        defaultValue_one: key,
        defaultValue_two: plural,
        defaultValue_few: plural,
        defaultValue_many: plural,
        defaultValue_other: plural
      });
    },
    [t]
  );


  return useMemo(
    () => ({
      t,
      nt,
      pt,
      npt,
      i18n,
      ready
    }),
    [i18n, npt, nt, pt, ready, t]
  );
}
