import Client from "./core/Client";
import APIProcedures from "./Procedures";
import { PagableData } from "./types";
import createAPIHandler from "./utils/createAPIHandler";

export type CompaniesListAPIPayload = {
  userId: string;
};

export type CompaniesAPIResponseItem = {
  applicationDescription: string;
  applicationName: string;
  clientCode: string;
  clientConnectionId: string;
  clientDbId: string;
  clientDbName: string;
  clientDealerId: string;
  clientDescription: string;
  clientEmail: string;
  clientId: string;
  clientIsDealer: boolean;
  clientKsCode: string;
  clientName: string;
  companyName: string;
  companyId: string;
  currencyCode: string;
  id: string;
  rivUser: string;
  userEmail: string;
  userFirstName: string;
  userId: string;
  userLastName: string;
  userLogin: string;
  userRole: string;
};

export type CompaniesGetAPIPayload = {
  clientDbId: string;
};

export type CompanyGetAPIResponse = {
  app: {
    im_kodas: string;
    im_pav: string;
    im_pvm_kodas: string;
  };
};

export type CompaniesCheckAPIPayload = {
  userId: string;
  clientDbId: string;
};

export type CompaniesListAPIResponse = PagableData<CompaniesAPIResponseItem>;

const CompaniesAPIRequest = () => {
  return {
    list: createAPIHandler(async (payload: CompaniesListAPIPayload) => {
      const query = {
        filter: {
          junction: "AND",
          entities: [
            {
              field: "userId",
              operation: "=",
              value: payload.userId,
            },
            {
              field: "applicationName",
              operation: "=",
              value: "MGAMA",
            },
          ],
        },
      };

      const response = await Client.post<CompaniesListAPIResponse>(
        "/account/client-db-app-user/query",
        query
      );
      return response.data;
    }),

    get: createAPIHandler(
      async (
        payload: CompaniesGetAPIPayload
      ): Promise<CompanyGetAPIResponse> => {
        const response = await Client.post<CompanyGetAPIResponse>(
          "/client/application",
          {
            clientDbId: payload.clientDbId,
            procedureName: APIProcedures.RGI_MGAMA_GET_IM_INF,
          }
        );
        return response.data;
      }
    ),

    check: createAPIHandler(async (payload: CompaniesCheckAPIPayload) => {
      const query = {
        filter: {
          junction: "AND",
          entities: [
            {
              field: "userId",
              operation: "=",
              value: payload.userId,
            },
            {
              field: "applicationName",
              operation: "=",
              value: "MGAMA",
            },
            {
              field: "clientDbId",
              operation: "=",
              value: payload.clientDbId,
            },
          ],
        },
      };
      const response = await Client.post<CompaniesListAPIResponse>(
        "/account/client-db-app-user/query",
        query
      );
      return response.data;
    }),
  };
};

export default CompaniesAPIRequest;
