import { useMemo } from "react";
import { Icon } from "@adv-libs/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  ROUTE_CALENDAR,
  ROUTE_MAIN,
  ROUTE_TASKS,
  ROUTE_VACATIONS,
  ROUTE_BUSINESS_TRIPS,
  ROUTE_DOCUMENTS,
  ROUTE_LOGOUT,
  ROUTE_USER_SETTINGS,
} from "../../../app/routes";
import useI18n from "../../../hooks/useI18n";
import { setUrlWithCompanyId } from "../../../utils/path";
import { useModulesContext } from "../../../features/ModulesList/ModulesContext";
import Modules from "../../../features/ModulesList/Modules";
import toastInfo from "../../../features/ToastMessage/toastInfo";

const returnInfo = (message?: string) => {
  if (message) {
    return () => toastInfo(message);
  } else return () => null;
};

export interface SideMenuProps {}

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const { t } = useI18n();
  const modulesContext = useModulesContext();

  const modules = useMemo(() => {
    if (modulesContext) {
      return {
        business: modulesContext.find(
          (item) => item.module === Modules.BUSINESS_TRIP
        ) || { show: false, disabled: true, errorMessage: "" },
        calendar: modulesContext.find(
          (item) => item.module === Modules.CALENDAR
        ) || { show: false, disabled: true, errorMessage: "" },
        vacation: modulesContext.find(
          (item) => item.module === Modules.VACATIONS
        ) || { show: false, disabled: true, errorMessage: "" },
        tasks: modulesContext.find((item) => item.module === Modules.TASKS) || {
          show: false,
          disabled: true,
          errorMessage: "",
        },
        documents: modulesContext.find(
          (item) => item.module === Modules.DOCUMENTS
        ) || {
          show: false,
          disabled: true,
          errorMessage: "",
        },
      };
    }
    return null;
  }, [modulesContext]);

  return (
    <SideMenuStyled>
      <MenuItem>
        <NavLink exact to={setUrlWithCompanyId(ROUTE_MAIN.path)}>
          <Icon src="/icons/icon-nav-dashboard.svg" />
          <span className="nav-item-title">{t("Dashboard")}</span>
        </NavLink>
      </MenuItem>
      {modules.tasks.show && (
        <MenuItem
          disabled={modules.tasks.disabled}
          onClick={returnInfo(modules.tasks.errorMessage)}
        >
          <NavLink to={setUrlWithCompanyId(ROUTE_TASKS.path)}>
            <Icon src="/icons/icon-nav-tasks.svg" />
            <span className="nav-item-title">{t("Approvals")}</span>
          </NavLink>
        </MenuItem>
      )}
      {modules.vacation.show && (
        <MenuItem
          disabled={modules.vacation.disabled}
          onClick={returnInfo(modules.vacation.errorMessage)}
        >
          <NavLink to={setUrlWithCompanyId(ROUTE_VACATIONS.path)}>
            <Icon src="/icons/icon-nav-vacations.svg" />
            <span className="nav-item-title">{t("Vacations")}</span>
          </NavLink>
        </MenuItem>
      )}
      {modules.business.show && (
        <MenuItem
          disabled={modules.business.disabled}
          onClick={returnInfo(modules.business.errorMessage)}
        >
          <NavLink to={setUrlWithCompanyId(ROUTE_BUSINESS_TRIPS.path)}>
            <Icon src="/icons/icon-nav-business-trip.svg" />
            <span className="nav-item-title">{t("Bussines Trips")}</span>
          </NavLink>
        </MenuItem>
      )}
      {modules.calendar.show && (
        <MenuItem
          disabled={modules.calendar.disabled}
          onClick={returnInfo(modules.calendar.errorMessage)}
        >
          <NavLink to={setUrlWithCompanyId(ROUTE_CALENDAR.path)}>
            <Icon src="/icons/icon-nav-calendar.svg" />
            <span className="nav-item-title">{t("Calendar")}</span>
          </NavLink>
        </MenuItem>
      )}
      {modules.documents.show && (
        <MenuItem
          disabled={modules.documents.disabled}
          onClick={returnInfo(modules.documents.errorMessage)}
        >
          <NavLink to={setUrlWithCompanyId(ROUTE_DOCUMENTS.path)}>
            <Icon src="/icons/icon-nav-documents.svg" />
            <span className="nav-item-title">{t("Documents")}</span>
          </NavLink>
        </MenuItem>
      )}

      <MenuItem>
        <NavLink to={setUrlWithCompanyId(ROUTE_USER_SETTINGS.path)}>
          <Icon src="/icons/icon-nav-settings2.svg" />
          <span className="nav-item-title">{t("Settings")}</span>
        </NavLink>
      </MenuItem>

      <MenuItem>
        <NavLink to={ROUTE_LOGOUT.path}>
          <Icon icon="logout" />
          <span className="nav-item-title">{t("Logout")}</span>
        </NavLink>
      </MenuItem>
    </SideMenuStyled>
  );
};

const MenuItem = styled.div<{ disabled?: boolean }>`
  a {
    ${(props) =>
      props.disabled &&
      `pointer-events: none;

    `}
    display: block;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;

    color: ${(props) =>
      props.disabled ? props.theme.disabledColor : `rgb(41, 46, 58)`};
    text-decoration: none;
    border-radius: 6px;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .r365-icon {
      font-size: 20px;
      margin-right: 8px;
    }

    transition: background 0.05s linear, color 0.05s linear;

    &:hover {
      background: rgb(241, 241, 241);
    }

    &.active {
      background: rgba(0, 179, 115, 0.1);
      color: rgb(13, 128, 95);
    }
  }
`;

export const SideMenuStyled = styled.div`
  flex-direction: column;
  gap: 4px;

  width: ${({ theme }) => theme.leftMenuWidth}px;
  height: 100%;
  padding: 0px 16px;
  padding-top: 24px;
  padding-bottom: 30px;

  background: white;
  border-right: 1px solid rgb(234, 234, 234);
`;

export default SideMenu;
