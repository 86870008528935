import React, { Component, ReactNode, ErrorInfo } from "react";
import ErrorFallback from "./ErrorFallback";

interface IErrorBoundary {
  fallbackComponent?: () => JSX.Element | null;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  children?: ReactNode;
}

interface IState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<IErrorBoundary, IState> {
  state: IState = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    const { fallbackComponent: FallbackComponent = ErrorFallback, children } =
      this.props;

    if (this.state.error) {
      return <FallbackComponent {...this.state} />;
    }

    return children;
  }
}

export default ErrorBoundary;
