import * as History from "history";

let __history: any = null;

export const setHistory = (history: History.History<History.LocationState>) => {
  __history = history;
};

export const getHistory = <
  S extends History.LocationState = any
>(): History.History<S> => {
  return __history;
};
