import React from "react";
import FormEditing from "./components/FormEditing";

export interface NewFormProps {}

const NewForm: React.FC<NewFormProps> = (props) => {
  return <FormEditing />;
};

export default NewForm;
