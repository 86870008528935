import { AdvSelect2, AdvSimpleSelect } from "@adv-libs/r365-ui";
import { useIsMobile, useSecondEffect } from "@adv-libs/utils";
import React, { useLayoutEffect, useState, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { JobPositionListAPIResponseItem } from "../../api/vacationsAPI";
import Accordion from "../../components/Accordion/Accordion";
import NoDataCard from "../../components/NoDataCard";
import StateLoader from "../../components/StateLoader";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { persistentJobPosition } from "../../features/PersistentJobPosition/persistentJobPosition";
import useI18n from "../../hooks/useI18n";
import breakpoints from "../../style/breakpoints";
import { minScreenLength } from "../../style/theme";
import JobPositionBlock, {
  AccruedVacations,
} from "./components/JobPositionBlock";
import { useModulesContext } from "../../features/ModulesList/ModulesContext";
import modules from "../../features/ModulesList/Modules";

export interface VacationsDashboardWidgetProps {
  cache?: {
    id: string;
    keepOn: string[];
  };
  expandedByDefault?: boolean;
}

const VacationsDashboardWidget: React.FC<VacationsDashboardWidgetProps> = (
  props
) => {
  const { t } = useI18n();

  return (
    <VacationsDashboardWidgetStyled>
      <Accordion
        title={t("Vacations")}
        iconSrc="/icons/icon-nav-vacations.svg"
        expandedByDefault={props.expandedByDefault}
        minHeight={100}
      >
        <VacationsDashboardWidgetData cache={props.cache} />
      </Accordion>
    </VacationsDashboardWidgetStyled>
  );
};

export interface VacationsDashboardWidgetDataProps {
  cache?: {
    id: string;
    keepOn: string[];
  };
}

const VacationsDashboardWidgetData: React.FC<VacationsDashboardWidgetDataProps> =
  (props) => {
    const companyContext = useCompanyContext();
    const modulesContext = useModulesContext();

    const isMobile = useIsMobile(minScreenLength);

    const [jobPosition, setJobPosition] =
      useState<JobPositionListAPIResponseItem>(null);

    const isRequestBtn = useMemo(() => {
      const value = modulesContext.find(
        (el) => el.module === modules.VACATION_REQUEST_BTN
      );
      const result = value ? value.show : true;
      return result;
    }, [modulesContext]);

    const { t, pt } = useI18n();

    const [state] = useAPI(
      API.vacations.listJobPositions,
      {
        autoStart: {
          clientDbId: companyContext.company.clientDbId,
          page: 1,
          upcoming: true,
        },
        ...(props.cache
          ? {
              cache: props.cache,
            }
          : {}),
      },
      [companyContext.company.clientDbId]
    );

    useLayoutEffect(() => {
      if (state.data && state.data.length && !jobPosition) {
        const defaultJobPositionId = persistentJobPosition.getValue();

        let defaultJobPosition;

        if (defaultJobPositionId) {
          defaultJobPosition = state.data.find(
            (item) => item.id === defaultJobPositionId
          );
        }

        defaultJobPosition = defaultJobPosition || state.data[0];

        setJobPosition(defaultJobPosition);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data]);

    useSecondEffect(() => {
      persistentJobPosition.setValue(jobPosition ? jobPosition.id : null);
    }, [jobPosition]);

    return (
      <VacationsDashboardWidgetDataStyled>
        <StateLoader isLoading={state.isLoading} error={state.error}>
          {() => {
            if (state.data.length) {
              return (
                <>
                  {isMobile ? (
                    <AdvSimpleSelect
                      fieldName="job-position"
                      label={t("Job position")}
                      value={jobPosition}
                      onCommit={setJobPosition}
                      getItems={state.data}
                    />
                  ) : (
                    <AdvSelect2
                      fieldName="job-position"
                      label={t("Job position")}
                      value={jobPosition}
                      onCommit={setJobPosition}
                      getItems={state.data}
                      paginated={false}
                      filter="fuzzy"
                    />
                  )}

                  {jobPosition ? (
                    <JobPositionBlock
                      listButton
                      requestButton={isRequestBtn}
                      item={jobPosition}
                    />
                  ) : null}
                </>
              );
            } else {
              return (
                <NoDataCard>
                  {pt("vacations", "No information available")}
                </NoDataCard>
              );
            }
          }}
        </StateLoader>
      </VacationsDashboardWidgetDataStyled>
    );
  };

const VacationsDashboardWidgetDataStyled = styled.div`
  .r365-control-select2 {
    margin-bottom: 10px;
  }
`;

const VacationsDashboardWidgetStyled = styled.div`
  ${AccruedVacations} {
    grid-auto-flow: row;

    @media screen and (min-width: ${breakpoints.xs}px) {
      grid-auto-flow: column;
    }

    @media screen and (min-width: ${breakpoints.s}px) {
      grid-auto-flow: row;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      grid-auto-flow: column;
    }
  }
`;

export default VacationsDashboardWidget;
