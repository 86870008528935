import { AdvFormAPI, useFormName, useFormValues } from "@adv-libs/adv-form";
import { AdvFormGroup, Button } from "@adv-libs/r365-ui";
import { useIsMobile } from "@adv-libs/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import {
  JobPositionListAPIResponseItem,
  BusinessTripsTypeListAPIResponseItem,
} from "../../api/businessTripAPI";
import { ROUTE_BUSINESS_TRIPS } from "../../app/routes";
import BackButtonDesktop from "../../components/BackButtonDesktop";
import Card, { CardStyled } from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {
  FormDatePickerRange,
  FormSelectField,
  FormTextareaField,
  FormTextField,
} from "../../components/form";
import Form from "../../components/form/Form";
import FormSimpleSelectField from "../../components/form/FormSimpleSelectField";
import SubmitButton from "../../components/form/SubmitButton";
import NoDataCard from "../../components/NoDataCard";
import PageTitle from "../../components/PageTitle";
import StateLoader from "../../components/StateLoader";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { persistentJobPosition } from "../../features/PersistentJobPosition/persistentJobPosition";
import PopStateItem from "../../features/PopState/PopStateItem";
import goSafeBack, { createGoSafeBack } from "../../features/Router/goSafeBack";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import toastSuccess from "../../features/ToastMessage/toastSuccess";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import { minScreenLength } from "../../style/theme";
import { setUrlWithCompanyId } from "../../utils/path";
import {
  JobPositionBlockStyled,
  JobPositionHeader,
} from "./components/JobPositionBlock";

export interface BusinessTripRequestProps {}

const BusinessTripRequest: React.FC<BusinessTripRequestProps> = (props) => {
  const companyContext = useCompanyContext();
  const { t, pt } = useI18n();
  const [formAPI, setFormAPI] = useState<AdvFormAPI>();

  const isMobile = useIsMobile(minScreenLength);

  const handleSubmit = useCallback(
    async (values, formAPI: AdvFormAPI) => {
      await API.businessTrips.businessTripRequest({
        clientDbId: companyContext.company.clientDbId,
        jobPosition: values.jobPosition,
        startDate: values.businessTripDate?.[0],
        endDate: values.businessTripDate?.[1],
        businessTripType: values.businessTripType,
        destinationCode: values.destinationCode,
        destinationName: values.destinationName,
        purpose: values.purpose,
        comment: values.comment,
      });

      formAPI.setTouched(false);
      goSafeBack(ROUTE_BUSINESS_TRIPS.path);
      setTimeout(() => {
        toastSuccess(t("Business trip request is created"));
      }, 1);
    },
    [companyContext.company.clientDbId, t]
  );

  const validation = useMemo(() => {
    return yup.object({
      jobPosition: yup.string().required(t("Field is required")),
      businessTripType: yup.string().required(t("Field is required")),
      businessTripDate: yup.array().nullable().required(t("Field is required")),
    });
  }, [t]);

  const [state] = useAPI(
    API.businessTrips.getBusinessRequestInit,
    {
      autoStart: {
        clientDbId: companyContext.company.clientDbId,
      },
    },
    [companyContext.company.clientDbId]
  );

  const handlePopStateItem = useCallback(() => {
    if (formAPI && formAPI.isTouched()) {
      return t("Are you sure to exit?");
    }
  }, [formAPI, t]);

  const handleFieldChange = useCallback(
    (
      field: string,
      value: JobPositionListAPIResponseItem,
      formAPI: AdvFormAPI
    ) => {
      if (field === "jobPosition") {
        persistentJobPosition.setValue(value.id);
      }

      if (field === "destinationCode") {
        const name = value.name.split(value.id + " - ")[1];
        formAPI.setValue("destinationName", name);
      }
    },
    []
  );

  useEffect(() => {
    if (
      state.data &&
      state.data.jobsList &&
      state.data.jobsList.length &&
      formAPI &&
      !formAPI.isDisposed()
    ) {
      let jobPosition = formAPI.getValues("jobPosition");
      if (!jobPosition) {
        const defaultJobPositionId = persistentJobPosition.getValue();
        let defaultJobPosition;

        if (defaultJobPositionId) {
          defaultJobPosition = state.data.jobsList.find(
            (item) => item.id === defaultJobPositionId
          );
        }

        defaultJobPosition = defaultJobPosition || state.data.jobsList[0];

        formAPI.setValue("jobPosition", defaultJobPosition, {
          silent: true,
          ignoreDiff: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data, formAPI]);

  return (
    <>
      <PageTitle>{t("Business trip request")}</PageTitle>
      <ScrollRestoration id="businessTrip-request" restoreTop />
      <PopStateItem
        name="businessTrip-request-form"
        onPopState={handlePopStateItem}
      >
        <BusinessTripRequestStyled>
          <Grid>
            <GridCol col-l="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
              <Card>
                <BackButtonDesktop />
                <CardBody>
                  <StateLoader error={state.error} isLoading={state.isLoading}>
                    {() => {
                      if (state.data) {
                        return (
                          <>
                            <Header>{t("Business trip request")}</Header>
                            <Form
                              onSubmit={handleSubmit}
                              onFormReady={setFormAPI}
                              onFieldChange={handleFieldChange}
                              validation={validation}
                              showGenericError
                              toastifyError={false}
                            >
                              {isMobile ? (
                                <FormSimpleSelectField
                                  name="jobPosition"
                                  submitFieldName="jobPosition:id"
                                  getItems={state.data.jobsList}
                                  label={t("Job position")}
                                  size={{ md: 6 }}
                                />
                              ) : (
                                <FormSelectField
                                  name="jobPosition"
                                  submitFieldName="jobPosition:id"
                                  getItems={state.data.jobsList}
                                  label={t("Job position")}
                                  size={{ md: 6 }}
                                />
                              )}
                              <AdvFormGroup noMargin>
                                {isMobile ? (
                                  <FormSimpleSelectField
                                    label={t("Business trip type")}
                                    name="businessTripType"
                                    getItems={state.data.businessTypesList}
                                    isClearable
                                    submitFieldName="businessTripType:id"
                                    size={{
                                      md: 6,
                                    }}
                                  />
                                ) : (
                                  <FormSelectField
                                    label={t("Business trip type")}
                                    name="businessTripType"
                                    getItems={state.data.businessTypesList}
                                    paginated={false}
                                    filter="fuzzy"
                                    isClearable
                                    submitFieldName="businessTripType:id"
                                    size={{
                                      md: 6,
                                    }}
                                  />
                                )}
                                <FormDatePickerRange
                                  label={t("Date from - to")}
                                  name="businessTripDate"
                                  isClearable
                                  size={{
                                    md: 6,
                                  }}
                                />
                              </AdvFormGroup>
                              <BusinessTripTypeNotes />
                              <AdvFormGroup>
                                {isMobile ? (
                                  <FormSimpleSelectField
                                    label={t("Destination")}
                                    name="destinationCode"
                                    getItems={state.data.destinationsList}
                                    isClearable
                                    submitFieldName="destinationCode:id"
                                    size={{
                                      md: 6,
                                    }}
                                  />
                                ) : (
                                  <FormSelectField
                                    label={t("Destination")}
                                    name="destinationCode"
                                    getItems={state.data.destinationsList}
                                    selectedLabelParam={"id"}
                                    paginated={false}
                                    filter="fuzzy"
                                    isClearable
                                    submitFieldName="destinationCode:id"
                                    size={{
                                      md: 6,
                                    }}
                                  />
                                )}
                                <FormTextField
                                  label={t("Destination name")}
                                  type="text"
                                  name="destinationName"
                                  maxLength={100}
                                  size={{
                                    md: 6,
                                  }}
                                />
                              </AdvFormGroup>
                              <AdvFormGroup>
                                <FormTextField
                                  label={t("Purpose of business trip")}
                                  type="text"
                                  name="purpose"
                                  maxLength={100}
                                  size={{
                                    md: 12,
                                  }}
                                />
                                <FormTextareaField
                                  name="comment"
                                  label={t("Comment")}
                                  rows={5}
                                  size={{ md: 12 }}
                                />
                              </AdvFormGroup>
                              <FormActions>
                                <div className="close-button">
                                  <Button
                                    fill
                                    onClick={createGoSafeBack(
                                      setUrlWithCompanyId(
                                        ROUTE_BUSINESS_TRIPS.path
                                      )
                                    )}
                                  >
                                    {t("Close")}
                                  </Button>
                                </div>
                                <div className="submit-button">
                                  <SubmitButton>{t("Submit")}</SubmitButton>
                                </div>
                              </FormActions>
                            </Form>
                          </>
                        );
                      } else {
                        return (
                          <NoDataCard>
                            {pt("business trips", "No information available")}
                          </NoDataCard>
                        );
                      }
                    }}
                  </StateLoader>
                </CardBody>
              </Card>
            </GridCol>
          </Grid>
        </BusinessTripRequestStyled>
      </PopStateItem>
    </>
  );
};

interface BusinessTripTypeNotesProps {}

const BusinessTripTypeNotes: React.FC<BusinessTripTypeNotesProps> = (props) => {
  const formName = useFormName();
  const values = useFormValues(formName);

  const vacationType =
    values?.businessTripType as BusinessTripsTypeListAPIResponseItem;

  const note = vacationType?.pastaba;

  const { t } = useI18n();

  if (!note) {
    return null;
  }

  return (
    <BusinessTripTypeNoteStyled>
      <div className="note-container">
        <div className="note-header">{t("Notes")}</div>
        <div className="note">
          {note.split("\n").map((line, key) => {
            return <p key={key}>{line}</p>;
          })}
        </div>
      </div>
    </BusinessTripTypeNoteStyled>
  );
};

const BusinessTripTypeNoteStyled = styled.div`
  padding: 6px;

  .note-container {
    background: #f5f5f8;

    border-radius: 6px;
    padding: 8px 16px 8px 24px;

    .note-header {
      margin-bottom: 12px;
      font-size: 10px;
      color: #737373;
    }

    .note {
      p {
        margin: 4px 0;
      }
    }
  }
`;

const FormActions = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  /* gap: 14px; */
  /* justify-content: flex-end; */

  .close-button {
    order: 2;
  }

  .submit-button {
    order: 1;
  }

  .r365-button {
    .r365-button__content {
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;

    .close-button {
      order: 1;
    }

    .submit-button {
      order: 2;
    }

    .r365-button {
      min-width: 200px;
    }
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 6px;
`;

const BusinessTripRequestStyled = styled.div`
  margin: 0 auto;

  form {
    ${CardStyled} {
      margin: 6px;
      padding-top: 8px;
      padding-bottom: 8px;

      ${JobPositionBlockStyled} {
        ${JobPositionHeader} {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default BusinessTripRequest;
