import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import {
  JobPositionListAPIResponseItem,
  BusinessTripsListAPIResponse,
} from "../../api/businessTripAPI";
import { ROUTE_BUSINESS_TRIP, ROUTE_BUSINESS_TRIPS } from "../../app/routes";
import InfiniteCard from "../../components/InfiniteCard/InfiniteCard";
import NoDataCard from "../../components/NoDataCard";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import useI18n from "../../hooks/useI18n";
import BusinessTripCard from "./components/BusinessTripCard";

export interface MyBusinessTripsHistoryProps {
  jobPosition: JobPositionListAPIResponseItem;
}

const MyBusinessTripsHistory: React.FC<MyBusinessTripsHistoryProps> = (
  props
) => {
  const companyContext = useCompanyContext();
  const { t } = useI18n();

  const cacheKeepOn = useMemo(() => {
    return [ROUTE_BUSINESS_TRIPS.path, ROUTE_BUSINESS_TRIP.path];
  }, []);

  const request = useCallback(
    async (page: number) => {
      return await API.businessTrips.list({
        clientDbId: companyContext.company.clientDbId,
        page: page,
        size: 10,
        jobPosition: props.jobPosition?.id,
      });
    },
    [companyContext.company.clientDbId, props.jobPosition]
  );

  if (!props.jobPosition) return null;

  return (
    <MyBusinessTripsHistoryStyled>
      <InfiniteCard
        id="businessTrips-history"
        showMore
        keepOn={cacheKeepOn}
        header={t("Business trips history")}
        request={request}
        threshold={9}
      >
        {(items: BusinessTripsListAPIResponse) => {
          if (!items.length) {
            return <NoDataCard>{t("No business trips history")}</NoDataCard>;
          }
          return (
            <MyBusinessTripsItems>
              {items.map((item, key) => {
                return <BusinessTripCard key={key} item={item} showStatus />;
              })}
            </MyBusinessTripsItems>
          );
        }}
      </InfiniteCard>
    </MyBusinessTripsHistoryStyled>
  );
};

const MyBusinessTripsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MyBusinessTripsHistoryStyled = styled.div``;

export default MyBusinessTripsHistory;
