import { AdvFormFieldWrapper } from "@adv-libs/r365-ui";
import styled from "styled-components";

interface FormLabelProps {
  default?: string;
  label?: string;
  size?: number;
}

const FormLabel: React.FC<FormLabelProps> = ({
  default: note,
  label: header,
  size,
}) => {
  if (!note) {
    return null;
  }

  return (
    <AdvFormFieldWrapper size={{ md: size }}>
      <FormLabelStyled>
        <div className="note-container">
          <div className="note-header">{header}</div>
          <div className="note">
            {note.split("\n").map((line, key) => {
              return <p key={key}>{line}</p>;
            })}
          </div>
        </div>
      </FormLabelStyled>
    </AdvFormFieldWrapper>
  );
};

const FormLabelStyled = styled.div`
  .note-container {
    background: #f5f5f8;

    border-radius: 6px;
    padding: 8px 11px 4px 20px;

    .note-header {
      font-size: 10px;
      color: #737373;
    }

    .note {
      padding: 1px 2px;
      p {
        margin: 4px 0;
      }
    }
  }
`;

export default FormLabel;
