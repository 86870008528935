import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { popStateIsSkipped, restorePopState } from "./skipPopState";
import popStateBuffer from "./popStateBuffer";

const DEBUG = false;

let previousKey = null;
let previousIndex = null;
let blocked = false;
let revertingPop = false;

const locationKeys = {};

const usePopState = () => {
  const history = useHistory();

  const getFirstMessage = useCallback((state, targetIndex: number) => {
    if (popStateIsSkipped()) {
      restorePopState();
      return false;
    }

    const popStates = popStateBuffer.getUntilIndex(targetIndex);
    for (const popState of popStates) {
      const message = popState.callback(state);
      if (message) return message;
    }
    return false;
  }, []);

  const restorePop = useCallback(() => {
    revertingPop = true;
    const windowIdx = window.history.state?.state?.idx ?? 0;
    const delta = previousIndex - windowIdx;
    if (DEBUG) {
      console.log("RESTORE POP", "PREV", previousIndex, "NEXT", windowIdx, "DELTA", delta);
    }
    if (delta !== 0) {
      history.go(delta);
    }
    setTimeout(() => {
      revertingPop = false;
      blocked = false;
    }, 100);
  }, [history]);

  const handlePopState = useCallback(
    (state, action) => {
      if (blocked) {
        return false;
      }

      if (action === "PUSH" && state.state?.createdUrl) return true;

      if (DEBUG) {
        console.log("POPSTATE", state.key, window.history.state?.key, action);
        console.log("TO", state.state?.idx ?? 0, "FROM", previousIndex);
        console.log("TO", state.key, "FROM", previousKey);
      }

      if (revertingPop) {
        return true;
      }

      const targetIndex = state.state?.idx ?? 0;
      const message = getFirstMessage(state, targetIndex);
      if (message) {
        blocked = true;
        const result = window.confirm(message);
        if (result) {
          blocked = false;
          return true;
        } else {
          if (!locationKeys[state.key]) {
            restorePop();
          } else {
            blocked = false;
          }
          return false;
        }
      } else {
        return true;
      }
    },
    [getFirstMessage, restorePop]
  );

  const handleBeforeUnload = useCallback((e) => {
    // Handle unload event
    const popStates = popStateBuffer.get();
    for (const popState of popStates) {
      const message = popState.callback(window.history);
      if (message) {
        e.preventDefault();
        e.returnValue = "";
        blocked = false;
        revertingPop = false;
        break;
      }
    }
  }, []);

  const handleHistoryChange = useCallback((state, action) => {
    if (!revertingPop) {
      // On history change update current key and index
      const windowIdx = window.history.state?.state?.idx ?? 0;
      const windowKey = window.history.state?.key;
      if (DEBUG) {
        console.log("LISTEN", windowIdx, windowKey);
      }
      if (action === "PUSH") {
        locationKeys[windowKey] = true;
      }
      previousKey = windowKey;
      previousIndex = windowIdx;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    if (history) {
      // Initially set the current key and index
      previousKey = history.location.key;
      previousIndex = (history.location.state as any)?.idx ?? 0;
      // ?? locationKeys[history.location.key] = true;
      return history.listen(handleHistoryChange);
    }
  }, [handleHistoryChange, history]);

  useEffect(() => {
    if (history) {
      return history.block(handlePopState as any);
    }
  }, [handlePopState, history]);
};

export default usePopState;
