import { openDynamicModal } from "../../../features/Modal/openDynamicModal";
import { CalendarEventDetailedType } from "../types";
import CalendarEventDetailView from "./CalendarEventDetailView";

export interface OpenCalendarDetailViewOptions {
  event: CalendarEventDetailedType;
  title: string;
  user: string;
  clientDbId: string;
}

const openCalendarDetailView = (options: OpenCalendarDetailViewOptions) => {
  openDynamicModal({
    Component: CalendarEventDetailView,
    props: {
      event: options.event,
      title: options.title,
      user: options.user,
      clientDbId: options.clientDbId,
    },
  });
};

export default openCalendarDetailView;
