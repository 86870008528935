import React from "react";
import styled from "styled-components";
import { SpinnerStyled } from "../components/Spinner/Spinner";
import SpinnerWithProgress, {
  SpinnerWithProgressInstance,
} from "../components/Spinner/SpinnerWithProgress";

export interface LayoutLoaderProps {}

const LayoutLoader = React.forwardRef<
  SpinnerWithProgressInstance,
  React.PropsWithChildren<LayoutLoaderProps>
>((props, ref) => {
  return (
    <LayoutLoaderStyled>
      <SpinnerWithProgress ref={ref} />
    </LayoutLoaderStyled>
  );
});

const LayoutLoaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15vh;

  ${SpinnerStyled} {
    font-size: 26px;
  }
`;

export default LayoutLoader;
