import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import actionAfterCompanySelect from "../../../actions/actionAfterCompanySelect";
import API from "../../../api/API";
import useAPI from "../../../api/hooks/useAPI";
import { ROUTE_MAIN } from "../../../app/routes";
import { userSelectors, useStore } from "../../../app/store";
import ErrorMessage from "../../../components/ErrorMessage";
import useI18n from "../../../hooks/useI18n";
import CompaniesBoxes from "../../../modules/CompaniesList/components/CompaniesBoxes";
import { useCacheContext } from "../../Cache/CacheContext";
import { useCompanyContext } from "../CompanyContext";

export interface CompaniesSwitchProps {}

const CompaniesSwitch: React.FC<CompaniesSwitchProps> = (props) => {
  const { t } = useI18n();
  const userId = useStore(userSelectors.userId) || "";

  const cacheContext = useCacheContext();

  const companyContext = useCompanyContext();

  const [state] = useAPI(
    API.companies.list,
    {
      autoStart: { userId },
      toastifyError: false,
      cache: {
        id: "company-switch",
        keepOn: true,
      },
    },
    [userId]
  );

  const handleOnChange = useCallback(
    (choosenDbId: string) => {
      cacheContext.clearCache();
      actionAfterCompanySelect(choosenDbId, ROUTE_MAIN.path);
    },
    [cacheContext]
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(state.data?.content)) return null;
    return state.data.content.filter(
      (company) => company.id !== companyContext.company.id
    );
  }, [companyContext, state.data]);

  if (state.error) {
    return (
      <CompaniesSwitchStyled>
        <ErrorMessage error={state.error} />
      </CompaniesSwitchStyled>
    );
  }

  if (state.isLoading) {
    return null;
  }

  if (!filteredData || !filteredData.length) return null;

  return (
    <CompaniesSwitchStyled>
      <div className="h2">{t("Switch company")}</div>
      <CompaniesBoxes data={filteredData} onChange={handleOnChange} />
    </CompaniesSwitchStyled>
  );
};

const CompaniesSwitchStyled = styled.div`
  position: relative;
  width: 100%;
  padding: 28px 16px;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    width: auto;
    min-width: 420px;
    padding: 28px 20px;
  }

  .h2 {
    margin-bottom: 14px;
  }

  .box {
    cursor: pointer;
  }
`;

export default CompaniesSwitch;
