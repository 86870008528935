import { AdvFormMessage, AdvFormMessages } from "@adv-libs/adv-form";
import { Callout } from "@adv-libs/r365-ui";
import React from "react";
import styled from "styled-components";

export interface MainFormErrorProps {}

const MainFormError: React.FC<MainFormErrorProps> = (props) => {
  return (
    <MainFormErrorStyled>
      <AdvFormMessages component={MainFormErrorMessages}></AdvFormMessages>
    </MainFormErrorStyled>
  );
};

interface MainFormErrorMessagesProps {
  formMessages: AdvFormMessage[];
}

const MainFormErrorMessages: React.FC<MainFormErrorMessagesProps> = (props) => {
  if (!props.formMessages) return null;

  if (Array.isArray(props.formMessages) && !props.formMessages.length) {
    return null;
  }

  return (
    <Callout intent="error" noMargin style={{ marginBottom: 6 }}>
      {props.formMessages.map((message, key) => {
        if (typeof message === "string") {
          return <div key={key}>{message}</div>;
        } else {
          return <div key={key}>{message.description}</div>;
        }
      })}
    </Callout>
  );
};

export const MainFormErrorStyled = styled.div`
  padding: 0 8px;
`;

export default MainFormError;
