import { getHistory } from "../Router/history";

class PopStateBuffer {
  private buffer: Map<string, { callback: (state) => string | null | void; index: number }> =
    new Map();

  private getCurrentLocationIndex() {
    const history = getHistory();
    const location = history?.location;
    const index = location?.state?.idx ?? 0;
    return index;
  }

  add(name: string, callback: (state) => string | null | void) {
    const index = this.getCurrentLocationIndex();
    if (this.buffer.has(name)) {
      this.buffer.delete(name);
      this.buffer.set(name, { callback, index });
    } else {
      this.buffer.set(name, { callback, index });
    }
  }

  remove(name: string) {
    if (this.buffer.has(name)) {
      this.buffer.delete(name);
    }
  }

  getLast() {
    const items = Array.from(this.buffer.values());
    return items[items.length - 1] ?? null;
  }

  getLastName() {
    const items = Array.from(this.buffer.keys());
    return items[items.length - 1] ?? null;
  }

  getUntilIndex(targetIndex: number) {
    const currentIndex = this.getCurrentLocationIndex();
    const items = this.get();
    return items.filter((item) => {
      if (targetIndex > currentIndex) {
        return item.index < targetIndex;
      } else if (targetIndex < currentIndex) {
        return item.index > targetIndex;
      } else {
        return false;
      }
    });
  }

  get() {
    return Array.from(this.buffer.values()).reverse();
  }
}

const popStateBuffer = new PopStateBuffer();

export default popStateBuffer;
