import { AdvStaticStatus, Button } from "@adv-libs/r365-ui";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import spacetime from "spacetime";
import styled from "styled-components";
import { BusinessTripsListAPIResponseItem } from "../../../api/businessTripAPI";
import { ROUTE_BUSINESS_TRIP } from "../../../app/routes";
import useI18n from "../../../hooks/useI18n";
import { setUrlWithCompanyId } from "../../../utils/path";

export interface BusinessTripCardProps {
  showCancelButton?: boolean;
  showStatus?: boolean;
  item: BusinessTripsListAPIResponseItem;
}

const BusinessTripCard: React.FC<BusinessTripCardProps> = (props) => {
  const { t } = useI18n();

  const handleCancelRequest = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <BusinessTripCardStyled>
      <Link
        to={setUrlWithCompanyId(
          ROUTE_BUSINESS_TRIP.createPath({ id: props.item.id })
        )}
      >
        <Top>
          <LeftSide>
            <div className="date">
              <b>
                {spacetime(props.item.dateFrom).format("iso-short")} -{" "}
                {spacetime(props.item.dateTo).format("iso-short")}
              </b>
            </div>
            <div className="title">{props.item.title}</div>
            {props.item.location ? (
              <div className="location">{props.item.location}</div>
            ) : null}
          </LeftSide>
          {props.showStatus && props.item.statusName?.trim?.() ? (
            <RightSide>
              <div className="status" style={{ color: props.item.statusColor }}>
                <AdvStaticStatus
                  id={props.item.statusId}
                  name={props.item.statusName}
                  background={props.item.statusColor}
                  narrow
                  template={false}
                  color="#313131"
                />
              </div>
            </RightSide>
          ) : null}
        </Top>
        {props.showCancelButton ? (
          <Bottom>
            <Button onClick={handleCancelRequest} fill>
              {t("Cancel request")}
            </Button>
          </Bottom>
        ) : null}
      </Link>
    </BusinessTripCardStyled>
  );
};

const Top = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* gap: 20px; */
  gap: 10px;
`;

const Bottom = styled.div`
  padding: 10px 26px;
  padding-bottom: 0;
  margin: 10px -10px 0 -16px;
  display: flex;
  justify-content: center;

  border-top: 1px solid #e4e4e4;

  .r365-button {
    max-width: 400px;
    width: 100%;
  }

  .r365-button--new button.r365-button__content {
    height: 35px;
    font-size: 12px;
    font-weight: 600;
    color: #292e3a;
  }
`;

const RightSide = styled.div`
  order: 1;
  display: flex;
  /* justify-content: flex-end; */
`;

const LeftSide = styled.div`
  order: 2;
`;

const BusinessTripCardStyled = styled.div`
  background: ${({ theme }) => theme.greyColor};
  border-radius: 6px;
  transition: background 0.05s linear;

  &:hover {
    background: #e5e5e8;
  }

  a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 14px 10px 10px 16px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .location {
    color: #737373;
    font-size: 12px;
    font-weight: 600;
  }

  .date {
    color: #737373;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    margin-bottom: 4px;

    b {
      color: #292e3a;
      font-weight: 600;
    }
  }

  .user {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .status {
    .r365-status {
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      min-width: 70px;
      justify-content: center;
    }
  }

  @media screen and (min-width: 480px) {
    ${Top} {
      flex-direction: row;
      justify-content: space-between;

      ${LeftSide} {
        order: 1;
      }

      ${RightSide} {
        order: 2;
      }
    }
  } ;
`;

export default BusinessTripCard;
