import React from "react";
import styled from "styled-components";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { ROUTE_TASKS } from "../../app/routes";
import Accordion from "../../components/Accordion/Accordion";
import AccordionFooterButton from "../../components/Accordion/AccordionFooterButton";
import NoDataCard from "../../components/NoDataCard";
import StateLoader from "../../components/StateLoader";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import useI18n from "../../hooks/useI18n";
import { setUrlWithCompanyId } from "../../utils/path";
import TaskCard from "../MyTasks/components/TaskCard";

export interface TasksDashboardWidgetProps {
  footer?: boolean;
  size?: number;
  showBackButton?: boolean;
  cache?: {
    id: string;
    keepOn: string[];
  };
}

const TasksDashboardWidget: React.FC<TasksDashboardWidgetProps> = (props) => {
  const { pt } = useI18n();

  return (
    <TasksDashboardWidgetStyled>
      <Accordion
        title={pt("approvals", "Approvals")}
        expandedByDefault
        minHeight={100}
        showBackButton={props.showBackButton}
        iconSrc="/icons/icon-nav-tasks.svg"
        cache={props.cache}
      >
        <TasksDashboardWidgetData
          footer={props.footer}
          size={props.size}
          cache={props.cache}
        />
      </Accordion>
    </TasksDashboardWidgetStyled>
  );
};

export interface TasksDashboardWidgetDataProps {
  footer?: boolean;
  size?: number;
  cache?: {
    id: string;
    keepOn: string[];
  };
}

const TasksDashboardWidgetData: React.FC<TasksDashboardWidgetDataProps> = (
  props
) => {
  const { t, pt } = useI18n();

  const companyContext = useCompanyContext();

  const [state] = useAPI(
    API.tasks.list,
    {
      autoStart: {
        clientDbId: companyContext.company.clientDbId,
        size: props.size,
        page: 1,
        active: true,
      },
      ...(props.cache
        ? {
            cache: props.cache,
          }
        : {}),
    },
    [companyContext.company.clientDbId]
  );

  return (
    <StateLoader error={state.error} isLoading={state.isLoading}>
      {() => {
        if (state.data.length) {
          return (
            <TasksDashboardWidgetItems>
              {state.data.map((item, key) => {
                return <TaskCard key={key} item={item} />;
              })}
              {props.footer ? (
                <AccordionFooterButton
                  to={setUrlWithCompanyId(ROUTE_TASKS.path)}
                >
                  {pt("approvals", "Show all approvals")}
                </AccordionFooterButton>
              ) : null}
            </TasksDashboardWidgetItems>
          );
        } else {
          return (
            <>
              <NoDataCard>{t("No new approvals at this moment")}</NoDataCard>
              {props.footer ? (
                <AccordionFooterButton
                  to={setUrlWithCompanyId(ROUTE_TASKS.path)}
                >
                  {pt("approvals", "Show all approvals")}
                </AccordionFooterButton>
              ) : null}
            </>
          );
        }
      }}
    </StateLoader>
  );
};

const TasksDashboardWidgetItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TasksDashboardWidgetStyled = styled.div``;

export default TasksDashboardWidget;
