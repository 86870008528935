import { useSecondEffect } from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useCacheContext } from "../../features/Cache/CacheContext";
import serializeError from "../../features/Error/serializeError";
import useI18n from "../../hooks/useI18n";
import breakpoints from "../../style/breakpoints";
import ErrorMessage from "../ErrorMessage";
import Spinner, { SpinnerStyled } from "../Spinner/Spinner";
import { InfiniteCardState } from "./types";
import BackButtonDesktop from "../BackButtonDesktop";
import { Icon } from "@adv-libs/icons";

export interface InfiniteCardWithSearchProps {
  showMore?: boolean;
  keepOn?: string[];
  header?: string | React.ReactNode;
  threshold: number;
  id: string;
  request: (page: number) => Promise<any>;
  getFilterValues?: () => Promise<any[]>;
  children?: (data: any[]) => React.ReactNode;
  filtersContainer?: React.ReactNode;
  searchInput?: React.ReactNode;
  iconSrc?: any;
  showBackButton?: boolean;
  noBorders?: boolean;
}

const initialState: InfiniteCardState = {
  items: [],
  hasMore: true,
  page: 1,
  error: null,
  isLoading: true,
  iteration: 0,
  loaded: false,
};

const CACHE_SCOPE = "infinite-card-with-Search";

const InfiniteCardWithSearch: React.FC<InfiniteCardWithSearchProps> = (
  props
) => {
  const { t } = useI18n();
  const { request } = props;
  const mountedRef = useRef(true);

  const cacheContext = useCacheContext();

  const [state, setState] = useState<InfiniteCardState>(
    cacheContext.getCache(CACHE_SCOPE, props.id) || initialState
  );

  const fetch = useCallback(async () => {
    try {
      setState((state) => {
        return { ...state, isLoading: true, error: null };
      });
      const data = await request(state.page);
      if (!mountedRef.current) return;
      if (data.length === 0) {
        setState((state) => {
          return {
            ...state,
            hasMore: false,
            isLoading: false,
          };
        });
      } else {
        setState((state) => {
          const items = [...state.items, ...data];
          return {
            ...state,
            hasMore: data.length < props.threshold ? false : state.hasMore,
            items,
            isLoading: false,
            loaded: true,
          };
        });
      }
    } catch (err) {
      console.error(err);
      if (!mountedRef.current) return;
      setState((state) => {
        return { ...state, isLoading: false, error: serializeError(err) };
      });
    }
  }, [props.threshold, request, state.page]);

  const handleLoadMore = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        page: state.page + 1,
      };
    });
  }, []);

  useSecondEffect(() => {
    setState({ ...initialState, iteration: state.iteration + 1 });
  }, [request]);

  useEffect(() => {
    if (!state.loaded) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSecondEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.iteration]);

  useLayoutEffect(() => {
    cacheContext.setCache(CACHE_SCOPE, props.id, props.keepOn || [], state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useLayoutEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <InfiniteCardStyled noBorders={props.noBorders}>
      {props.showBackButton ? <BackButtonDesktop /> : null}

      <InfiniteCardTop>
        {props.header ? (
          <InfiniteCardHeader>
            <span className="infinite-card-title">
              {props.iconSrc ? (
                <span className="infinite-card-icon">
                  <Icon src={props.iconSrc} />
                </span>
              ) : null}
              {props.header}
            </span>
          </InfiniteCardHeader>
        ) : null}

        {props.searchInput ? (
          <SearchInput>{props.searchInput}</SearchInput>
        ) : null}
        {props.filtersContainer ? (
          <FiltersContainer>{props.filtersContainer}</FiltersContainer>
        ) : null}

        <InfiniteCardContent state={state} noPadding={props.noBorders}>
          {props.children}
        </InfiniteCardContent>
      </InfiniteCardTop>
      {props.showMore &&
      state.hasMore &&
      !(state.page === 1 && state.isLoading) ? (
        <InfiniteCardShowMore
          onClick={handleLoadMore}
          padding={props.noBorders}
        >
          {state.isLoading && state.page !== 1 ? (
            <Spinner />
          ) : (
            <span className="show-more">{t("Show more")}</span>
          )}
        </InfiniteCardShowMore>
      ) : null}
    </InfiniteCardStyled>
  );
};

interface InfiniteCardContentProps {
  state: InfiniteCardState;
  children: (items: any[]) => React.ReactNode;
  noPadding?: boolean;
}

const InfiniteCardContent: React.FC<InfiniteCardContentProps> = (props) => {
  if (props.state.error) {
    return <ErrorMessage error={props.state.error} />;
  }

  if (props.state.isLoading && props.state.page === 1) {
    return <Spinner />;
  }

  return (
    <InfiniteCardContentStyled noPadding={props.noPadding}>
      {props.children(props.state.items)}
    </InfiniteCardContentStyled>
  );
};

const InfiniteCardHeader = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #e6e6e6;

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfiniteCardContentStyled = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding }) =>
    !noPadding
      ? css`
          padding: 8px 8px 16px 8px;
          @media screen and (min-width: ${breakpoints.l}px) {
            padding-left: 16px;
            padding-right: 16px;
          }
        `
      : null}
`;

const InfiniteCardTop = styled.div``;

const SearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;

  > div {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const FiltersContainer = styled.div`
  padding: 0 8px;
  @media screen and (min-width: ${breakpoints.l}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfiniteCardShowMore = styled.div<{ padding?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  ${({ padding }) => (padding ? "padding-top: 16px;" : null)}

  .show-more {
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
    color: black;

    &:hover {
      opacity: 0.8;
    }
  }

  ${SpinnerStyled} {
    min-height: initial !important;
    margin: 0px 0px;
  }
`;

export const InfiniteCardStyled = styled.div<{ noBorders?: boolean }>`
  border-radius: 6px;
  background: white;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ noBorders }) =>
    !noBorders
      ? "margin-bottom: 10px; box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05); border: 1px solid #e6e6e6; "
      : null}

  .infinite-card-title {
    font-size: 18px;
    height: 36px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .infinite-card-icon {
      font-size: 22px;
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }

  ${SpinnerStyled} {
    min-height: 40px;
  }
`;

export default InfiniteCardWithSearch;
