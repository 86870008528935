import { advFormReducer } from "@adv-libs/adv-form";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { modalReducer } from "../../features/Modal/reducer";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  devTools: process.env.NODE_ENV === "development",
  reducer: {
    ...advFormReducer,
    ...modalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
