import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import API from "../../api/API";
import { UserGetModulesAPIResponseItem } from "../../api/userAPI";
import { SpinnerWithProgressInstance } from "../../components/Spinner/SpinnerWithProgress";
import LayoutError from "../../layouts/LayoutError";
import LayoutLoader from "../../layouts/LayoutLoader";
import { useCompanyContext } from "../Companies/CompanyContext";

const ModulesContext =
  React.createContext<UserGetModulesAPIResponseItem[]>(null);

export interface ModulesContextProviderProps {}

const ModulesContextProvider: React.FC<ModulesContextProviderProps> = (
  props
) => {
  const [contextValue, setContextValue] =
    useState<UserGetModulesAPIResponseItem[]>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const spinnerRef = useRef<SpinnerWithProgressInstance>();

  const companyContext = useCompanyContext();

  const getContextData = useCallback(
    async (clientDbId: string) => {
      try {
        setError(null);
        setLoading(true);
        spinnerRef.current?.start();

        const result = await API.user.getModules({
          clientDbId: clientDbId,
        });

        setContextValue(result);

        spinnerRef.current?.done();
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    getContextData(companyContext.company.clientDbId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContext]);

  const value = useMemo(() => {
    return contextValue;
  }, [contextValue]);

  return (
    <ModulesContext.Provider value={value}>
      {error ? (
        <LayoutError error={error} />
      ) : value && !loading ? (
        props.children
      ) : (
        <LayoutLoader ref={spinnerRef} />
      )}
    </ModulesContext.Provider>
  );
};

const useModulesContext = () => {
  return useContext(ModulesContext);
};

export { ModulesContextProvider, useModulesContext };
