type EventType =
  | "error"
  | "clear-error"
  | "beforesubmit"
  | "aftersubmit"
  | "fieldchanged"
  | "toast-error"
  | "action-button"
  | "print-report";

class Events {
  private listeners: Record<string, ((...args) => any)[]> = {};

  constructor() {
    this.on = this.on.bind(this);
  }

  public emit(type: EventType, ...args) {
    if (this.listeners[type]) {
      this.listeners[type].forEach((listener) => listener(...args));
    }
  }

  public on(type: EventType, listener: (...args) => any) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }

    this.listeners[type].push(listener);
  }
}

const events = new Events();

export default events;
