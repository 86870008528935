import {
  AdvFormFieldWrapper,
  AdvSelect2,
  AdvSimpleSelect,
} from "@adv-libs/r365-ui";
import { useIsMobile, useSecondEffect } from "@adv-libs/utils";
import React, { useLayoutEffect, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { JobPositionListAPIResponseItem } from "../../api/businessTripAPI";
import { ROUTE_BUSINESS_TRIP, ROUTE_BUSINESS_TRIPS } from "../../app/routes";
import ErrorMessage from "../../components/ErrorMessage";
import NoDataCard from "../../components/NoDataCard";
import Spinner from "../../components/Spinner/Spinner";
import StateLoader from "../../components/StateLoader";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { persistentJobPosition } from "../../features/PersistentJobPosition/persistentJobPosition";
import useI18n from "../../hooks/useI18n";
import { minScreenLength } from "../../style/theme";
import JobPositionBlock from "./components/JobPositionBlock";

export interface MyBusinessTripsSelectorProps {
  onJobPositionChanged: (jobPosition: JobPositionListAPIResponseItem) => any;
  jobPosition: JobPositionListAPIResponseItem;
}

const MyBusinessTripsSelector: React.FC<MyBusinessTripsSelectorProps> = (
  props
) => {
  const { t, pt } = useI18n();

  const companyContext = useCompanyContext();

  const cacheKeepOn = useMemo(() => {
    return [ROUTE_BUSINESS_TRIPS.path, ROUTE_BUSINESS_TRIP.path];
  }, []);

  const isMobile = useIsMobile(minScreenLength);

  const [state] = useAPI(
    API.businessTrips.listJobPositions,
    {
      autoStart: {
        clientDbId: companyContext.company.clientDbId,
        page: 1,
        upcoming: true,
      },
      cache: {
        id: "businessTrips-job-position",
        keepOn: cacheKeepOn,
      },
    },
    [companyContext.company.clientDbId]
  );

  useLayoutEffect(() => {
    if (state.data && state.data.length && !props.jobPosition) {
      const defaultJobPositionId = persistentJobPosition.getValue();

      let defaultJobPosition;

      if (defaultJobPositionId) {
        defaultJobPosition = state.data.find(
          (item) => item.id === defaultJobPositionId
        );
      }

      defaultJobPosition = defaultJobPosition ?? state.data[0];

      props.onJobPositionChanged(defaultJobPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  useSecondEffect(() => {
    persistentJobPosition.setValue(
      props.jobPosition ? props.jobPosition.id : null
    );
  }, [props.jobPosition]);

  if (state.error) {
    return <ErrorMessage error={state.error} noMargin />;
  }

  if (state.isLoading || (state.data?.length && !props.jobPosition)) {
    return <Spinner />;
  }

  return (
    <MyBusinessTripsSelectorStyled>
      <StateLoader
        error={state.error}
        isLoading={
          state.isLoading || (state.data?.length && !props.jobPosition)
        }
      >
        {() => {
          if (props.jobPosition) {
            return (
              <>
                <Header>
                  <AdvFormFieldWrapper
                    padding={false}
                    size={{
                      xs: 12,
                      lg: 6,
                    }}
                  >
                    {isMobile ? (
                      <AdvSimpleSelect
                        fieldName="job-position"
                        label={t("Job position")}
                        value={props.jobPosition}
                        onCommit={props.onJobPositionChanged}
                        getItems={state.data}
                      />
                    ) : (
                      <AdvSelect2
                        fieldName="job-position"
                        label={t("Job position")}
                        value={props.jobPosition}
                        onCommit={props.onJobPositionChanged}
                        getItems={state.data}
                        paginated={false}
                        filter="fuzzy"
                      />
                    )}
                  </AdvFormFieldWrapper>
                </Header>
                <JobPositionBlock
                  requestButton
                  item={props.jobPosition}
                  responsive
                />
              </>
            );
          } else {
            return (
              <NoDataCard>
                {pt("business trips", "No information available")}
              </NoDataCard>
            );
          }
        }}
      </StateLoader>
    </MyBusinessTripsSelectorStyled>
  );
};

const Header = styled.div`
  .r365-form-field-wrapper {
    margin-bottom: 8px;
  }
`;

const MyBusinessTripsSelectorStyled = styled.div``;

export default MyBusinessTripsSelector;
