import { RootState } from "../useStore";
import UserRoles from "./UserRoles";

export const userId = (state: RootState) => state.user.userId;

export const isLoggedIn = (state: RootState) => !!state.user.userId;

export const isSystemAdmin = (state: RootState) =>
  state.user.role?.toUpperCase() === UserRoles.ROLE_ADMIN;

export const isClientAdmin = (state: RootState) =>
  state.user.role?.toUpperCase() === UserRoles.ROLE_CLIENT_ADMIN;

export const isAdmin = (state: RootState) =>
  state.user.role?.toUpperCase() === UserRoles.ROLE_ADMIN ||
  state.user.role?.toUpperCase() === UserRoles.ROLE_CLIENT_ADMIN;
