import { Button } from "@adv-libs/r365-ui";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ROUTE_MAIN } from "../../../app/routes";
import { setUrlWithCompanyId } from "../../../utils/path";
import { createGoSafeBack } from "../../Router/goSafeBack";

export interface BackButtonProps {}

const BackButtonIcon = ["arrow-left", 2174];

const BackButton: React.FC<BackButtonProps> = (props) => {
  const location = useLocation();

  const isVisible = location.pathname !== ROUTE_MAIN.path;

  return (
    <BackButtonStyled visible={isVisible}>
      <Button
        onClick={createGoSafeBack(setUrlWithCompanyId(ROUTE_MAIN.path))}
        icon={BackButtonIcon}
        gray
      ></Button>
    </BackButtonStyled>
  );
};

export const BackButtonStyled = styled.div<{ visible: boolean }>`
  width: 40px;
  height: 40px;
  /* background: ${({ theme }) => theme.greyColor}; */
  border-radius: 6px;

  will-change: margin-left;
  margin-left: ${(props) => (props.visible ? 0 : -56)}px;
  transition: margin-left 0.1s linear;

  .r365-button__content {
    width: 40px !important;
    height: 40px !important;

    .r365-button__icon {
      padding: 0 !important;
    }
  }
`;

export default BackButton;
