import {
  AdvForm,
  AdvFormAPI,
  AdvFormOnFieldChangeCallback,
} from "@adv-libs/adv-form";
import { toast } from "@adv-libs/r365-ui";
import React, { useCallback } from "react";
import serializeError from "../../features/Error/serializeError";
import scrollToFirstError from "../../utils/scrollToFirstError";
import MainFormError from "./MainFormError";
import setErrorToForm from "./setErrorToForm";
import validateForm from "./validateForm";

export interface FormProps {
  name?: string;
  onSubmit?: (values: Record<string, any>, formAPI: AdvFormAPI) => any;
  onFieldChange?: AdvFormOnFieldChangeCallback;
  initialValues?: Record<any, any>;
  validation?: any;
  validationContext?: any;
  toastifyError?: boolean;
  showGenericError?: boolean;
  formElementProps?: any;
  onFormReady?:
    | ((formAPI: AdvFormAPI) => any)
    | React.MutableRefObject<AdvFormAPI>;
}

const Form: React.FC<FormProps> = (props) => {
  const { onSubmit } = props;

  const onFormReady = useCallback(
    (formAPI: AdvFormAPI) => {
      if (typeof props.onFormReady === "function") {
        props.onFormReady(formAPI);
      } else if (props.onFormReady) {
        props.onFormReady.current = formAPI;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onFormReady]
  );

  const handleSubmit = useCallback(
    async (values, formAPI: AdvFormAPI) => {
      try {
        if (props.validation) {
          await validateForm(values, props.validation, props.validationContext);
        }

        formAPI.clearMessages();

        toast.dismiss();

        if (typeof onSubmit === "function") {
          formAPI.setSubmitting(true);
          await onSubmit(values, formAPI);
        }
      } catch (err) {
        const serializedError = serializeError(err);
        formAPI.clearMessages();
        setErrorToForm(formAPI, serializedError, props.toastifyError);

        scrollToFirstError();
      }
      if (!formAPI.isDisposed()) {
        formAPI.setSubmitting(false);
      }
    },
    [onSubmit, props.toastifyError, props.validation, props.validationContext]
  );

  return (
    <AdvForm
      useNativeForm
      formElementProps={props.formElementProps}
      formName={props.name}
      onSubmit={handleSubmit}
      initialValues={props.initialValues}
      onFormReady={onFormReady}
      onFieldChange={props.onFieldChange}
    >
      {props.showGenericError ? <MainFormError /> : null}

      {props.children}
    </AdvForm>
  );
};

Form.defaultProps = {
  toastifyError: true,
  showGenericError: true,
};

export default Form;
