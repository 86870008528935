import { AdvStaticStatus } from "@adv-libs/r365-ui";
import React from "react";
import styled from "styled-components";
import { ApproveHistoryAPIResponseItem } from "../../../../api/types";
import useI18n from "../../../../hooks/useI18n";
import breakpoints from "../../../../style/breakpoints";
import assureNumber from "../../../../utils/assureNumber";
import statusIconMap from "../../../MyTasks/statusIconMap";
import statusMap from "./statusMap";

export interface MyApproveHistoryCardProps {
  item: ApproveHistoryAPIResponseItem;
}

const MyApproveHistoryCard: React.FC<MyApproveHistoryCardProps> = (props) => {
  const { pt } = useI18n();

  return (
    <TaskApproveHistoryCardStyled>
      <div className="top">
        <div className="left">
          <div className="status">
            <AdvStaticStatus
              id={assureNumber(props.item.result)}
              name={pt("approvalsHistory", statusMap[props.item.result])}
              icon={statusIconMap[props.item.result]}
              iconSize={14}
              responsive={breakpoints.xs}
              narrow
            />
          </div>
          {props.item.statusName?.trim?.() ? (
            <div className="dynamic-status">
              <AdvStaticStatus
                template={false}
                background={props.item.statusColor}
                color={"#313131"}
                name={props.item.statusName}
                narrow
              />
            </div>
          ) : null}
        </div>
        <div className="right">
          <div className="date">{props.item.date}</div>
        </div>
      </div>
      <div className="bottom">
        <div className="user">{props.item.user}</div>
        <div className="comment">{props.item.comment}</div>
      </div>
    </TaskApproveHistoryCardStyled>
  );
};

const TaskApproveHistoryCardStyled = styled.div`
  /** Layout */
  display: flex;
  flex-direction: column;
  gap: 8px;

  .top {
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      gap: 6px;
      display: flex;
      align-items: center;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  @media screen and (max-width: 460px) {
    .top {
      flex-direction: column;
      align-items: initial;
      justify-content: initial;
    }
  }

  /** Styles */
  .top {
    flex-wrap: wrap;

    .status,
    .dynamic-status {
      .r365-status {
        height: 24px;

        &__label {
          font-weight: 600;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .date {
      color: #737373;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      padding-left: 16px;
    }
  }

  .bottom {
    border-left: 1px solid #e6e6e6;
    padding-left: 14px;
    padding-bottom: 6px;

    .comment {
      color: #737373;
      font-size: 14px;
      font-weight: 400;
    }

    .user {
      color: #292e3a;
      font-weight: 600;
      font-size: 14px;
    }
  }
`;

export default MyApproveHistoryCard;
