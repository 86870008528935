import { Button } from "@adv-libs/r365-ui";
import React from "react";
import spacetime from "spacetime";
import styled, { css } from "styled-components";
import { JobPositionListAPIResponseItem } from "../../../api/vacationsAPI";
import { ROUTE_VACATIONS, ROUTE_VACATION_REQUEST } from "../../../app/routes";
import useI18n from "../../../hooks/useI18n";
import breakpoints from "../../../style/breakpoints";
import { setUrlWithCompanyId } from "../../../utils/path";
import VacationCard from "./VacationCard";
export interface JobPositionBlockProps {
  listButton?: boolean;
  requestButton?: boolean;
  responsive?: boolean;
  item: JobPositionListAPIResponseItem;
}

const JobPositionBlock: React.FC<JobPositionBlockProps> = (props) => {
  const { t } = useI18n();

  return (
    <JobPositionBlockStyled responsive={props.responsive}>
      <TopGroup>
        <JobPositionHeader>
          <div className="job-position">
            {t("Job position")}: <b>{props.item.name}</b>
          </div>
          <div className="started-from">
            {t("Hired on")}:{" "}
            <b>{spacetime(props.item.startedFrom).format("iso-short")}</b>
          </div>
        </JobPositionHeader>
        <AccruedVacations>
          <div className="accrued-item">
            <div className="accrued-title">
              <div className="accrued-title--primary">
                {t("Accrued vacations")}
              </div>
              <small>({t("till this day")})</small>
            </div>
            <div className="accrued-value-container">
              <div className="accrued-value">
                {props.item.accrued} {t("day(s)")}
              </div>
            </div>
          </div>
          <div className="accrued-item">
            <div className="accrued-title">
              <div className="accrued-title--primary">
                {t("Accrued till")} {props.item.accruedYearEnd}
              </div>
              <small>({t("upcoming vacations not included")})</small>
            </div>
            <div className="accrued-value-container">
              <div className="accrued-value">
                {props.item.accruedToYearEnd} {t("day(s)")}
              </div>
            </div>
          </div>
        </AccruedVacations>
      </TopGroup>
      {props.item.upcoming?.length > 0 ? (
        <>
          <UpcomingVacations>
            <div className="title">{t("Upcoming vacations")}</div>
            <div className="next-date">
              {t("Upcoming vacation on")}:{" "}
              <b>{spacetime(props.item.nextVacationOn).format("iso-short")}</b>
            </div>
          </UpcomingVacations>
          <UpcomingVacationsContainer>
            {props.item.upcoming.map((item, key) => {
              return <VacationCard key={key} item={item} showStatus />;
            })}
          </UpcomingVacationsContainer>
        </>
      ) : null}
      {props.listButton || props.requestButton ? (
        <ButtonsContainer responsive={props.responsive}>
          {props.listButton ? (
            <Button fill link={setUrlWithCompanyId(ROUTE_VACATIONS.path)}>
              {t("Vacations")}
            </Button>
          ) : null}
          {props.requestButton ? (
            <Button
              fill
              primary
              link={setUrlWithCompanyId(ROUTE_VACATION_REQUEST.path)}
            >
              {t("Vacations request")}
            </Button>
          ) : null}
        </ButtonsContainer>
      ) : null}
    </JobPositionBlockStyled>
  );
};

export const JobPositionHeader = styled.div`
  margin-bottom: 18px;

  .job-position {
    margin-bottom: 4px;

    b {
      font-weight: 600;
    }
  }

  .started-from {
    color: #8d8d8d;

    b {
      color: #737373;
      font-weight: 600;
    }
  }
`;

export const AccruedVacations = styled.div`
  margin-top: 8px;

  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 8px;

  .accrued-item {
    background: #ccf0e3;
    border-radius: 4px;
    padding: 8px 8px;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;

    .accrued-title {
      display: flex;
      flex-direction: column;
      padding: 4px 0;
      gap: 4px;

      font-size: 14px;

      &--primary {
        font-weight: 600;
      }

      small {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .accrued-value-container {
      display: flex;
      align-items: center;

      .accrued-value {
        background: #effff9;
        min-width: 100px;
        border-radius: 4px;
        color: #1a9d77;
        font-weight: 600;
        font-size: 16px;
        flex-grow: 1;

        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 4px;
      }
    }

    @media screen and (min-width: ${breakpoints.xs}px) {
      padding: 16px 8px;
    }
  }

  @media screen and (min-width: ${breakpoints.xs}px) {
    grid-auto-flow: column;

    .accrued-item {
      padding: 8px 8px;
      grid-column-gap: 8px;
    }
  }
`;

export const TopGroup = styled.div``;

const UpcomingVacations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;

  .title {
    font-weight: 600;
    color: black;
    margin-bottom: 6px;
  }

  .next-date {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;

    b {
      display: block;
      color: #1a9d77;
      font-weight: 600;
      white-space: nowrap;
    }
  }
`;

const UpcomingVacationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsContainer = styled.div<{ responsive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;
  gap: 10px;

  .r365-button {
    flex: 1 1 0px;
  }

  ${(props) =>
    props.responsive
      ? css`
          @media screen and (min-width: 1000px) {
            max-width: 300px;
            margin-right: 0;
            margin-left: auto;
          }
        `
      : ""};
`;

export const JobPositionBlockStyled = styled.div<{ responsive?: boolean }>`
  padding-top: 8px;
`;

export default JobPositionBlock;
