import React, { useMemo, useCallback } from "react";
import { ROUTE_DOCUMENTS, ROUTE_FORMS_NEW } from "../../app/routes";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import MyFormsList from "./MyFormsList";
import styled from "styled-components";
import { Button } from "@adv-libs/r365-ui";
import { setUrlWithCompanyId } from "../../utils/path";
import { FloatingButton } from "../../components";
import { useIsMobile } from "@adv-libs/utils";
import { minScreenLength } from "../../style/theme";
import { getHistory } from "../../features/Router/history";

export interface MyFormsProps {}

const MyForms: React.FC<MyFormsProps> = (props) => {
  const { t } = useI18n();
  const isMobile = useIsMobile(minScreenLength);

  const keepOn = useMemo(() => {
    return [ROUTE_DOCUMENTS.path];
  }, []);

  const handleClick = useCallback(async () => {
    getHistory().push(setUrlWithCompanyId(ROUTE_FORMS_NEW.path));
  }, []);

  return (
    <>
      <PageTitle>{t("Document forms")}</PageTitle>
      <ScrollRestoration id="documents" keepOn={keepOn} />
      <Grid>
        <GridCol col-s="2 / -2" col-xl="3 / -3" col-xxl="4 / -4">
          <PageContent>
            <MyDocumentsStyled>
              {isMobile ? (
                <FloatingButton onClick={handleClick} />
              ) : (
                <ButtonStyled>
                  <Button
                    fill
                    primary
                    iconSrc="/icons/icon-add.svg"
                    link={setUrlWithCompanyId(ROUTE_FORMS_NEW.path)}
                  >
                    {t("New form")}
                  </Button>
                </ButtonStyled>
              )}
              <MyFormsList />
            </MyDocumentsStyled>
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

const ButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;

  .r365-button {
    min-width: 120px;
  }

  .r365-button * {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;

const MyDocumentsStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

export default MyForms;
