import i18n from "i18next";
import { string, number, date, array, object } from "yup";
import types from "./FieldTypes";
import { assureArray } from "@adv-libs/utils";

const createValidationObject = (
  list: { schema: { [key: string]: string } | { [key: string]: string }[] }[]
) => {
  const val = {};

  for (const i in list) {
    const row = list[i].schema ? assureArray(list[i].schema) : [];

    row.forEach((r) => {
      const type = r.type && r.type.toLowerCase();
      if (!type) return;

      if (type === types.LABEL || type === types.HIDDEN) return;

      switch (type) {
        case types.DATERANGE:
          val[r.id] = array().nullable();
          break;

        case types.DATE:
          val[r.id] = date().nullable();
          break;

        case types.SELECT:
        case types.RADIO:
          val[r.id] = object();
          break;

        case types.NUMBER:
          val[r.id] = number();
          break;

        default:
          val[r.id] = string();
      }

      //minimums
      if (r.min) {
        if (type === types.NUMBER)
          val[r.id] = val[r.id].min(+r.min, i18n.t("Minimum value ${min}")); // eslint-disable-line

        if (type === types.TEXT || type === types.TEXTAREA)
          val[r.id] = val[r.id].min(
            +r.min,
            i18n.t("${min} characters minimum") // eslint-disable-line
          );

        if (type === types.DATE)
          val[r.id] = val[r.id].min(
            r.min,
            i18n.t("The value must be later than ${min}") // eslint-disable-line
          );

        if (type === types.DATERANGE) {
          const minDate = r.min;
          val[r.id] = val[r.id].test({
            name: "testMin",
            exclusive: false,
            params: { minDate },
            message: i18n.t("The value must be later than ${minDate}"), // eslint-disable-line
            test: (value) =>
              value == null ||
              (value && value.length > 0 && value[0] >= minDate),
          });
        }
      }

      //maximums;
      if (r.max) {
        if (type === types.NUMBER)
          val[r.id] = val[r.id].max(
            +r.max,
            i18n.t("Maximum value ${max}") // eslint-disable-line
          );

        if (type === types.TEXT || type === types.TEXTAREA)
          val[r.id] = val[r.id].max(
            +r.max,
            i18n.t("Input must be no longer than ${max} characters") // eslint-disable-line
          );

        if (type === types.DATE)
          val[r.id] = val[r.id].max(
            r.max,
            i18n.t("Value must be earlier than ${max}") // eslint-disable-line
          );

        if (type === types.DATERANGE) {
          const maxDate = r.max;
          val[r.id] = val[r.id].test({
            name: "testMax",
            exclusive: false,
            params: { maxDate },
            message: i18n.t("Value must be earlier than ${maxDate}"), // eslint-disable-line
            test: (value) =>
              value == null ||
              (value && value.length > 0 && value[1] <= maxDate),
          });
        }
      }

      if (r.required && r.required.toLowerCase() === "true")
        val[r.id] = val[r.id].required(i18n.t("Field is required"));

      if (r.requiredWhen) {
        val[r.id] = val[r.id].when("$" + r.id, ([value], schema) => {
          return value === true
            ? schema.required(i18n.t("Field is required"))
            : schema.notRequired();
        });
      }
    });
  }
  return val;
};

export default createValidationObject;
