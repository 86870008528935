import styled from "styled-components";

const LoginLayout = styled.div`
  width: 100vw;
  margin: 0 auto;
  height: 100vh;

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    min-width: ${(props) => props.theme.minScreenLength}px;
    max-width: 1000px;
  }
`;

export default LoginLayout;
