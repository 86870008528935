import * as History from "history";
import API from "../api/API";
import { ROUTE_LOGIN } from "../app/routes";
import { useStore } from "../app/store";
import { deleteTokenExpirationAndRememberDate } from "../features/AuthToken/AuthToken";
import toastError from "../features/ToastMessage/toastError";

const actionLogout = (
  history: History.History<History.LocationState>,
  error?: any
) => {
  const location = history.location;
  const from = location.pathname + location.search;

  API.auth.logout().catch(console.error);
  deleteTokenExpirationAndRememberDate();

  useStore.getState().clearUser();

  if (error) {
    toastError(error);
  }

  history.replace(ROUTE_LOGIN.path, {
    from: from,
    redirect: true,
  });
};

export default actionLogout;
