import { Icon } from "@adv-libs/icons";
import { Button } from "@adv-libs/r365-ui";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import API from "../../../api/API";
import { CardStyled } from "../../../components/Card/Card";
import DataView, {
  DataViewStyled,
} from "../../../components/DataView/DataView";
import Spinner from "../../../components/Spinner/Spinner";
import toastError from "../../../features/ToastMessage/toastError";
import useI18n from "../../../hooks/useI18n";
import MyApproveHistory from "../../shared/MyApproveHistory/MyApproveHistory";
import { CalendarEventDetailedType } from "../types";

export interface CalendarEventDetailViewProps {
  event: CalendarEventDetailedType;
  title: string;
  user: string;
  clientDbId: string;
  onClose: () => any;
}

const CalendarEventDetailView: React.FC<CalendarEventDetailViewProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [schema, setSchema] = useState(null);
  const [showHistory, setShowHistory] = useState(null);

  const { t } = useI18n();

  const fetchEventDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      if (props.event.link) {
        const response = await API.calendar.getCalendarEventDetail({
          id: props.event.link,
          clientDbId: props.clientDbId,
          user: props.user,
        });
        setSchema(response.schema);
        setShowHistory(response.showHistory);
      } else {
        setSchema([
          {
            type: "block",
            value: t("No data available"),
          },
        ]);
      }
    } catch (err) {
      toastError(err);
    }
    setIsLoading(false);
  }, [props.clientDbId, props.event.link, props.user, t]);

  useEffect(() => {
    fetchEventDetail();
  }, [fetchEventDetail]);

  return (
    <CalendarEventDetailViewStyled>
      <Header>
        <Title>{props.title}</Title>
        <CloseButton onClick={props.onClose}>
          <Icon icon="close" />
        </CloseButton>
      </Header>
      <Content>
        {isLoading ? (
          <Spinner />
        ) : (
          <DataView schema={schema} clientDbId={props.clientDbId} />
        )}
        {showHistory ? (
          <MyApproveHistory
            id={props.event.link}
            clientDbId={props.clientDbId}
            user={props.user}
            request={API.calendar.getCalendarEventDetailHistory}
          />
        ) : null}
      </Content>
      <Footer>
        <Button primary onClick={props.onClose}>
          {t("Close")}
        </Button>
      </Footer>
    </CalendarEventDetailViewStyled>
  );
};

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding-right: 40px;
`;

const Header = styled.div`
  position: relative;
  padding-bottom: 24px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
  cursor: pointer;
`;

const Footer = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${CardStyled} {
    border: 0;
    padding: 0;
  }

  ${DataViewStyled} {
    width: 100%;
  }
`;

const CalendarEventDetailViewStyled = styled.div`
  background: white;
  padding: 16px 16px;
  border-radius: 6px;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export default CalendarEventDetailView;
