import { AuthAPIRequest } from "./authAPI";
import CalendarAPIRequest from "./calendarAPI";
import CompaniesAPIRequest from "./companiesAPI";
import FileAPIRequest from "./fileAPI";
import TasksAPIRequest from "./tasksAPI";
import UserAPIRequest from "./userAPI";
import VacationsAPIRequest from "./vacationsAPI";
import BusinessTripAPIRequest from "./businessTripAPI";
import DocumentsAPIRequest from "./documentsAPI";
import FormsAPIRequest from "./formsAPI";

class API {
  static auth = AuthAPIRequest();
  static user = UserAPIRequest();
  static companies = CompaniesAPIRequest();
  static tasks = TasksAPIRequest();
  static vacations = VacationsAPIRequest();
  static files = FileAPIRequest();
  static calendar = CalendarAPIRequest();
  static businessTrips = BusinessTripAPIRequest();
  static documents = DocumentsAPIRequest();
  static forms = FormsAPIRequest();
}

export default API;
