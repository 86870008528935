import { ReactNode } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "../components";
import { CompanyContextProvider } from "../features/Companies/CompanyContext";
import { ModulesContextProvider } from "../features/ModulesList/ModulesContext";
import HeaderMenu from "../modules/shared/HeaderMenu/HeaderMenu";
import SideMenu, { SideMenuStyled } from "../modules/shared/SideMenu/SideMenu";
import breakpoints from "../style/breakpoints";

interface IBaseLayout {
  children: ReactNode;
}

export default function BaseLayout(props: IBaseLayout) {
  const { children } = props;

  return (
    <ErrorBoundary>
      <CompanyContextProvider>
        <ModulesContextProvider>
          <HeaderMenu />
          <PageContentLayout>
            <SideMenu />
            <LayoutContainer id="scroll-container">{children}</LayoutContainer>
          </PageContentLayout>
        </ModulesContextProvider>
      </CompanyContextProvider>
    </ErrorBoundary>
  );
}

const LayoutContainer = styled.div`
  padding-top: 2vh;
  padding-bottom: 120px;

  @media screen and (min-width: ${breakpoints.l}px) {
    padding-top: 5vh;
  }

  height: 100%;

  overflow-y: auto;
`;

const PageContentLayout = styled.div`
  position: relative;

  overflow: hidden;

  height: 100%;

  ${SideMenuStyled} {
    display: none;
  }

  padding-top: calc(${({ theme }) => theme.headerHeight}px + 64px);

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    padding-top: ${({ theme }) => theme.headerHeight}px;

    display: grid;
    grid-template-columns: ${({ theme }) => theme.leftMenuWidth}px 1fr;

    ${SideMenuStyled} {
      display: flex;
    }
  }
`;
