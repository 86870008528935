import { AdvStaticStatus } from "@adv-libs/r365-ui";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DocumentsListAPIResponseItem } from "../../../api/documentsAPI";
import { ROUTE_DOCUMENT } from "../../../app/routes";
import { setUrlWithCompanyId } from "../../../utils/path";

export interface DocumentCardProps {
  item: DocumentsListAPIResponseItem;
}

const DocumentCard: React.FC<DocumentCardProps> = (props) => {
  return (
    <DocumentCardStyled>
      <Link
        to={setUrlWithCompanyId(
          ROUTE_DOCUMENT.createPath({
            id: props.item.id,
            formId: props.item.formId,
          })
        )}
      >
        <Top>
          <LeftSide>
            <div className="date">
              <b>{props.item.item1}</b>
            </div>
            <div className="title">{props.item.item2}</div>
            {props.item.item3 ? (
              <div className="location">{props.item.item3}</div>
            ) : null}
          </LeftSide>
          {props.item.statusName?.trim?.() ? (
            <RightSide>
              <div className="status" style={{ color: props.item.statusColor }}>
                <AdvStaticStatus
                  id={props.item.statusId}
                  name={props.item.statusName}
                  background={props.item.statusColor}
                  narrow
                  template={false}
                  color="#313131"
                />
              </div>
            </RightSide>
          ) : null}
        </Top>
      </Link>
    </DocumentCardStyled>
  );
};

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RightSide = styled.div`
  order: 1;
  display: flex;
`;

const LeftSide = styled.div`
  order: 2;
`;

const DocumentCardStyled = styled.div`
  background: ${({ theme }) => theme.greyColor};
  border-radius: 6px;
  transition: background 0.05s linear;

  &:hover {
    background: #e5e5e8;
  }

  a {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 14px 10px 10px 16px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .location {
    color: #737373;
    font-size: 12px;
    font-weight: 600;
  }

  .date {
    color: #737373;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    margin-bottom: 4px;

    b {
      color: #292e3a;
      font-weight: 600;
    }
  }

  .status {
    .r365-status {
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      min-width: 70px;
      justify-content: center;
    }
  }

  @media screen and (min-width: 480px) {
    ${Top} {
      flex-direction: row;
      justify-content: space-between;

      ${LeftSide} {
        order: 1;
      }

      ${RightSide} {
        order: 2;
      }
    }
  }
`;

export default DocumentCard;
