import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvFormFieldWrapper,
  AdvRadioGroup,
  AdvRadioGroupProps,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";
import styled from "styled-components";

export interface FormRadioGroupProps
  extends Omit<AdvRadioGroupProps, "value" | "onCommit"> {
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
  size?: any;
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = (props) => {
  const { name, size, ...controlProps } = props;

  const formIsSubmitting = useFormIsSubmitting();

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  return (
    <AdvFormFieldStyled>
      <AdvFormField
        fieldName={name}
        control={AdvRadioGroup}
        controlProps={controlProps}
        wrapper={AdvFormFieldWrapper}
        wrapperProps={wrapperProps}
        disabled={formIsSubmitting}
      ></AdvFormField>
    </AdvFormFieldStyled>
  );
};

const AdvFormFieldStyled = styled.div`
  .r365-control-radio {
    flex-wrap: wrap;
    height: auto;
  }
`;

export default FormRadioGroup;
