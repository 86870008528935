import React from "react";
import styled from "styled-components";

export interface FullWidthContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const FullWidthContainer: React.FC<FullWidthContainerProps> = (props) => {
  return <FullWidthContainerStyled {...props}></FullWidthContainerStyled>;
};

export const FullWidthContainerStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

export default FullWidthContainer;
