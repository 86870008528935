import Client from "./core/Client";
import APIProcedures from "./Procedures";
import createAPIHandler from "./utils/createAPIHandler";
import { assureArray } from "@adv-libs/utils";

export type UserGetAPIPayload = {
  clientDbId: string;
};

export type UserGetAPIResponse = {
  app: {
    email: string;
    kodas_rs: string;
    pavarde: string;
    tel: string;
    vardas: string;
    lygis: string;
  };
};

export type UserGetModulesAPIResponseItemOriginal = {
  modulis: string;
  arRodyti: string;
  arAktyvus: string;
  klaida: string;
};

export type UserGetModulesAPIResponseItem = {
  module: string;
  show: boolean;
  disabled: boolean;
  errorMessage: string;
};

const UserAPIRequest = () => {
  return {
    get: createAPIHandler(
      async (payload: UserGetAPIPayload): Promise<UserGetAPIResponse> => {
        const response = await Client.post<UserGetAPIResponse>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_GET_ASM_INF,
            clientDbId: payload.clientDbId,
            data: {},
          }
        );
        return response.data;
      }
    ),
    getModules: createAPIHandler(
      async (
        payload: UserGetAPIPayload
      ): Promise<UserGetModulesAPIResponseItem[]> => {
        const response = await Client.post<{
          app:
            | UserGetModulesAPIResponseItemOriginal
            | UserGetModulesAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_GET_USER_MODULES,
          clientDbId: payload.clientDbId,
          data: {},
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((item) => ({
          module: item.modulis,
          show: item.arRodyti === "1",
          disabled: item.arAktyvus === "0",
          errorMessage: item.klaida,
        }));
      }
    ),
  };
};

export default UserAPIRequest;
