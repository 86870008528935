import { Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../components";
import { getTokenExpirationDate } from "../features/AuthToken/AuthToken";
import { LoginLayout, SettingsLayout, SettingsLayoutMobile } from "../layouts";
import Auth from "../modules/Auth/Auth";
import CalendarPage from "../modules/Calendar/CalendarPage";
import CompaniesList from "../modules/CompaniesList/CompaniesList";
import Dashboard from "../modules/Dashboard/Dashboard";
import Logout from "../modules/Logout";
import MyTask from "../modules/MyTasks/MyTask";
import MyTaskApprove from "../modules/MyTasks/MyTaskApprove";
import MyTasks from "../modules/MyTasks/MyTasks";
import UserSettings from "../modules/UserSettings/UserSettings";
import MyVacation from "../modules/Vacations/MyVacation";
import MyVacations from "../modules/Vacations/MyVacations";
import VacationRequest from "../modules/Vacations/VacationRequest";
import MyBusinessTrip from "../modules/BusinessTrips/MyBusinessTrip";
import MyBusinessTrips from "../modules/BusinessTrips/MyBusinessTrips";
import BusinessTripRequest from "../modules/BusinessTrips/BusinessTripRequest";
import DocumentsRequest from "../modules/Documents/MyDocumentForm";
import MyDocuments from "../modules/Documents/MyDocuments";
import MyDocument from "../modules/Documents/MyDocument";
import MyForms from "../modules/Forms/MyForms";
import MyForm from "../modules/Forms/MyForm";
import NewForm from "../modules/Forms/NewForm";
import * as routes from "./routes";
import { useStore } from "./store";
import { isLoggedIn } from "./store/selectors/userSelector";
import SettingsMenu from "../modules/shared/SettingsMenu/SettingsMenu";
import { minScreenLength } from "../style/theme";
import { useIsMobile } from "@adv-libs/utils";

export default function AppRoutes() {
  const expCookie = getTokenExpirationDate();
  const isUserLoggedIn = useStore(isLoggedIn) && !!expCookie;
  const isMobile = useIsMobile(minScreenLength);
  const loginPath = routes.ROUTE_LOGIN.path;
  const userSettingsPath = routes.ROUTE_USER_SETTINGS.path;

  return (
    <Switch>
      <PrivateRoute
        path={routes.ROUTE_LOGIN.path}
        isAllowed={!isUserLoggedIn}
        redirectTo={routes.ROUTE_COMPANIES.path}
        component={Auth}
        layout={LoginLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_LOGOUT.path}
        component={Logout}
        isAllowed={true}
        redirectTo={loginPath}
        layout={null}
      />
      <PrivateRoute
        path={routes.ROUTE_COMPANIES.path}
        component={CompaniesList}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
        layout={LoginLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_CALENDAR.path}
        component={CalendarPage}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_VACATION_REQUEST.path}
        component={VacationRequest}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_VACATION.path}
        component={MyVacation}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_VACATIONS.path}
        component={MyVacations}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_BUSINESS_TRIP_REQUEST.path}
        component={BusinessTripRequest}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_BUSINESS_TRIP.path}
        component={MyBusinessTrip}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_BUSINESS_TRIPS.path}
        component={MyBusinessTrips}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_DOCUMENTS_REQUEST.path}
        component={DocumentsRequest}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_DOCUMENT.path}
        component={MyDocument}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_DOCUMENTS.path}
        component={MyDocuments}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_APPROVE_TASK.path}
        component={MyTaskApprove}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_TASK.path}
        component={MyTask}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_TASKS.path}
        component={MyTasks}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />
      <PrivateRoute
        path={routes.ROUTE_FORMS_NEW.path}
        component={NewForm}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
        layout={SettingsLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_FORM.path}
        component={MyForm}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
        layout={SettingsLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_FORMS.path}
        component={MyForms}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
        layout={SettingsLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_USER_SETTINGS.path}
        component={UserSettings}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
        layout={SettingsLayout}
      />
      <PrivateRoute
        path={routes.ROUTE_SETTINGS.path}
        component={SettingsMenu}
        isAllowed={isUserLoggedIn && isMobile}
        redirectTo={!isUserLoggedIn ? loginPath : userSettingsPath}
        layout={SettingsLayoutMobile}
      />
      <PrivateRoute
        path={routes.ROUTE_MAIN.path}
        component={Dashboard}
        isAllowed={isUserLoggedIn}
        redirectTo={loginPath}
      />

      <Redirect
        to={{
          pathname: routes.ROUTE_LOGIN.path,
          state: {
            redirect: true,
          },
        }}
      />
    </Switch>
  );
}
