import { Callout } from "@adv-libs/r365-ui";
import React from "react";
import styled from "styled-components";
import serializeError from "../features/Error/serializeError";

export interface ErrorMessageProps {
  error: any;
  noMargin?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const error = serializeError(props.error);

  return (
    <ErrorMessageStyled>
      <Callout
        intent={error.intent}
        title={error && error.title}
        noMargin={props.noMargin}
      >
        {error && error.description ? <p>{error.description}</p> : null}
      </Callout>
    </ErrorMessageStyled>
  );
};

export const ErrorMessageStyled = styled.div``;

export default ErrorMessage;
