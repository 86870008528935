import { AdvFormField, useFormIsSubmitting } from "@adv-libs/adv-form";
import {
  AdvDatePicker,
  AdvDatePickerProps,
  AdvFormFieldWrapper,
} from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface FormDatePickerProps
  extends Omit<AdvDatePickerProps, "onCommit" | "value"> {
  name: string;
  size?: any;
}

const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const { name, size, ...restProps } = props;

  const formIsSubmitting = useFormIsSubmitting();

  const wrapperProps = useMemo(() => {
    return { size };
  }, [size]);

  return (
    <AdvFormField
      fieldName={name}
      control={AdvDatePicker}
      controlProps={restProps}
      wrapper={AdvFormFieldWrapper}
      wrapperProps={wrapperProps}
      disabled={formIsSubmitting}
    />
  );
};

export default React.memo(FormDatePicker);
