import React from "react";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import { useRouteMatch } from "react-router-dom";
import FormEditing from "./components/FormEditing";

export interface MyFormProps {}

const MyForm: React.FC<MyFormProps> = (props) => {
  const {
    params: { id: formId },
  } = useRouteMatch<any>();

  const companyContext = useCompanyContext();

  const [state] = useAPI(
    API.forms.get,
    {
      autoStart: {
        clientDbId: companyContext.company.clientDbId,
        formId: formId,
      },
    },
    [companyContext.company.clientDbId, formId]
  );

  return (
    <FormEditing
      initialData={state.data}
      formId={formId}
      error={state.error}
      isLoading={state.isLoading}
    />
  );
};

export default MyForm;
