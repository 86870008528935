import { useCallback } from "react";
import { useAppDispatch } from "../../../app/redux/reduxStore";
import { openModal } from "../reducer";

const useOpenModal = (modalName: string) => {
  const dispatch = useAppDispatch();

  const open = useCallback(() => {
    dispatch(openModal({ modalName }));
  }, [dispatch, modalName]);

  return open;
};

export default useOpenModal;
