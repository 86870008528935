import { AdvFormAPI } from "@adv-libs/adv-form";
import { AdvStaticStatus, Button } from "@adv-libs/r365-ui";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import API from "../../api/API";
import useAPI from "../../api/hooks/useAPI";
import { ROUTE_TASK, ROUTE_TASKS } from "../../app/routes";
import BackButtonDesktop from "../../components/BackButtonDesktop";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import DataView from "../../components/DataView/DataView";
import { FormTextareaField } from "../../components/form";
import Form from "../../components/form/Form";
import { PageContent } from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import StateLoader from "../../components/StateLoader";
import { useCacheContext } from "../../features/Cache/CacheContext";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import openConfirm from "../../features/Modal/openConfirm";
import goSafeBack, { createGoSafeBack } from "../../features/Router/goSafeBack";
import ScrollRestoration from "../../features/Router/ScrollRestoration";
import toastError from "../../features/ToastMessage/toastError";
import toastSuccess from "../../features/ToastMessage/toastSuccess";
import useI18n from "../../hooks/useI18n";
import { Grid, GridCol } from "../../style/Grid";
import assureNumber from "../../utils/assureNumber";
import { setUrlWithCompanyId } from "../../utils/path";
import scrollToFirstError from "../../utils/scrollToFirstError";
import DataViewPageSharedStyles from "../shared/DataViewPageSharedStyles";
import MyApproveHistory from "../shared/MyApproveHistory/MyApproveHistory";
import statusIconMap from "./statusIconMap";
import statusMap from "./statusMap";

export interface MyTaskViewProps {
  taskId: any;
  approve?: boolean;
}

const MyTaskView: React.FC<MyTaskViewProps> = (props) => {
  const { t, pt } = useI18n();
  const { clearCache } = useCacheContext();
  const formAPIRef = useRef<AdvFormAPI>(null);
  const companyContext = useCompanyContext();
  const [submitting, setSubmitting] = useState<"A" | "P">(null);

  const [state] = useAPI(
    API.tasks.get,
    {
      autoStart: {
        id: props.taskId,
        clientDbId: companyContext.company.clientDbId,
      },
    },
    [
      props.taskId,
      companyContext.company.clientDbId,
      companyContext.company.rivUser,
    ]
  );

  const handleSubmit = useCallback(
    async (action: "A" | "P", accept?: boolean) => {
      setSubmitting(action);
      const formAPI = formAPIRef.current;
      formAPI.clearMessages();
      const comment = formAPI.getValues("comment");
      try {
        await API.tasks.approve({
          id: props.taskId,
          action: action,
          clientDbId: companyContext.company.clientDbId,
          comment: comment,
        });
        goSafeBack(ROUTE_TASKS.path);

        clearCache();

        setTimeout(() => {
          if (action === "P") {
            if (accept) {
              toastSuccess(t("Accepted!"), { autoClose: 1000 });
            } else {
              toastSuccess(t("Approved!"), { autoClose: 1000 });
            }
          } else {
            toastSuccess(t("Declined!"), { autoClose: 1000 });
          }
        }, 1);
      } catch (err) {
        setSubmitting(null);
        toastError(err);
      }
    },
    [clearCache, companyContext.company.clientDbId, props.taskId, t]
  );

  const handleAcceptClick = useCallback(() => {
    const formAPI = formAPIRef.current;
    formAPI.clearMessages();
    handleSubmit("P", true);
  }, [handleSubmit]);

  const handleApproveClick = useCallback(async () => {
    const formAPI = formAPIRef.current;
    formAPI.clearMessages();

    const confirmed = await openConfirm({
      message: t("Are you sure to approve?"),
    });

    if (!confirmed) return;

    handleSubmit("P");
  }, [handleSubmit, t]);

  const handleDeclineClick = useCallback(async () => {
    const formAPI = formAPIRef.current;
    const comment = formAPI.getValues("comment");

    if (state.data.comment && !comment) {
      formAPI.setFieldMessages("comment", [
        { description: t("Comment is required") },
      ]);

      scrollToFirstError();
      return;
    }

    const confirmed = await openConfirm({
      message: t("Are you sure to decline?"),
      intent: "danger",
    });

    if (!confirmed) return;

    handleSubmit("A");
  }, [handleSubmit, state?.data?.comment, t]);

  const modifiedSchema = useMemo(() => {
    if (state.isLoading || state.error || !state.data) return null;
    if (props.approve) return state.data.schema;
    if (state.data.busenaComment)
      return [
        ...state.data.schema,
        {
          type: "block" as "block",
          key: t("Comment"),
          value: state.data.busenaComment,
        },
      ];
    return [...state.data.schema];
  }, [props.approve, state.data, state.error, state.isLoading, t]);

  if (state.data && state.data.status !== "1" && props.approve) {
    return <Redirect to={ROUTE_TASK.createPath({ id: props.taskId })} />;
  }

  return (
    <>
      <ScrollRestoration id="task" restoreTop />
      <Grid>
        <GridCol col-s="2 / -2" col-m="3 / -3" col-xl="4 / -4">
          <PageContent>
            <DataViewPageSharedStyles.ViewStyled>
              <Card>
                <BackButtonDesktop />
                <CardBody>
                  <StateLoader error={state.error} isLoading={state.isLoading}>
                    {() => {
                      return (
                        <>
                          <PageTitle>{state.data.title}</PageTitle>
                          <DataViewPageSharedStyles.ViewInnerStyled>
                            <div className="top">
                              <div className="left">
                                <div className="dynamic-status">
                                  <AdvStaticStatus
                                    id={assureNumber(state.data.status)}
                                    name={pt(
                                      "task",
                                      statusMap[state.data.status]
                                    )}
                                    icon={statusIconMap[state.data.status]}
                                  />
                                </div>
                              </div>
                              <div className="right">
                                {state.data.add_data?.trim?.() ? (
                                  <div className="create-date">
                                    <div className="title">{t("Created")}</div>
                                    <div className="date">
                                      {state.data.add_data}
                                    </div>
                                  </div>
                                ) : null}
                                {state.data.kor_data?.trim?.() ? (
                                  <div className="update-date">
                                    <div className="title">{t("Updated")}</div>
                                    <div className="date">
                                      {state.data.kor_data}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <DataViewPageSharedStyles.Header>
                              {state.data.title}
                            </DataViewPageSharedStyles.Header>
                            <DataView
                              schema={modifiedSchema}
                              clientDbId={companyContext.company.clientDbId}
                            />
                            <Form onFormReady={formAPIRef}>
                              {props.approve && state.data.comment ? (
                                <FormTextareaField
                                  name="comment"
                                  label={t("Comment")}
                                  maxLength={150}
                                  autoHeight={true}
                                  showLengthCount={true}
                                  rows={5}
                                  defaultValue={state.data.busenaComment}
                                />
                              ) : null}
                              {props.approve ? (
                                <DataViewPageSharedStyles.FormActions>
                                  <Grid>
                                    <GridCol
                                      col-s="2 / -2"
                                      col-m="3 / -3"
                                      col-xl="4 / -4"
                                    >
                                      {state.data.buttons === "1" ? (
                                        <>
                                          <div className="buttons-container">
                                            <Button
                                              primary
                                              fill
                                              onClick={handleAcceptClick}
                                            >
                                              {state.data.confirmButtonTitle ||
                                                t("Accept")}
                                            </Button>
                                          </div>
                                          <div className="back-button">
                                            <Button
                                              fill
                                              onClick={createGoSafeBack(
                                                setUrlWithCompanyId(
                                                  ROUTE_TASKS.path
                                                )
                                              )}
                                            >
                                              {t("Close")}
                                            </Button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="buttons-container">
                                            <Button
                                              danger
                                              dark
                                              fill
                                              onClick={handleDeclineClick}
                                              disabled={!!submitting}
                                              icon={
                                                submitting === "A"
                                                  ? ["spin", 2200]
                                                  : undefined
                                              }
                                              spin={submitting === "A"}
                                            >
                                              {t("Decline")}
                                            </Button>
                                            <Button
                                              primary
                                              fill
                                              onClick={handleApproveClick}
                                              disabled={!!submitting}
                                              icon={
                                                submitting === "P"
                                                  ? ["spin", 2200]
                                                  : undefined
                                              }
                                              spin={submitting === "P"}
                                            >
                                              {state.data.confirmButtonTitle ||
                                                t("Approve")}
                                            </Button>
                                          </div>
                                          <div className="back-button">
                                            <Button
                                              fill
                                              onClick={createGoSafeBack(
                                                setUrlWithCompanyId(
                                                  ROUTE_TASKS.path
                                                )
                                              )}
                                            >
                                              {t("Close")}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </GridCol>
                                  </Grid>
                                </DataViewPageSharedStyles.FormActions>
                              ) : (
                                <DataViewPageSharedStyles.FormActions>
                                  <Grid>
                                    <GridCol
                                      col-s="2 / -2"
                                      col-m="3 / -3"
                                      col-xl="4 / -4"
                                    >
                                      <div className="back-button">
                                        <Button
                                          fill
                                          onClick={createGoSafeBack(
                                            setUrlWithCompanyId(
                                              ROUTE_TASKS.path
                                            )
                                          )}
                                        >
                                          {t("Close")}
                                        </Button>
                                      </div>
                                    </GridCol>
                                  </Grid>
                                </DataViewPageSharedStyles.FormActions>
                              )}
                            </Form>
                          </DataViewPageSharedStyles.ViewInnerStyled>
                        </>
                      );
                    }}
                  </StateLoader>
                </CardBody>
              </Card>
            </DataViewPageSharedStyles.ViewStyled>
            {state.data && state.data.showHistory ? (
              <MyApproveHistory
                id={state.data.id}
                clientDbId={companyContext.company.clientDbId}
                user={companyContext.user.kodas_rs}
                request={API.tasks.getApproveHistory}
              />
            ) : null}
          </PageContent>
        </GridCol>
      </Grid>
    </>
  );
};

export default MyTaskView;
