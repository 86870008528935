import { assureArray } from "@adv-libs/utils";
import Client from "./core/Client";
import APIProcedures from "./Procedures";
import createAPIHandler from "./utils/createAPIHandler";
import {
  ApproveHistoryAPIPayload,
  ApproveHistoryAPIResponse,
  ApproveHistoryAPIResponseOriginal,
} from "./types";
import createValidationObject from "../modules/Documents/components/createValidationObject";
import types from "../modules/Documents/components/FieldTypes";
import i18n from "i18next";

export type DocumentsFieldItemOriginal = {
  type: string;
  caption?: string;
  id?: string;
  value?: string;
  width?: string;
  required?: string;
  data?: string;
  decimal?: string;
  max?: string;
  min?: string;
  disabled?: string;
  visible?: string;
  rows?: string;
};

export type DocumentsFieldItem = {
  type: string;
  label: string;
  name: string;
  default: string;
  size: number;
  required: boolean;
  getItems: string;
  decimalScale: number;
  max: string;
  min: string;
  disabled: boolean;
  visible: boolean;
  rows: number;
  value: any;
};

export type DocumentsFormAPIPayload = {
  clientDbId: string;
  formId: string;
};

export type DocumentsFormListAPIPayload = {
  clientDbId: string;
  filterXML?: string;
  page?: number;
  size?: number;
};

export type DocumentsFormAPIResponse = {
  app: {
    form: {
      caption?: string;
      id?: any;
      row:
        | {
            schema: DocumentsFieldItemOriginal | DocumentsFieldItemOriginal[];
          }
        | {
            schema: DocumentsFieldItemOriginal | DocumentsFieldItemOriginal[];
          }[];
    };
    data?: {};
  };
};

export type DocumentsGetListAPIPayload = {
  clientDbId: string;
  procedureName: string;
  size?: number;
  page?: number;
  filterXML?: string;
};

export type DocumentsGetListAPIResponse = DocumentsGetListAPIResponseItem[];

export type DocumentsGetListAPIResponseItem = {
  id: string;
  name: string;
};

export type DocumentsRequestAPIPayload = {
  clientDbId: any;
  formId: string;
  xml: string;
};

export type DocumentsListAPIResponseItemOriginal = {
  eil_nr: string;
  id: string;
  form_id: string;
  apr1: string;
  apr2: string;
  apr3: string;
  statusasPavadinimas: string;
  statusasSpalva: string;
  statusasId: string;
};

export type DocumentsListAPIResponse = DocumentsListAPIResponseItem[];

export type DocumentsListAPIResponseItem = {
  id: any;
  item1: string;
  item2: string;
  item3: string;
  formId: string;
  statusName: string;
  statusColor: string;
  statusId: any;
};

export type DocumentsListAPIPayload = {
  clientDbId: any;
  size?: number;
  page?: number;
  active?: boolean;
  filterXML?: string;
};

export type DocumentsFiltersAPIPayload = {
  clientDbId: string;
  filterXML: string;
};

export type DocumentsFiltersAPIResponseItemOriginal = {
  k: string;
  p: string;
  t: string;
  f: string;
};

export type DocumentsFiltersAPIResponseOriginal = {
  filters: {
    apr1: {
      r: DocumentsFiltersAPIResponseItemOriginal;
    };
    apr2: {
      r: DocumentsFiltersAPIResponseItemOriginal;
    };
    apr3: {
      r: DocumentsFiltersAPIResponseItemOriginal;
    };
    menuo: {
      r: DocumentsFiltersAPIResponseItemOriginal;
    };
    search: {
      r: DocumentsFiltersAPIResponseItemOriginal;
    };
  };
};

export type DocumentsFiltersAPIResponseItem = {
  id: string;
  name: string;
};

export type DocumentsFiltersAPIResponse = {
  apr1: DocumentsFiltersAPIResponseItem[];
  apr2: DocumentsFiltersAPIResponseItem[];
  apr3: DocumentsFiltersAPIResponseItem[];
  menuo: DocumentsFiltersAPIResponseItem[];
  search: DocumentsFiltersAPIResponseItem[];
};

export type DocumentGetAPIPayload = {
  formId: any;
  clientDbId: string;
  operationId: string;
  busHistory?: number;
};

export type DocumentGetAPIResponse = {
  id: any;
  title: string;
  buttons: string;
  comment: boolean;
  statusColor: string;
  statusPav: string;
  statusId: string;
  add_data: string;
  kor_data: string;
  schema: DocumentsFieldItem[];
};

export type DocumentCancelRequestAPIPayload = {
  formId: any;
  clientDbId: string;
  operationId: string;
};

export type contextType = {
  form: { caption?: string; id?: any };
  field: DocumentsFieldItem[];
  data: { [key: string]: any };
};

const DocumentsAPIRequest = () => {
  return {
    documentsForm: createAPIHandler(
      async (payload: DocumentsFormAPIPayload): Promise<any> => {
        const response = await Client.post<{
          row: { result: DocumentsFormAPIResponse };
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_INIT,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.formId,
            lang: i18n.language,
          },
        });

        if (!response.data.row?.result?.app?.form?.row) {
          return {};
        }

        const list = assureArray(response.data.row?.result?.app?.form?.row);

        //Formos validacija - min, max, required
        const validation = createValidationObject(list);

        const initialValues = {};

        const fields = [];

        const dynamicAttributes = [];

        const rows = list.map((d) => {
          const row = d.schema ? assureArray(d.schema) : [];

          return row.map((r) => {
            const type = r.type && r.type.toLowerCase();
            if (!type) return {};

            //sugenruojamas formos pradiniu reiksmiu objektas
            let valueByType: string | boolean | object = r.value;
            if (r.value) {
              if (type === types.CHECKBOX) {
                initialValues[r.id] = r.value.toLowerCase() === "true";
                valueByType = r.value.toLowerCase() === "true";
              } else if (
                type === types.SELECT ||
                type === types.RADIO ||
                type === types.DATERANGE
              ) {
                initialValues[r.id] = JSON.parse(r.value);
                valueByType = JSON.parse(r.value);
              } else initialValues[r.id] = r.value;
            }

            //Dinaminiai atributai - sugeneruojamas masyvas lauku, kuriu atributai bus dinaminiai
            const attr = [
              "visibleWhen",
              "requiredWhen",
              "disabledWhen",
              "valueWhen",
            ];

            attr.forEach((atr) => {
              if (r[atr] && r[atr].search(/exec:/i) > -1) {
                dynamicAttributes.push({
                  name: r.id,
                  attr: atr,
                  jexl: r[atr].split(/exec:/i)[1],
                });
              }
            });

            const res = {
              type: type,
              label: r.caption,
              name: r.id,
              default: r.value,
              size:
                r.width && Number.isInteger(+r.width) && +r.width < 3
                  ? 12 / +r.width
                  : 12,
              getItems: r.data,
              visible:
                typeof r.visible !== "undefined"
                  ? r.visible.toLowerCase() !== "false"
                  : true,
              required:
                typeof r.required !== "undefined"
                  ? r.required.toLowerCase() === "true"
                  : false,
              disabled:
                typeof r.disabled !== "undefined"
                  ? r.disabled.toLowerCase() === "true"
                  : false,
              decimalScale: r.decimal && +r.decimal,
              rows: r.rows && +r.rows,
            };

            fields.push({
              ...res,
              id: r.id,
              caption: r.caption,
              value: valueByType,
            });

            return res;
          });
        });

        const form = response.data.row?.result?.app?.form;
        const { row, ...formInfo } = form;
        const isFormDynamic = dynamicAttributes.length > 0;

        //Sutvarkoma data dalis, kad būtų paprasti masyvai
        const contextData = {};

        if (response.data.row?.result?.app?.data) {
          const dataList = response.data.row?.result?.app?.data;

          for (let el in dataList) {
            const listEl = assureArray(dataList[el].list);
            if (listEl.length > 0) contextData[el] = listEl;
          }
        }

        //Kontekstas, kuris bus reikalingas dinaminei formai
        const context: contextType = {
          form: formInfo,
          field: fields,
          data: contextData,
        };

        return {
          rows: rows,
          data: response.data.row?.result?.app?.data,
          formName: response.data.row?.result?.app?.form?.caption || "",
          formId: response.data.row?.result?.app?.form?.id || "",
          validation: validation,
          initialValues: initialValues,
          fields: fields,
          context: context,
          dynamicAttr: dynamicAttributes,
          isFormDynamic: isFormDynamic,
        };
      }
    ),
    getList: createAPIHandler(
      async (
        payload: DocumentsGetListAPIPayload
      ): Promise<DocumentsGetListAPIResponse> => {
        const response = await Client.post<{
          app: DocumentsGetListAPIResponse;
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_SP_SELECT,
          clientDbId: payload.clientDbId,
          data: {
            proc: payload.procedureName,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
            filter: payload.filterXML,
          },
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      }
    ),
    documentsRequest: createAPIHandler(
      async (payload: DocumentsRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_EDIT,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.formId,
            lang: i18n.language,
            result: payload.xml,
          },
        });
      }
    ),
    getFormsList: createAPIHandler(
      async (
        payload: DocumentsFormListAPIPayload
      ): Promise<DocumentsGetListAPIResponse> => {
        const response = await Client.post<{
          app: DocumentsGetListAPIResponse;
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_DVS_FORM_TYPE_LIST,
          clientDbId: payload.clientDbId,
          data: {
            filter_xml: payload.filterXML,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
          },
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      }
    ),
    list: createAPIHandler(
      async (
        payload: DocumentsListAPIPayload
      ): Promise<DocumentsListAPIResponse> => {
        const response = await Client.post<{
          app:
            | DocumentsListAPIResponseItemOriginal
            | DocumentsListAPIResponseItemOriginal[];
        }>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_DVS_OP_LIST,
          clientDbId: payload.clientDbId,
          data: {
            response_type: 1,
            pageNumber: payload.page || 1,
            top: payload.size || 100,
            filter_xml: payload.filterXML,
          },
        });

        if (!response.data.app) {
          return [];
        }

        const list = assureArray(response.data.app);

        return list.map((d) => {
          return {
            id: d.id,
            item1: d.apr1,
            item2: d.apr2,
            item3: d.apr3,
            formId: d.form_id,
            statusColor: d.statusasSpalva,
            statusName: d.statusasPavadinimas,
            statusId: d.statusasId,
          };
        });
      }
    ),
    getDocumentsFilters: createAPIHandler(
      async (
        payload: DocumentsFiltersAPIPayload
      ): Promise<DocumentsFiltersAPIResponse> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_DVS_OP_LIST,
          clientDbId: payload.clientDbId,
          data: {
            response_type: 5,
            filter_xml: payload.filterXML,
          },
        });

        const apr1Result = assureArray(response.data?.filters?.apr1?.r);
        const apr2Result = assureArray(response.data?.filters?.apr2?.r);
        const apr3Result = assureArray(response.data?.filters?.apr3?.r);
        const menuoResult = assureArray(response.data?.filters?.menuo?.r);
        const searchResult = assureArray(response.data?.filters?.search?.r);

        return {
          apr1: apr1Result.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          apr2: apr2Result.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          apr3: apr3Result.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          menuo: menuoResult.map((item) => {
            return {
              id: item.k,
              name: item.p || item.k,
              disabled: item.t === "0" ? true : false,
            };
          }),
          search: searchResult.map((item) => {
            return {
              id: item.k,
              name: item.k || "",
              disabled: false,
            };
          }),
        };
      }
    ),
    get: createAPIHandler(
      async (payload: DocumentGetAPIPayload): Promise<any> => {
        const response = await Client.post<any>("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_INIT,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.formId,
            lang: i18n.language,
            op: payload.operationId,
          },
        });

        const schema = assureArray(response.data.row?.result?.schema);

        return {
          ...response?.data.row?.result?.data?.row,
          schema,
        };
      }
    ),
    getApproveHistory: createAPIHandler(
      async (
        payload: ApproveHistoryAPIPayload
      ): Promise<ApproveHistoryAPIResponse> => {
        const response = await Client.post<ApproveHistoryAPIResponseOriginal>(
          "/client/application",
          {
            procedureName: APIProcedures.RGI_MGAMA_FORM_INIT,
            clientDbId: payload.clientDbId,
            data: {
              id: payload.formId,
              lang: i18n.language,
              op: payload.id,
              busHistory: 1,
            },
          }
        );

        const data = assureArray(response.data?.row?.rez?.busenos);

        return data.map((item) => {
          return {
            comment: item.komentaras,
            date: item.korData,
            result: item.busena,
            statusColor: item.statusasSpalva,
            statusId: item.statusasId,
            statusName: item.statusasPavadinimas,
            user: item.vartotojas,
          };
        });
      }
    ),
    documentCancelRequest: createAPIHandler(
      async (payload: DocumentCancelRequestAPIPayload): Promise<any> => {
        await Client.post("/client/application", {
          procedureName: APIProcedures.RGI_MGAMA_FORM_CANCEL,
          clientDbId: payload.clientDbId,
          data: {
            id: payload.operationId,
            form_id: payload.formId,
          },
        });
      }
    ),
  };
};

export default DocumentsAPIRequest;
