import { ReactNode } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "../components";
import { CompanyContextProvider } from "../features/Companies/CompanyContext";
import { ModulesContextProvider } from "../features/ModulesList/ModulesContext";

interface ISettingsLayout {
  children: ReactNode;
}

export default function SettingsLayout(props: ISettingsLayout) {
  const { children } = props;

  return (
    <ErrorBoundary>
      <CompanyContextProvider>
        <ModulesContextProvider>
          <LayoutContainer id="scroll-container">{children}</LayoutContainer>
        </ModulesContextProvider>
      </CompanyContextProvider>
    </ErrorBoundary>
  );
}

const LayoutContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;
