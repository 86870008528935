import { useFormIsSubmitting } from "@adv-libs/adv-form";
import { Button } from "@adv-libs/r365-ui";
import React, { useMemo } from "react";

export interface SubmitButtonProps {}

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const formIsSubmitting = useFormIsSubmitting();

  const icon = useMemo(() => {
    if (formIsSubmitting) return ["spin", 2200];
  }, [formIsSubmitting]);

  return (
    <Button
      fill
      type="submit"
      icon={icon}
      spin={formIsSubmitting}
      primary
      disabled={formIsSubmitting}
    >
      {props.children}
    </Button>
  );
};

export default SubmitButton;
