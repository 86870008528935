import React from "react";
import styled from "styled-components";

export interface CardProps {}

const Card: React.FC<CardProps> = (props) => {
  return <CardStyled>{props.children}</CardStyled>;
};

export const CardStyled = styled.div`
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  background: white;
`;

export default Card;
