import { DefaultTheme } from "styled-components";

export const brandGreenDark = "#0b3f30";
export const brandGreenLight = "#00b373";
export const minScreenLength = 992;
export const leftMenuWidth = 250;

const Theme: DefaultTheme = {
  darkBackground: brandGreenDark,
  lightBackground: brandGreenLight,

  whiteColor: "#FFFFFF",

  greyColor: "#F5F5F8",
  greyColorLight: "#F5F5F5",

  lightGreen: "#1A9D77",
  lightBlue: "rgba(56, 137, 170, 0.35)",
  darkGreen: brandGreenDark,
  lightestGreen: "rgba(13, 128, 95, 0.2)",

  fontColorFaded: "#737373",
  disabledColor: "rgb(133,147,155)",

  buttonSelectColor: "#f1f1f1",
  buttonHoverColor: "#00b3731a",

  spacing: 5,
  radius: "6px",

  headerHeight: 60,
  headerBackground: "white",

  minScreenLength: minScreenLength,
  leftMenuWidth: leftMenuWidth,
  containerMaxWidth: 1000,
};

export default Theme;
