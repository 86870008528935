import { default as React } from "react";
import PageTitle from "../../components/PageTitle";
import useI18n from "../../hooks/useI18n";
import { Logo } from "../../logo";
import {
  Container,
  Item,
  LoginContainer,
  LogoContainer,
  LogoImage,
} from "../Auth/Auth";
import { Languages, LoginFooter } from "../Auth/components";
import CompaniesListForm from "./components/CompaniesListForm";

export interface CompaniesListProps {}

const CompaniesList: React.FC<CompaniesListProps> = (props) => {
  const { t } = useI18n();

  return (
    <>
      <PageTitle>{t("Companies")}</PageTitle>
      <Container>
        <Item>
          <LoginContainer>
            <CompaniesListForm />
            <Languages />
            <LoginFooter />
          </LoginContainer>
        </Item>

        <Item green>
          <LogoContainer>
            <LogoImage>
              <Logo width="100px" />
            </LogoImage>
          </LogoContainer>
        </Item>
      </Container>
    </>
  );
};

export default CompaniesList;
