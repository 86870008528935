import cookies from "js-cookie";
import API from "../../api/API";
import i18n from "../../utils/i18n";

export const saveTokenExpirationDate = (date: string, remember?: boolean) => {
  if (remember) {
    const nextYearExpirationDate = new Date();
    nextYearExpirationDate.setFullYear(
      nextYearExpirationDate.getFullYear() + 1
    );

    cookies.set("exp", date, {
      expires: nextYearExpirationDate,
    });
    cookies.set("exp_rm", "1", {
      expires: nextYearExpirationDate,
    });
  } else {
    cookies.set("exp", date);
  }
};

export const isRememberMe = () => {
  return cookies.get("exp_rm") === "1";
};

export const deleteTokenExpirationAndRememberDate = () => {
  cookies.remove("exp");
  cookies.remove("exp_rm");
};

export const getTokenExpirationDate = () => {
  return cookies.get("exp");
};

export const isTokenExpired = () => {
  const expirationDate = getTokenExpirationDate();
  if (!expirationDate) return true;
  const now = new Date();
  const expiration = new Date(expirationDate);
  if (String(expiration) === "Invalid Date") return true;
  const threshold = 60 * 1000;
  return now.getTime() > expiration.getTime() - threshold;
};

let memo = null;

let justRefreshed = false;

export const isJustRefreshed = () => {
  return justRefreshed;
};

export const onSuccessedRequest = () => {
  justRefreshed = false;
};

const refreshTokenLogic = async () => {
  try {
    const tokenExpirationDate = await API.auth.refresh();
    saveTokenExpirationDate(
      new Date(tokenExpirationDate).toISOString(),
      isRememberMe()
    );
    justRefreshed = true;
    return true;
  } catch (err) {
    console.error(err);
    throw new Error(i18n.t("Failed to refresh token"));
  }
};

const refreshToken = () => {
  if (!memo) {
    memo = new Promise(async (resolve, reject) => {
      try {
        const result = await refreshTokenLogic();
        resolve(result);
      } catch (err) {
        reject(err);
      } finally {
        memo = null;
      }
    });
  }

  return memo;
};

export default refreshToken;
