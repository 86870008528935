import persistentCompanyId from "../features/Companies/persistentCompanyId";
import { getHistory } from "../features/Router/history";
import { setUrlWithCompanyId } from "../utils/path";

const actionAfterCompanySelect = (clientDbId: string, redirectTo: string) => {
  persistentCompanyId.setValue(clientDbId);
  getHistory().replace(setUrlWithCompanyId(redirectTo));
};

export default actionAfterCompanySelect;
