import { AdvInput, AdvInputPassword, AdvInputProps } from "@adv-libs/r365-ui";
import styled from "styled-components";

export interface ITextField extends AdvInputProps {
  password?: boolean;
}

function TextField(props: ITextField) {
  const { type, ...restProps } = props;

  return (
    <InputStyle>
      {type === 'password' ? (
        <AdvInputPassword {...restProps} />
      ) : (
        <AdvInput {...restProps} />
      )}
    </InputStyle>
  );
}

export default TextField;

const InputStyle = styled.div`
  margin-bottom: ${(props) => `${props.theme.spacing * 3}px`};
`;
