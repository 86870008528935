import styled from "styled-components";
import breakpoints from "./breakpoints";

const gridColumns = 12;
const gutterSmall = 10;
const gutterLarge = 20;
const marginSmall = 3;
const marginLarge = 5;
const maxWidth = 1400;

export interface GridProps {}

export const Grid: React.FC<GridProps> = (props) => {
  return (
    <GridStyled>
      <GridContainerStyled>{props.children}</GridContainerStyled>
    </GridStyled>
  );
};

export const GridStyled = styled.div`
  padding: 0 ${marginSmall}%;

  @media screen and (min-width: ${breakpoints.s}px) {
    padding: 0 ${marginLarge}%;
  }
`;

export const GridContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(${gridColumns}, minmax(0, 1fr));
  max-width: ${maxWidth}px;
  margin: 0 auto;

  grid-gap: ${gutterSmall}px;

  @media screen and (min-width: ${breakpoints.s}px) {
    grid-gap: ${gutterLarge}px;
  }
`;

export const GridCol = styled.div<{
  col?: string;
  order?: number;
  "col-s"?: string;
  "order-s"?: number;
  "col-m"?: string;
  "order-m"?: number;
  "col-l"?: string;
  "order-l"?: number;
  "col-xl"?: string;
  "order-xl"?: number;
  "col-xxl"?: string;
  "order-xxl"?: number;
  "col-xxxl"?: string;
  "order-xxxl"?: number;
}>`
  grid-column: ${(props) => props.col || "span 12"};
  order: ${(props) => props.order};

  @media screen and (min-width: ${breakpoints.s}px) {
    grid-column: ${(props) => props["col-s"]};
    order: ${(props) => props["order-s"]};
  }

  @media screen and (min-width: ${breakpoints.m}px) {
    grid-column: ${(props) => props["col-m"]};
    order: ${(props) => props["order-m"]};
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: ${(props) => props["col-l"]};
    order: ${(props) => props["order-l"]};
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    grid-column: ${(props) => props["col-xl"]};
    order: ${(props) => props["order-xl"]};
  }

  @media screen and (min-width: ${breakpoints.xxl}px) {
    grid-column: ${(props) => props["col-xxl"]};
    order: ${(props) => props["order-xxl"]};
  }

  @media screen and (min-width: ${breakpoints.xxxl}px) {
    grid-column: ${(props) => props["col-xxxl"]};
    order: ${(props) => props["order-xxxl"]};
  }
`;
